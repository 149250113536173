import { ENetworkRequestStatus } from 'services/BackendApi';
import { BookingSearchItem, EPurchaseCostReviewStatus, ERateSource, ISalesRepresentative } from 'services/BookingManagerApi';
import { HotelNameResponse as Hotel, CountryNameResponse as Country } from 'services/BackendApi/types';

export enum ExportType {
  BookingInfo = 'BOOKINGS',
  DetailsInfo = 'GROUND_SERVICES',
  SrExport = 'SR_EXPORT',
}

export enum DateRangeTypes {
  Arrivals = 'Arrivals',
  Departures = 'Departures',
  Invoice = 'Invoice',
  Proforma = 'Proforma',
}

export enum BookingListProductTypesFilter {
  Accommodations = 'hasAccommodations',
  Transfers = 'hasTransfers',
  GroundServices = 'hasGroundServices',
  Fines = 'hasFines',
  Supplements = 'hasSupplements',
  Bespokes = 'hasBespokes',
}

interface IDateRange {
  type: DateRangeTypes | null;
  startDate: string | null;
  endDate: string | null;
}

interface DestinationList {
  hotels: Hotel[];
  countries: Country[];
  requestStatus: ENetworkRequestStatus;
}

export interface IBookingListExportData {
  isWaitingForExport: boolean;
  exportUuid: string | null;
  exportSignedUrl: string | null;
  exportType: ExportType | null;
  triggerExportLoad: ENetworkRequestStatus;
  pollExportLoad: ENetworkRequestStatus;
}

export interface IBookingsListDomain {
  requestPending: boolean;
  error: any | null;
  bookings: BookingSearchItem[] | null;

  itemsPerPage: number;
  currentPage: number;
  totalResults: number;
  sortBy: keyof BookingSearchItem;
  sortOrder: 'asc' | 'desc';

  travelAgentUuid: string | null;
  travelAgentCountryCode: string | null;
  companyUuid: string | null;

  bookingStatuses: string[];
  productConfirmationNumberFilter: string | null;
  purchaseCostReviewStatus: EPurchaseCostReviewStatus | null;
  bookingType: string | null;

  humanReadableIdFilter: string | null;
  guestNameFilter: string | null;
  travelPartnerRefFilter: string | null;

  destinationList: DestinationList;
  destination: string | null;

  salesRepList: ISalesRepresentative[] | null;
  salesRepListRequestPending: boolean;
  salesRepListError: string | null;
  salesRepresentativeUuid: string | null;

  exportData: IBookingListExportData;
  onlyShowWithInvoiceFilter: boolean;
  onlyShowWithGroundServicesFilter: boolean | null;
  isViewOnlyAssignedToMeFilter: boolean;
  dateRange: IDateRange | null;
  hasProformaCreated: boolean | null;
  hasInvoiceCreated: boolean | null;
  hasOverduePayments: boolean | null;
  hasHotelCommissionAfterCheckout: boolean | null;
  showFullyPaidByTaFilter: boolean | null;
  onlyShowMyBookingsFilter: boolean | null;
  rateSourceFilter: ERateSource[] | null;

  productTypesFilter: BookingListProductTypesFilter[];

  isRefetchingBookings: boolean; // used by saga
}

export const initialState: IBookingsListDomain = {
  requestPending: true,
  error: null,
  bookings: null,

  itemsPerPage: 10,
  currentPage: 0,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  totalResults: 0,

  travelAgentUuid: null,
  travelAgentCountryCode: null,
  companyUuid: null,

  bookingStatuses: [],
  productConfirmationNumberFilter: null,
  purchaseCostReviewStatus: null,
  bookingType: null,

  humanReadableIdFilter: null,
  guestNameFilter: null,
  travelPartnerRefFilter: null,

  destinationList: {
    hotels: [],
    countries: [],
    requestStatus: ENetworkRequestStatus.IDLE,
  },
  destination: null,

  salesRepList: null,
  salesRepListRequestPending: false,
  salesRepListError: null,
  salesRepresentativeUuid: null,

  onlyShowWithInvoiceFilter: false,
  onlyShowWithGroundServicesFilter: null,
  isViewOnlyAssignedToMeFilter: false,
  hasProformaCreated: null,
  hasInvoiceCreated: null,
  hasOverduePayments: null,
  hasHotelCommissionAfterCheckout: null,
  showFullyPaidByTaFilter: null,
  onlyShowMyBookingsFilter: null,
  rateSourceFilter: null,

  exportData: {
    isWaitingForExport: false,
    exportUuid: null,
    exportSignedUrl: null,
    exportType: null,
    triggerExportLoad: ENetworkRequestStatus.IDLE,
    pollExportLoad: ENetworkRequestStatus.IDLE,
  },
  dateRange: null,

  productTypesFilter: [],

  isRefetchingBookings: false,
};
