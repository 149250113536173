import produce from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi';
import * as Actions from './actions';
import { initialState, IBookingsListDomain, DateRangeTypes } from './model';

const bookingListReducer = (
  state: IBookingsListDomain = initialState,
  action: Actions.BookingsListAction
): IBookingsListDomain => {
  switch (action.type) {
    case Actions.GET_BOOKING_LIST_REQUEST:
      return {
        ...state,
        requestPending: true,
        totalResults: 0,
        bookings: null,
      };

    case Actions.GET_BOOKING_LIST_SUCCESS:
      return {
        ...state,
        requestPending: false,
        totalResults: action.totalResults,
        bookings: action.bookings,
        error: null,
      };

    case Actions.GET_BOOKING_LIST_FAILURE:
      return {
        ...state,
        requestPending: false,
        error: action.error,
      };

    case Actions.SET_HUMAN_READABLE_ID_FILTER:
      return {
        ...state,
        currentPage: 0,
        humanReadableIdFilter: action.humanReadableIdFilter,
      };

    case Actions.SET_GUEST_NAME_FILTER:
      return {
        ...state,
        currentPage: 0,
        guestNameFilter: action.guestNameFilter,
      };

    case Actions.SET_TRAVEL_PARTNER_REF_FILTER:
      return {
        ...state,
        currentPage: 0,
        travelPartnerRefFilter: action.travelPartnerRefFilter,
      };

    case Actions.SET_PAGE_NUMBER:
      return {
        ...state,
        currentPage: action.pageNumber,
      };

    case Actions.SET_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.itemsPerPage,
      };

    case Actions.SET_SORT:
      return {
        ...state,
        sortBy: action.sortBy,
        sortOrder: action.sortOrder,
      };
    case Actions.SET_TRAVEL_AGENT_UUID:
      return {
        ...state,
        travelAgentUuid: action.travelAgentUuid,
      };
    case Actions.SET_TRAVEL_AGENT_COUNTRY_CODE:
      return {
        ...state,
        currentPage: 0,
        travelAgentCountryCode: action.travelAgentCountryCode,
      };
    case Actions.SET_BOOKING_TYPE:
      return {
        ...state,
        currentPage: 0,
        bookingType: action.bookingType,
      };
    case Actions.SET_COMPANY_UUID:
      return {
        ...state,
        companyUuid: action.companyUuid,
      };
    case Actions.SET_SALES_REPRESENTATIVE_UUID:
      return {
        ...state,
        salesRepresentativeUuid: action.salesRepresentativeUuid,
      };
    case Actions.SET_DESTINATION:
      return {
        ...state,
        destination: action.destination,
      };
    case Actions.SET_STATUSES:
      return {
        ...state,
        bookingStatuses: action.statuses,
      };
    case Actions.SET_PURCHASE_COST_REVIEW_STATUS:
      return {
        ...state,
        purchaseCostReviewStatus: action.status,
      };

    case Actions.GET_DESTINATION_LIST_REQUEST:
      return produce(state, x => {
        x.destinationList.requestStatus = ENetworkRequestStatus.PENDING;
        return x;
      });

    case Actions.GET_DESTINATION_LIST_SUCCESS:
      return produce(state, x => {
        x.destinationList.hotels = action.hotels;
        x.destinationList.countries = action.countries;
        x.destinationList.requestStatus = ENetworkRequestStatus.SUCCESS;
        return x;
      });

    case Actions.GET_DESTINATION_LIST_FAILURE:
      return produce(state, x => {
        x.destinationList.requestStatus = ENetworkRequestStatus.ERROR;
        return x;
      });

    case Actions.GET_SALES_REP_LIST_REQUEST:
      return {
        ...state,
        salesRepListRequestPending: true,
        salesRepList: null,
        salesRepListError: null,
      };

    case Actions.GET_SALES_REP_LIST_SUCCESS:
      return {
        ...state,
        salesRepListRequestPending: false,
        salesRepList: action.salesRepList,
        salesRepListError: null,
      };

    case Actions.GET_SALES_REP_LIST_FAILURE:
      return {
        ...state,
        salesRepListRequestPending: false,
        salesRepList: null,
        salesRepListError: action.error,
      };

    case Actions.TRIGGER_BOOKING_LIST_EXPORT_REQUEST:
      return {
        ...state,
        exportData: {
          ...state.exportData,
          isWaitingForExport: true,
          exportUuid: null,
          exportSignedUrl: null,
          exportType: action.exportType,
          triggerExportLoad: ENetworkRequestStatus.PENDING,
        },
      };

    case Actions.TRIGGER_BOOKING_LIST_EXPORT_SUCCESS:
      return {
        ...state,
        exportData: {
          ...state.exportData,
          exportUuid: action.exportUuid,
          triggerExportLoad: ENetworkRequestStatus.SUCCESS,
        },
      };

    case Actions.TRIGGER_BOOKING_LIST_EXPORT_FAILURE:
      return {
        ...state,
        exportData: {
          ...state.exportData,
          exportUuid: null,
          isWaitingForExport: false,
          triggerExportLoad: ENetworkRequestStatus.ERROR,
        },
      };

    case Actions.POLL_BOOKING_LIST_EXPORT_REQUEST:
      return {
        ...state,
        exportData: {
          ...state.exportData,
          pollExportLoad: ENetworkRequestStatus.PENDING,
        },
      };

    case Actions.POLL_BOOKING_LIST_EXPORT_SUCCESS:
      return {
        ...state,
        exportData: {
          ...state.exportData,
          isWaitingForExport: false,
          exportSignedUrl: action.signedUrl,
          pollExportLoad: ENetworkRequestStatus.SUCCESS,
        },
      };

    case Actions.POLL_BOOKING_LIST_EXPORT_CONTINUE:
      return {
        ...state,
        exportData: {
          ...state.exportData,
          pollExportLoad: ENetworkRequestStatus.IDLE,
        },
      };

    case Actions.POLL_BOOKING_LIST_EXPORT_FAILURE:
      return {
        ...state,
        exportData: {
          ...state.exportData,
          isWaitingForExport: false,
          exportUuid: null,
          pollExportLoad: ENetworkRequestStatus.ERROR,
        },
      };

    case Actions.CANCEL_BOOKING_LIST_EXPORT:
      return {
        ...state,
        exportData: {
          ...state.exportData,
          isWaitingForExport: false,
          exportUuid: null,
          triggerExportLoad: ENetworkRequestStatus.IDLE,
          pollExportLoad: ENetworkRequestStatus.IDLE,
        },
      };

    case Actions.SET_ONLY_SHOW_WITH_INVOICE_FILTER:
      return {
        ...state,
        onlyShowWithInvoiceFilter: action.onlyShowWithInvoiceFilter,
      };

    case Actions.SET_ONLY_SHOW_WITH_GROUND_SERVICES_FILTER:
      return {
        ...state,
        currentPage: 0,
        onlyShowWithGroundServicesFilter: action.onlyShowWithGroundServicesFilter,
      };

    case Actions.SET_HAS_OVERDUE_PAYMENTS_FILTER:
      return {
        ...state,
        currentPage: 0,
        hasOverduePayments: action.hasOverduePaymentsFilter,
      };

    case Actions.SET_HAS_PROFORMA_CREATED_FILTER:
      return {
        ...state,
        currentPage: 0,
        hasProformaCreated: action.hasProformaCreated,
        hasInvoiceCreated: action.hasProformaCreated === true ? null : state.hasInvoiceCreated,
      };

    case Actions.SET_HAS_INVOICE_CREATED_FILTER:
      return {
        ...state,
        currentPage: 0,
        hasProformaCreated: action.hasInvoiceCreated === true ? null : state.hasProformaCreated,
        hasInvoiceCreated: action.hasInvoiceCreated,
      };

    case Actions.SET_SHOW_FULLY_PAID_BY_TA_FILTER:
      return {
        ...state,
        currentPage: 0,
        showFullyPaidByTaFilter: action.showFullyPaidByTaFilter,
      };

    case Actions.SET_ONLY_SHOW_MY_BOOKINGS_FILTER:
      return {
        ...state,
        onlyShowMyBookingsFilter: action.onlyShowMyBookingsFilter,
      };

    case Actions.SET_RATE_SOURCE_FILTER:
      return {
        ...state,
        rateSourceFilter: action.rateSourceFilter,
      };

    case Actions.SET_IS_VIEW_ONLY_ASSIGNED_TO_ME_FILTER:
      return {
        ...state,
        isViewOnlyAssignedToMeFilter: action.isViewOnlyAssignedToMeFilter,
      };

    case Actions.SET_DATE_RANGE_TYPE:
      return {
        ...state,
        dateRange: {
          startDate: state.dateRange?.startDate ?? null,
          endDate: state.dateRange?.endDate ?? null,
          type: action.dateRangeType,
        },
        hasProformaCreated:
          action.dateRangeType === DateRangeTypes.Proforma || action.dateRangeType === DateRangeTypes.Invoice
            ? null
            : state.hasProformaCreated,
        hasInvoiceCreated:
          action.dateRangeType === DateRangeTypes.Proforma || action.dateRangeType === DateRangeTypes.Invoice
            ? null
            : state.hasInvoiceCreated,
      };

    case Actions.SET_DATE_RANGE_VALUE:
      return {
        ...state,
        currentPage: 0,
        dateRange: {
          type: state.dateRange?.type ?? DateRangeTypes.Arrivals,
          startDate: action.startDate,
          endDate: action.endDate,
        },
      };

    case Actions.CLEAR_DATE_RANGE_FILTER:
      return { ...state, currentPage: 0, dateRange: null };

    case Actions.SET_IS_REFETCHING_BOOKINGS:
      return {
        ...state,
        isRefetchingBookings: action.newValue,
      };
    case Actions.SET_PRODUCT_TYPES_FILTER:
      return {
        ...state,
        productTypesFilter: [...action.productTypes],
      };
    case Actions.SET_PRODUCT_CONFIRMATION_NUMBER_FILTER:
      return {
        ...state,
        productConfirmationNumberFilter: action.productConfirmationNumber,
      };
    case Actions.RESET_FILTERS:
      return {
        ...initialState,
      };
    case Actions.SET_HAS_HOTEL_COMMISSION_AFTER_CHECKOUT_FILTER:
      return {
        ...state,
        hasHotelCommissionAfterCheckout: action.hasHotelCommissionAfterCheckoutFilter,
      };
    default:
      return state;
  }
};

export default bookingListReducer;
