import React from 'react';
import { ERoomRateType } from 'store/modules/bookingBuilder/model';
import classnames from 'classnames';

interface IV1RateTagProps {
  className?: string;
  rateType: ERoomRateType;
}

export const RateTypeTag: React.FC<IV1RateTagProps> = React.memo(({ rateType }) => {
  const label = rateType === ERoomRateType.STATIC ? 'STATIC RATE' : 'LIVE RATE';
  return (
    <div className="flex justify-end">
      <span
        className={classnames(
          'inline-flex items-center h-18px py-0 px-5px rounded-1px text-xs leading-14px tracking-[0.04em] mb-5px v1-rate-tag border-solid border-1',
          {
            'bg-white-hard border-gray-50': rateType === ERoomRateType.STATIC,
            'bg-teal-30 border-teal-80': rateType === ERoomRateType.LIVE,
          }
        )}
      >
        {label}
      </span>
    </div>
  );
});
