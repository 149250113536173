import { useEffect, useRef } from 'react';

export const usePrevious = <T>(value: T, setInitially?: boolean): T | undefined => {
  let ref;
  if (setInitially) {
    ref = useRef<T>(value);
  } else {
    ref = useRef<T>();
  }
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
