import { ILiveRatesDomain } from './model';
import * as Actions from './actions';
import { initialState } from './model';
import { update } from 'utils/immutable';
import { ENetworkRequestStatus } from 'services/BackendApi';

export const liveRatesReducer = (
  state: ILiveRatesDomain = initialState,
  action: Actions.AllActions
): ILiveRatesDomain => {
  switch (action.type) {
    case Actions.LIST_ACTION_TYPES.GET_REQUEST:
      return update(state, s => {
        s.request.listingPageGet = ENetworkRequestStatus.PENDING;
      });

    case Actions.LIST_ACTION_TYPES.GET_SUCCESS:
      return update(state, s => {
        s.request.listingPageGet = ENetworkRequestStatus.SUCCESS;

        s.listingPage.items = action.data;
        s.listingPage.filter.page_total = action.total;
      });

    case Actions.LIST_ACTION_TYPES.GET_FAILURE:
      return update(state, s => (s.request.listingPageGet = ENetworkRequestStatus.ERROR));

    case Actions.LIST_ACTION_TYPES.SET_LISTING_PAGE_GET:
      return update(state, s => {
        s.request.listingPageGet = action.val;
      });

    case Actions.LIST_ACTION_TYPES.SET_FILTER_NAME:
      return update(state, s => {
        s.listingPage.filter.page = 1;
        s.listingPage.filter.nameFilter = action.name;
      });

    case Actions.LIST_ACTION_TYPES.SET_FILTER_EXTERNAL_SYSTEM:
      return update(state, s => {
        s.listingPage.filter.page = 1;
        s.listingPage.filter.externalSystem = action.externalSystem;
      });

    case Actions.LIST_ACTION_TYPES.SET_SORT_DATA:
      return update(state, s => {
        s.listingPage.filter.page = 1;
        s.listingPage.filter.order_by = action.sortBy;
        s.listingPage.filter.order_type = action.sortOrder;
      });

    case Actions.LIST_ACTION_TYPES.SET_PAGE:
      return update(state, s => {
        s.listingPage.filter.page = action.val;
      });

    case Actions.LIST_ACTION_TYPES.SET_PER_PAGE:
      return update(state, s => {
        s.listingPage.filter.page = 1;
        s.listingPage.filter.page_size = action.val;
      });

    case Actions.LIST_ACTION_TYPES.SET_TO_DELETE:
      return update(state, s => {
        s.listingPage.toDelete = action.value;
      });

    case Actions.LIST_ACTION_TYPES.DELETE_REQUEST:
      return update(state, s => (s.request.deletingItem = ENetworkRequestStatus.PENDING));

    case Actions.LIST_ACTION_TYPES.DELETE_SUCCESS:
      return update(state, s => {
        s.request.deletingItem = ENetworkRequestStatus.SUCCESS;
        s.listingPage.toDelete = null;
      });

    case Actions.LIST_ACTION_TYPES.DELETE_FAILURE:
      return update(state, s => {
        s.request.deletingItem = ENetworkRequestStatus.ERROR;
      });

    case Actions.SETTINGS_PAGE_ACTION_TYPES.SET_INTERNAL_ROOMS_FILTER:
      return update(state, s => {
        s.settingsPage.internalRoomsFilter = action.name;
      });

    case Actions.SETTINGS_PAGE_ACTION_TYPES.ATTEMPT_SET_INTERNAL_ROOMS_FILTER_REQUEST:
      return update(state, s => {
        s.settingsPage.isAttemptingToChangeInternalRoomsFilterTo = action.name;
      });

    case Actions.SETTINGS_PAGE_ACTION_TYPES.SET_EXTERNAL_ROOMS_FILTER:
      return update(state, s => {
        s.settingsPage.externalRoomsFilter = action.name;
      });

    case Actions.SETTINGS_PAGE_ACTION_TYPES.GET_STAY_AND_INTERNAL_ROOMS_REQUEST:
      return update(state, s => {
        s.request.settingsPageStayAndInternalRoomsGet = ENetworkRequestStatus.PENDING;
      });
    case Actions.SETTINGS_PAGE_ACTION_TYPES.GET_STAY_AND_INTERNAL_ROOMS_SUCCESS:
      return update(state, s => {
        s.request.settingsPageStayAndInternalRoomsGet = ENetworkRequestStatus.SUCCESS;
        s.settingsPage.stayInformation = action.stayInformation;
        s.settingsPage.internalRooms = action.internalRoomsList;
        s.settingsPage.originalInternalRooms = action.internalRoomsList;
      });
    case Actions.SETTINGS_PAGE_ACTION_TYPES.GET_STAY_AND_INTERNAL_ROOMS_FAILURE:
      return update(state, s => {
        s.request.settingsPageStayAndInternalRoomsGet = ENetworkRequestStatus.ERROR;
      });

    case Actions.SETTINGS_PAGE_ACTION_TYPES.GET_EXTERNAL_ROOMS_REQUEST:
      return update(state, s => {
        s.request.settingsPageExternalRoomsGet = ENetworkRequestStatus.PENDING;
      });
    case Actions.SETTINGS_PAGE_ACTION_TYPES.GET_EXTERNAL_ROOMS_SUCCESS:
      return update(state, s => {
        s.request.settingsPageExternalRoomsGet = ENetworkRequestStatus.SUCCESS;
        s.settingsPage.externalRooms = action.externalRoomsList;
      });

    case Actions.SETTINGS_PAGE_ACTION_TYPES.GET_EXTERNAL_ROOMS_FAILURE:
      return update(state, s => {
        s.request.settingsPageExternalRoomsGet = ENetworkRequestStatus.ERROR;
      });

    case Actions.SETTINGS_PAGE_ACTION_TYPES.SET_INTERNAL_ROOM_EXTERNAL_ID:
      return update(state, s => {
        s.settingsPage.internalRooms = s.settingsPage.internalRooms.map(item => {
          if (item.uuid === action.internalRoomUuid) {
            item.externalId = action.externalId;
            item.isAmended = true;
          }
          return item;
        });
        s.settingsPage.hasUnsavedChanges = true;
      });

    case Actions.SETTINGS_PAGE_ACTION_TYPES.RESET_INTERNAL_ROOMS_TO_ORIGINAL_STATE:
      return update(state, s => {
        s.settingsPage.internalRooms = s.settingsPage.originalInternalRooms;
      });

    case Actions.SETTINGS_PAGE_ACTION_TYPES.CLEAR_INTERNAL_ROOM_ERRORS:
      return update(state, s => {
        s.settingsPage.internalRoomErrors = [];
      });
    case Actions.SETTINGS_PAGE_ACTION_TYPES.SET_INTERNAL_ROOM_ERRORS:
      return update(state, s => {
        s.settingsPage.internalRoomErrors = [...action.errors];
        // if theres errors, stop everything else from loading
        s.request.settingsPageStayAndInternalRoomsGet = ENetworkRequestStatus.ERROR;
        s.request.settingsPageExternalRoomsGet = ENetworkRequestStatus.ERROR;
        s.request.settingsPageSave = ENetworkRequestStatus.ERROR;
      });

    case Actions.SETTINGS_PAGE_ACTION_TYPES.SAVE_LISTS_REQUEST:
      return update(state, s => {
        s.request.settingsPageSave = ENetworkRequestStatus.PENDING;
      });
    case Actions.SETTINGS_PAGE_ACTION_TYPES.SAVE_LISTS_SUCCESS:
      return update(state, s => {
        s.request.settingsPageSave = ENetworkRequestStatus.SUCCESS;
        s.settingsPage.hasUnsavedChanges = false;
      });
    case Actions.SETTINGS_PAGE_ACTION_TYPES.SAVE_LISTS_FAILURE:
      return update(state, s => {
        s.request.settingsPageSave = ENetworkRequestStatus.ERROR;
      });

    case Actions.SETTINGS_PAGE_ACTION_TYPES.SET_HAS_UNSAVED_CHANGES:
      return update(state, s => {
        s.settingsPage.hasUnsavedChanges = action.hasUnsavedChanges;
      });

    case Actions.SETTINGS_PAGE_ACTION_TYPES.SET_SHOW_UNSAVED_CHANGES_MODAL:
      return update(state, s => {
        s.settingsPage.showUnsavedChangesModal = action.showUnsavedChangesModal;
      });

    case Actions.CREATE_ACTIONS.GET_MAPPABLE_STAYS_REQUEST:
      return update(state, s => {
        s.request.mappableStaysGet = ENetworkRequestStatus.PENDING;
      });

    case Actions.CREATE_ACTIONS.GET_MAPPABLE_STAYS_SUCCESS:
      return update(state, s => {
        s.request.mappableStaysGet = ENetworkRequestStatus.SUCCESS;
        s.createModal.mappableStays = action.mappableStays;
        s.createModal.unmappedHotels = action.unmappedHotels;
      });

    case Actions.CREATE_ACTIONS.GET_MAPPABLE_STAYS_FAILURE:
      return update(state, s => {
        s.request.mappableStaysGet = ENetworkRequestStatus.ERROR;
      });

    case Actions.CREATE_ACTIONS.PATCH_MAPPABLE_STAY_REQUEST:
      return update(state, s => {
        s.request.mappableStaysPatch = ENetworkRequestStatus.PENDING;
      });

    case Actions.CREATE_ACTIONS.PATCH_MAPPABLE_STAY_SUCCESS:
      return update(state, s => {
        s.request.mappableStaysPatch = ENetworkRequestStatus.SUCCESS;
      });

    case Actions.CREATE_ACTIONS.PATCH_MAPPABLE_STAY_FAILURE:
      return update(state, s => {
        s.request.mappableStaysPatch = ENetworkRequestStatus.ERROR;
      });

    case Actions.CREATE_ACTIONS.OPEN_NEW_MAPPING_MODAL:
      return update(state, s => {
        s.createModal.isOpen = true;
      });

    case Actions.CREATE_ACTIONS.CLOSE_NEW_MAPPING_MODAL:
      return update(state, s => {
        s.createModal.isOpen = false;
        s.createModal.mappableStays = [];
        s.createModal.unmappedHotels = [];
      });

    default:
      return state;
  }
};
