import React, { useCallback, useEffect, useState } from 'react'
import classnames from 'classnames';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import { format } from 'date-fns';
import { GuestAgesPicker } from 'ui/GuestAgesPicker/GuestAgesPicker';
import { ENetworkRequestStatus, IGuestAges } from 'services/BackendApi';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import FluidButton from 'ui/FluidButton';
import { FSetStartAndEndDate } from './types';
import DateRangeInput from 'pureUi/DateRangeInput';
import styled from 'styled-components';
import { theme } from '../../../tailwind.config';
import { getHotelsByCurrencyRequestAction, searchBAMAccommodationsRequestAction, setBAMErrorAction, setBAMHotelFilterAction } from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IHeadlineLineItemBreakdownComponent } from 'ui/HeadlineLineItemBreakdown';
import { getCurrencyBySymbol, numberOfNights } from 'utils';
import { BAMFilterHotelsRequestSelector, BAMHotelFilterSelector, BAMHotelsByCurrencySelector, BAMSearchAccommodationsRequestSelector } from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import { IMultiselectValueLabelPair, Multiselect } from 'ui/Multiselect';
import * as HotelDetailsSelectors from 'store/modules/bookingManager/subdomains/hotelDetails/selectors';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { LoadingBar } from 'ui/NetworkStatusBar';

export const GA_FLOW_TAG = 'act-modal';

// @ts-ignore Styled component errors
const StyledDateRangeInput = styled(DateRangeInput)`
  .pseudoSelect {
    background: ${theme.colors['ivory']};
    height: 37px;
    border-color: ${theme.colors['gray-40']};
  }
  .displayString {
    text-transform: none;
    font-family: 'PT Sans', sans-serif;
  }
`;

interface IAddAccommodationModalFiltersProps {
  bookingCurrencySymbol: IHeadlineLineItemBreakdownComponent['bookingCurrency'];
  hotelUuid: string;
  guestAges: IGuestAges;
  setGuestAges: (numberOfAdults: number, agesOfAllChildren?: number[]) => void;
  selectedDates: string[];
  setStartAndEndDate: FSetStartAndEndDate;
  error: string | null;
}

export const AddAccommodationModalFilters: React.FC<IAddAccommodationModalFiltersProps> = ({
  hotelUuid, guestAges, setGuestAges, selectedDates, setStartAndEndDate, bookingCurrencySymbol, error
}) => {
  const { dynamicParameters } = useDynamicParameters();
  const hotel = useSelector(HotelDetailsSelectors.hotelDetailsSelector);
  const hotelsByCurrency = useSelector(BAMHotelsByCurrencySelector);
  const filterHotelsRequest = useSelector(BAMFilterHotelsRequestSelector);
  const searchAccommodationsRequest = useSelector(BAMSearchAccommodationsRequestSelector);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  
  const hotelsByCurrencyOptions: IMultiselectValueLabelPair[] = hotelsByCurrency.map(item => ({
    value: item.uuid,
    label: item.name,
  }));

  useEffect(() => {
    if (!bookingCurrencySymbol) {
      dispatch(setBAMErrorAction('Can not get booking currency'));
      return;
    }
    const currency = getCurrencyBySymbol(bookingCurrencySymbol);
    dispatch(getHotelsByCurrencyRequestAction(currency.code));
  }, [bookingCurrencySymbol, dispatch])
  
  useEffect(() => {
    if (filterHotelsRequest === ENetworkRequestStatus.SUCCESS && hotel.uuid) {
      setLoading(false);
      dispatch(setBAMHotelFilterAction(hotel.uuid));
    }

  }, [dispatch, hotel, filterHotelsRequest]);

  useEffect(() => {
    dispatch(BreakdownActions.setBAMErrorAction(null));
    return () => {
      dispatch(BreakdownActions.resetBAMStateAction());
    }
  }, []);

  const handleSearchButtonClick = async () => {
    if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
      // @ts-ignore
      window.gtag('event', `${GA_FLOW_TAG}_search`, {});
    }
    dispatch(searchBAMAccommodationsRequestAction());
  }
  
  const handleHotelFilterUpdate = useCallback(values => {
    dispatch(setBAMHotelFilterAction(values[0]));
  }, [dispatch]);

  if (isLoading) {
    return (
      <div className="w-full">
        <LoadingBar />
      </div>
    )
  }

  return (
    <div className="search-and-filters">
      <div
        className={classnames('filters-and-search space-y-15px font-pt-sans')}
      >
        <div className="flex space-x-20px">
          {/* Hotel */}
          <label className="flex-1">
            <span className="font-bold text-black mb-5px block">Hotel *</span>
            <Multiselect
              id="pickup-from-multiselect"
              options={hotelsByCurrencyOptions}
              className={'bg-ivory'}
              itemsClassname="bg-ivory"
              itemCtaClassName="hover:bg-gray-10"
              onUpdate={handleHotelFilterUpdate}
              selectedValues={hotelUuid?.length ? [hotelUuid] : []}
              isEnableFuzzySearch
              isCloseOnSelect
              isSingleSelectMode
              hideCheckboxes
              hideDropdownArrow
              hideNonMatchingOptions
              disabled={searchAccommodationsRequest === ENetworkRequestStatus.PENDING}
            />
          </label>

          {/* Dates */}
          <label className="w-[190px]">
            <span className="font-bold text-black mb-5px block">Dates *</span>
            <DatePickerStateProvider
              defaultSelectedDates={selectedDates}
              onDateChange={dates => {
                const startDate = format(new Date(dates[0]), 'yyyy-MM-dd');
                const endDate = format(new Date(dates[dates.length - 1]), 'yyyy-MM-dd');
                setStartAndEndDate({ startDate, endDate });
                if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
                  // @ts-ignore
                  window.gtag('event', `${GA_FLOW_TAG}_select-period`, {
                    'selected-period': `from ${startDate} to ${endDate}`,
                  });
                }
              }}
              render={(params: IDatePickerSateParams) => {
                return (
                  <StyledDateRangeInput
                    displayString={params.displayString}
                    currentDate={params.datePickerCurrentDate}
                    selectedDates={[...params.selectedDates]}
                    totalNights={selectedDates?.length ? selectedDates.length - 1 : 0}
                    onDayClick={params.handleDayClick}
                    onDayMouseOver={params.handleDateMouseOver}
                    showDatePicker={params.showDatePicker}
                    onNextClick={params.incrementDate}
                    onPrevClick={params.decrementDate}
                    onMouseDown={params.toggleDatePicker}
                    onClickOutside={params.hideDatePicker}
                    placeholder={'Select Dates...'}
                    showTotalNights
                    shortNightsVariant
                    enablePastDates
                    noPortal
                    disabled={searchAccommodationsRequest === ENetworkRequestStatus.PENDING}
                  />
                );
              }}
            />
          </label>

          {/* People */}
          <label className="w-[260px]">
            <span className="font-bold text-black mb-5px block">People *</span>
            <GuestAgesPicker
              numberOfAdults={guestAges.numberOfAdults}
              agesOfAllChildren={guestAges.agesOfAllChildren}
              onUpdate={(numberOfAdults, agesOfAllChildren) => {
                setGuestAges(numberOfAdults, agesOfAllChildren || []);
                if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
                  // @ts-ignore
                  window.gtag('event', `${GA_FLOW_TAG}_set-guest`, {
                    'num-adults': numberOfAdults,
                    'child-ages': agesOfAllChildren,
                  });
                }
              }}
              disabled={searchAccommodationsRequest === ENetworkRequestStatus.PENDING}
            />
          </label>
        </div>

        <FluidButton
          disabled={!hotelUuid}
          onClick={handleSearchButtonClick}
          type="primary"
          isLoading={searchAccommodationsRequest === ENetworkRequestStatus.PENDING}
        >
          Search
        </FluidButton>
      </div>
    </div>
  )
}
