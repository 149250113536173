import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHideOnClickOutside } from 'hooks/useHideOnClickOutside';
import Loading from './Loading';
import AsyncSearchDropdownOptions, { IAsyncSearchDropdownOption } from './AsyncSearchDropdownOptions';

const scrollToElement = (instanceClassname: string, value?: string): void => {
  const itemsContainer = document.querySelector(`.${instanceClassname} .options-container`);
  const selectedItemContainer: HTMLElement | null = document.querySelector(
    `.${instanceClassname} .options-container .value-${value}`
  );

  if (itemsContainer && selectedItemContainer) {
    itemsContainer.scrollTop = Math.max(selectedItemContainer.offsetTop - 35, 0);
  }
};

export interface IAsyncSearchDropdownProps {
  fieldId: string;
  value?: string;
  selectedValue?: string;
  options: IAsyncSearchDropdownOption[];
  onChoose: (value?: string) => void;
  onChange: (value?: string) => void;
  label?: string;
  maxVisibleItems?: number;
  labelWhenNothingSelected?: string;
  useCustomLabelWhenNothingSelected?: boolean;
  className?: string;
  inputClassName?: string;
  showSelectedOption?: boolean;
  onOpen?: () => void;
  loading?: boolean;
  hasLegacyLook?: boolean;
  errorMessage?: string | null;
  errorClassName?: string;
  disabled?: boolean;
}

export const AsyncSearchDropdown: React.FC<IAsyncSearchDropdownProps> = React.memo(
  ({
    fieldId,
    value,
    selectedValue,
    options,
    onChange,
    onChoose,
    label,
    maxVisibleItems,
    className = '',
    inputClassName = '',
    onOpen = undefined,
    loading = false,
    errorMessage,
    errorClassName,
    disabled = false,
  }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const inputClassname = classnames('w-full text-15px leading-19px py-7px px-10px font-pt-sans outline-none', {
      'border-gray-40 bg-ivory': !errorMessage && !disabled,
      'border-red-95 bg-red-25': !!errorMessage && !disabled,
      'text-black border border-solid': !disabled,
      'text-gray-100 border-none bg-gray-10': disabled,
    });
    const selectedOption = options.find(item => item.value === selectedValue);

    const { instanceClassname } = useHideOnClickOutside(setIsOpen, 'select');

    const openDropdown = useCallback(() => {
      onOpen && onOpen();
      !disabled && setIsOpen(true);
    }, [disabled, onOpen]);

    const handleInputChange = useCallback(
      e => {
        onChange(e.target.value);
      },
      [onChange]
    );

    const handleChoose = useCallback(
      (value: string | undefined) => {
        onChoose(value);
        setIsOpen(false);
      },
      [setIsOpen, onChoose]
    );

    useEffect(() => {
      if (isOpen) {
        scrollToElement(instanceClassname, selectedOption?.value);
      }
    }, [isOpen, instanceClassname, selectedOption]);

    return (
      <div className={classnames(instanceClassname, 'flex flex-col min-w-75px', className)}>
        {label && (
          <label htmlFor={fieldId} className="font-pt-sans mb-5px text-black text-13px leading-17px tracking-2xs">
            {label}
          </label>
        )}
        <div
          id={fieldId}
          className={classnames(
            'relative flex w-full cursor-pointer items-center justify-between text-15px',
            inputClassName
          )}
        >
          <div className="options flex items-center justify-start relative h-full w-full">
            <input
              className={inputClassname}
              id={fieldId}
              onChange={handleInputChange}
              value={value}
              autoComplete="off"
              disabled={disabled}
              onFocus={openDropdown}
            />

            <div
              className={classnames(
                'w-full font-pt-sans absolute top-35px -left-1px z-30 border border-solid border-t-0 border-gray-20 bg-ivory',
                { hidden: !isOpen && !loading }
              )}
            >
              {loading ? (
                <div className="py-2">
                  <Loading />
                </div>
              ) : (
                <AsyncSearchDropdownOptions
                  options={options}
                  maxVisibleItems={maxVisibleItems}
                  value={selectedOption?.value}
                  onChoose={handleChoose}
                />
              )}
            </div>
          </div>
        </div>

        {errorMessage && (
          <p className={`text-red-95 text-13px leading-2xs font-pt-sans mt-5px ${errorClassName ?? ''}`}>
            {errorMessage}
          </p>
        )}
      </div>
    );
  }
);
