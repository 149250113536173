import { pipe, values, reverse, ifElse, always, length, equals, all, complement, both, path } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';
import { differenceInCalendarDays, eachDayOfInterval, format, subDays, addDays, differenceInDays } from 'date-fns';

import config from '../config';

export const SECONDS = 1000;

/**
 * To date
 *
 * Returns a new Date object
 *
 * @param {string | Date} date
 * @returns {Date}
 */
export const toDate = date => (date ? new Date(date) : new Date());

/**
 * Returns correct object length
 *
 * @param {object}
 * @returns {boolean}
 */
const correctLength = pipe(length, equals(2));

/**
 * Ensure all keys are populate
 *
 * @param {*}
 * @returns {boolean}
 */
const allPopulated = all(complement(isNilOrEmpty));

/**
 * Returns difference bewteen days
 *
 * @param {array} dates
 * @returns {*}
 */
const getDifference = dates => differenceInCalendarDays(...dates);

/**
 * Check both to and from exist
 *
 * @param {object}
 * @returns {boolean}
 */
const hasToFrom = both(correctLength, allPopulated);

/**
 * Get the number of days (nights) bewteen dates
 *
 * @param {object}
 * @returns {number | undefined}
 */
export const getNumberOfDays = pipe(values, reverse, ifElse(hasToFrom, getDifference, always(undefined)));

export const offsetDate = date => {
  const d = new Date(date);
  return new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
};

/**
 * Date formatter
 *
 * Wrapper around date-fns date formatter
 *
 * @param {string | Date | number} date
 * @param {string} pattern
 */
export const formatDate = (date, pattern = path(['defaults', 'dateFormat'], config)) => {
  // format already takes locale into account, offset function is a wrong concept,
  // changing the timestamp in date is wrong, 9:00am UK IS the same timestamp as 11:00am in Spain
  // not changing it just in case it impacts elsewhere
  return format(offsetDate(date), pattern);
};
export const formatDateWithoutOffset = (date, pattern = path(['defaults', 'dateFormat'], config)) => {
  return format(new Date(date), pattern);
};

export const addDaysUTC = (date, amount) => addDays(new Date(date), amount);
export const subDaysUTC = (date, amount) => subDays(new Date(date), amount);

export const formatDateDisplay = date => formatDate(date, path(['defaults', 'displayDateFormat'], config));
export const formatDateDisplayFourDigitYear = date => formatDate(date, 'd MMM yyyy');
export const formatDateTimeDisplay = date => formatDate(date, path(['defaults', 'displayDateTimeFormat'], config));
export const formatDateTimeDisplayWithoutOffset = date =>
  formatDateWithoutOffset(date, path(['defaults', 'displayDateTimeFormat'], config));
export const formatCreditNoteDateDisplay = date =>
  formatDate(date, path(['defaults', 'displayCreditNoteDateFormat'], config));

export const formatDateTimeIncludeTimezoneDisplay = date =>
  formatDate(date, path(['defaults', 'displayDateTimeIncludeTimezoneFormat'], config));

export const formatDateRangeDisplay = (startDate, endDate) => {
  if (!endDate) {
    return startDate ? formatDateDisplay(startDate) : null;
  }

  if (startDate === endDate) {
    return formatDateDisplay(startDate);
  }

  return `${formatDateDisplay(startDate)} - ${formatDateDisplay(endDate)}`;
};

export const generateArrayOfDatesBetween = (startDate, endDate) => {
  return eachDayOfInterval({
    start: offsetDate(startDate),
    end: offsetDate(endDate),
  }).map(date => format(date, path(['defaults', 'dateFormat'], config)));
};

export const dateToMidnightDate = date => {
  return new Date(formatDate(date));
};

export const numberOfNights = (arrivalDate, departureDate) => {
  if (!arrivalDate || !departureDate) {
    return '';
  }
  return differenceInDays(offsetDate(departureDate), offsetDate(arrivalDate));
};
