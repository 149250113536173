import { createSelector } from 'reselect';

import { IValueLabelPair, TCountryCode } from 'interfaces';
import { IHotelNameItem } from 'services/BackendApi/types/HotelNamesResponse';
import { BookingSearchQuery, ERateSource, ISalesRepresentative } from 'services/BookingManagerApi';

import { IBookingListExportData, IBookingsListDomain, DateRangeTypes } from './model';
import { EInvoiceType } from '../bookingManager/subdomains/breakdown/model';
import { IMultiselectValueLabelPair } from 'ui/Multiselect';
import { getCountry } from 'utils/country';
import { ENetworkRequestStatus } from 'services/BackendApi';

const bookingsListDomain = (state: any) => state.bookingsList;

export const requestPendingSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['requestPending'] => domain.requestPending
);

export const errorSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['error'] => domain.error
);

export const bookingsSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['bookings'] => {
    return domain.bookings;
  }
);

export const humanReadableIdFilterSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['humanReadableIdFilter'] => domain.humanReadableIdFilter
);

export const guestNameFilterSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['guestNameFilter'] => domain.guestNameFilter
);

export const travelPartnerRefFilterSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['travelPartnerRefFilter'] => domain.travelPartnerRefFilter
);

export const itemsPerPageSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['itemsPerPage'] => domain.itemsPerPage
);

export const currentPageSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['currentPage'] => domain.currentPage
);

export const totalResultsSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['totalResults'] => domain.totalResults
);

export const sortBySelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['sortBy'] => domain.sortBy
);

export const sortOrderSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['sortOrder'] => domain.sortOrder
);

export const pageCountSelector = createSelector(
  itemsPerPageSelector,
  totalResultsSelector,
  (itemsPerPage, totalResults): number => {
    return itemsPerPage && totalResults ? Math.ceil(totalResults / itemsPerPage) : 0;
  }
);

export const selectedTravelAgentUuidSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['travelAgentUuid'] => domain.travelAgentUuid
);

export const selectedTravelAgentCountryCodeSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['travelAgentCountryCode'] => domain.travelAgentCountryCode
);

export const selectedCompanyUuidSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['travelAgentUuid'] => domain.companyUuid
);

export const selectedSalesRepresentativeUuidSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['salesRepresentativeUuid'] => domain.salesRepresentativeUuid
);

export const selectedBookingTypeSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['bookingType'] => domain.bookingType
);

export const selectedDestinationSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['destination'] => domain.destination
);

export const selectedStatusesSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['bookingStatuses'] => domain.bookingStatuses
);

export const selectedPurchaseCostReviewStatusSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['purchaseCostReviewStatus'] => domain.purchaseCostReviewStatus
);

export const destinationListSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain) => domain.destinationList
);

export const destinationOptionsSelector = createSelector(
  destinationListSelector,
  ({ hotels, countries, requestStatus }): IMultiselectValueLabelPair[] => {
    if (requestStatus === ENetworkRequestStatus.PENDING) {
      return [{ value: '', label: 'Loading hotel names', disabled: true }];
    }
    if (requestStatus === ENetworkRequestStatus.ERROR) {
      return [{ value: '', label: 'Error loading hotel names', disabled: true }];
    }
    if (!hotels.length && !countries.length) {
      return [{ value: '', label: 'No hotels', disabled: true }];
    }
    return [
      { value: '', label: 'All Countries and Resorts' },
      { value: null, label: '', isDivider: true },
      ...countries.map(x => ({ value: x.code, label: x.name })),
      { value: null, label: '', isDivider: true },
      ...hotels.map(x => ({ value: x.uuid, label: x.name })),
    ];
  }
);

export const onlyShowWithInvoiceFilterSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['onlyShowWithInvoiceFilter'] => domain.onlyShowWithInvoiceFilter
);

export const onlyShowWithGroundServicesFilterSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['onlyShowWithGroundServicesFilter'] =>
    domain.onlyShowWithGroundServicesFilter
);

export const onlyShowWithProformaCreatedFilterSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['hasProformaCreated'] => domain.hasProformaCreated
);

export const onlyShowWithInvoiceCreatedFilterSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['hasInvoiceCreated'] => domain.hasInvoiceCreated
);

export const hasOverduePaymentsFilterSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['hasOverduePayments'] => domain.hasOverduePayments
);

export const showFullyPaidByTaFilterSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['showFullyPaidByTaFilter'] => domain.showFullyPaidByTaFilter
);

export const onlyShowMyBookingsFilterSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['onlyShowMyBookingsFilter'] => domain.onlyShowMyBookingsFilter
);

export const rateSourceFilterSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['rateSourceFilter'] => domain.rateSourceFilter
);

export const hasHotelCommissionAfterCheckoutFilterSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['hasHotelCommissionAfterCheckout'] =>
    domain.hasHotelCommissionAfterCheckout
);

export const onlyShowFilterSelector = createSelector(
  onlyShowWithInvoiceFilterSelector,
  onlyShowWithGroundServicesFilterSelector,
  onlyShowWithProformaCreatedFilterSelector,
  onlyShowWithInvoiceCreatedFilterSelector,
  hasOverduePaymentsFilterSelector,
  showFullyPaidByTaFilterSelector,
  onlyShowMyBookingsFilterSelector,
  rateSourceFilterSelector,
  hasHotelCommissionAfterCheckoutFilterSelector,
  (
    withInvoice: boolean,
    withGroundServices: boolean | null,
    withProformaCreated: boolean | null,
    withInvoiceCreated: boolean | null,
    withOverduePayments: boolean | null,
    isFullyPaidByTa: boolean | null,
    onlyMyBookings: boolean | null,
    rateSource: ERateSource[] | null,
    withHotelCommissionAfterCheckout: boolean | null
  ) => ({
    withInvoice,
    withGroundServices,
    withProformaCreated,
    withInvoiceCreated,
    withOverduePayments,
    isFullyPaidByTa,
    onlyMyBookings,
    rateSource,
    withHotelCommissionAfterCheckout,
  })
);

export const isViewOnlyAssignedToMeFilterSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['isViewOnlyAssignedToMeFilter'] =>
    domain.isViewOnlyAssignedToMeFilter
);

export const getSalesRepresentativesSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['salesRepList'] => domain.salesRepList
);

export const getSalesRepresentativesRequestPendingSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['salesRepListRequestPending'] => domain.salesRepListRequestPending
);

export const getSalesRepresentativesErrorSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['salesRepListError'] => domain.salesRepListError
);

export const selectedProductTypesFilterSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['productTypesFilter'] => domain.productTypesFilter
);

export const productConfirmationNumberFilterSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['productConfirmationNumberFilter'] =>
    domain.productConfirmationNumberFilter
);

export const salesRepresentativeOptionsSelector = createSelector(
  getSalesRepresentativesSelector,
  getSalesRepresentativesRequestPendingSelector,
  getSalesRepresentativesErrorSelector,
  (salesRepList: ISalesRepresentative[] | null, requestPending: boolean, error: string | null): IValueLabelPair[] => {
    if (requestPending) {
      return [{ value: '', label: 'Loading contacts', disabled: true }];
    }

    if (error) {
      return [{ value: '', label: 'Error loading contacts', disabled: true }];
    }

    if (!salesRepList) {
      return [{ value: '', label: 'No contacts', disabled: true }];
    }

    const initialOptions = [
      { value: '', label: 'No filter' },
      { value: 'no-assignment', label: 'No assignment' },
    ];
    const options = salesRepList.map(sr => ({ value: sr.salesRepUuid, label: sr.salesRepEmail }));
    return [...initialOptions, ...options];
  }
);

export const selectedDateRangeSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['dateRange'] => domain.dateRange
);

export const bookingsListQuerySelector = createSelector(
  sortBySelector,
  humanReadableIdFilterSelector,
  guestNameFilterSelector,
  travelPartnerRefFilterSelector,
  currentPageSelector,
  itemsPerPageSelector,
  sortOrderSelector,
  selectedTravelAgentUuidSelector,
  selectedTravelAgentCountryCodeSelector,
  selectedCompanyUuidSelector,
  selectedSalesRepresentativeUuidSelector,
  selectedDestinationSelector,
  selectedStatusesSelector,
  // @ts-ignore - definition for 13 args only
  selectedPurchaseCostReviewStatusSelector,
  selectedBookingTypeSelector,
  selectedDateRangeSelector,
  onlyShowFilterSelector,
  selectedProductTypesFilterSelector,
  productConfirmationNumberFilterSelector,
  (
    sortBy,
    humanReadableIdFilter,
    guestNameFilter,
    travelPartnerRefFilter,
    currentPage,
    itemsPerPage,
    sortOrder,
    travelAgentUuid,
    travelAgentCountryCode,
    companyUuid,
    salesRepresentativeUuid,
    destination,
    statuses,
    purchaseCostReviewStatus,
    bookingType,
    selectedDateRange,
    onlyShowFilter,
    selectedProductTypesFilter,
    productConfirmationNumberFilter
  ) => {
    const query: BookingSearchQuery = {
      filter: {},
      sort: {
        key: sortBy,
        order: sortOrder,
      },
      pagination: {
        offset: currentPage * itemsPerPage,
        limit: itemsPerPage,
      },
    };

    if (query.filter) {
      if (humanReadableIdFilter) {
        query.filter.humanReadableIdFilter = humanReadableIdFilter;
      }

      if (guestNameFilter) {
        query.filter.guestNameFilter = guestNameFilter;
      }

      if (travelPartnerRefFilter) {
        query.filter.travelPartnerRef = travelPartnerRefFilter;
      }

      if (travelAgentUuid) {
        query.filter.travelAgentUserUuid = travelAgentUuid;
      }

      if (travelAgentCountryCode) {
        query.filter.travelAgentCompanyCountryCode = travelAgentCountryCode;
      }

      if (companyUuid) {
        query.filter.travelAgentCompanyUuid = companyUuid;
      }

      if (salesRepresentativeUuid) {
        query.filter.salesRepUuid = salesRepresentativeUuid === 'no-assignment' ? null : salesRepresentativeUuid;
      }

      if (destination) {
        const country = getCountry(destination);

        if (country && country.isDestination) {
          query.filter.countryCode = destination;
        } else {
          query.filter.hotelUuid = destination;
        }
      }

      if (statuses) {
        query.filter.statuses = statuses;
      }

      if (bookingType) {
        query.filter.bookingType = bookingType;
      }

      if (purchaseCostReviewStatus) {
        query.filter.purchaseCostReviewStatus = purchaseCostReviewStatus;
      }

      if (onlyShowFilter.withInvoice === true) {
        query.filter.onlyShowWithInvoice = true;
      }

      if (onlyShowFilter.withProformaCreated !== null) {
        query.filter.automatedInvoiceFinanceSalesType = EInvoiceType.PROFORMA;
      }

      if (onlyShowFilter.withInvoiceCreated !== null) {
        query.filter.automatedInvoiceFinanceSalesType = EInvoiceType.FINAL;
      }

      if (onlyShowFilter.isFullyPaidByTa === null) {
        query.filter.fullyPaidByTa = undefined;
      } else {
        query.filter.fullyPaidByTa = onlyShowFilter.isFullyPaidByTa;
      }

      if (onlyShowFilter.withOverduePayments === true) {
        query.filter.hasOverduePayments = true;
      }

      if (onlyShowFilter.onlyMyBookings !== null) {
        query.filter.onlyMyBookings = true;
      }

      if (!!onlyShowFilter.rateSource?.length) {
        query.filter.rateSource = onlyShowFilter.rateSource;
      }

      if (onlyShowFilter.withHotelCommissionAfterCheckout !== null) {
        query.filter.withHotelCommissionAfterCheckout = true;
      }

      if (productConfirmationNumberFilter) {
        query.filter.productConfirmationNumber = productConfirmationNumberFilter;
      }

      if (selectedDateRange && selectedDateRange.startDate && selectedDateRange.endDate) {
        const { startDate, endDate } = selectedDateRange;

        switch (selectedDateRange.type) {
          case DateRangeTypes.Arrivals:
            query.filter.bookingArrivalFrom = startDate;
            query.filter.bookingArrivalTo = endDate;
            break;
          case DateRangeTypes.Departures:
            query.filter.bookingDepartureFrom = startDate;
            query.filter.bookingDepartureTo = endDate;
            break;
          case DateRangeTypes.Proforma:
            query.filter.automatedInvoiceFinanceSalesDateFrom = startDate;
            query.filter.automatedInvoiceFinanceSalesDateTo = endDate;
            query.filter.automatedInvoiceFinanceSalesType = EInvoiceType.PROFORMA;
            break;
          case DateRangeTypes.Invoice:
            query.filter.automatedInvoiceFinanceSalesDateFrom = startDate;
            query.filter.automatedInvoiceFinanceSalesDateTo = endDate;
            query.filter.automatedInvoiceFinanceSalesType = EInvoiceType.FINAL;
            break;
        }
      }
    }

    query.filter = {
      ...query.filter,
      ...selectedProductTypesFilter.reduce((acc, curr) => ((acc[curr] = true), acc), {}),
    };

    return query;
  }
);

export const exportDataSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['exportData'] => domain.exportData
);

export const isWaitingForExportSelector = createSelector(
  exportDataSelector,
  (domain: IBookingListExportData): IBookingListExportData['isWaitingForExport'] => domain.isWaitingForExport
);

export const exportUuidSelector = createSelector(
  exportDataSelector,
  (domain: IBookingListExportData): IBookingListExportData['exportUuid'] => domain.exportUuid
);

export const triggerExportLoadSelector = createSelector(
  exportDataSelector,
  (domain: IBookingListExportData): IBookingListExportData['triggerExportLoad'] => domain.triggerExportLoad
);

export const pollExportLoadSelector = createSelector(
  exportDataSelector,
  (domain: IBookingListExportData): IBookingListExportData['pollExportLoad'] => domain.pollExportLoad
);

export const exportTypeSelector = createSelector(
  exportDataSelector,
  (domain: IBookingListExportData): IBookingListExportData['exportType'] => domain.exportType
);

export const isRefetchingBookingsSelector = createSelector(
  bookingsListDomain,
  (domain: IBookingsListDomain): IBookingsListDomain['isRefetchingBookings'] => domain.isRefetchingBookings
);
