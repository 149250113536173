import { ENetworkRequestStatus, IGuestAges } from 'services/BackendApi';
import {
  IHeadlineLineItemBreakdown,
  IHeadlineBreakdownVersionListVersion,
  IOfferTerms,
  ICancellationPolicies,
  IPaymentTerms,
  IInvoiceAddressee,
  IPaymentMethod,
  IHeadlineLineItemBreakdownLineItem,
  IBAMState,
} from 'services/BookingManagerApi';

import { EBankAccount } from 'interfaces';
import { ICompanyMembership } from 'services/BookingManagerApi/types/CompanyMemberships';
import { SuppliersSearchItem } from 'services/BookingManagerApi/types/AncillaryService';

export enum EPdfLogo {
  MAIN_COMPANY_LOGO = 'main_company_logo',
  TA_LOGO = 'ta_logo',
  NO_LOGO = 'no_logo',
}

//FIXME remove from here as it needs to be defined as BMS response interface
export enum EInvoiceType {
  PROFORMA = 'proforma',
  FINAL = 'final',
  CANCELLATION = 'cancellation',
}

export enum EInvoiceAddresseeType {
  TRAVEL_PARTNER = 'travel_partner',
  FINAL_CLIENT = 'final_client',
}

export enum EInvoiceMutationMode {
  CREATE = 'create',
  UPDATE = 'update',
}

export enum EInvoiceLang {
  EN = 'en',
  AR = 'ar',
}

export interface ILiveCancellationModalDataAccom {
  bookingRef: string;
  date: string;
  cancellationFeeCents: number;
  rateCents: number;
  cancellationPolicies: string[];
}

export interface ILiveAccommodationData {
  bookingRef: string;
  rateCents: number;
  cancellationFeeCents: number;
}

export interface ILiveAccommodationCancellationPolicy {
  bookingRef: string;
  cancellationPolicies: string[];
}

export interface ILiveCancellationModalData {
  isLiveCancellationModalOpen: boolean;
  accoms: ILiveCancellationModalDataAccom[];
  cancellationFeeCentsTotal: number;
}

export interface IBookingManagerBreakdownSubdomain {
  headlineLineItemBreakdownCommited: IHeadlineLineItemBreakdown | null;
  headlineLineItemBreakdown: IHeadlineLineItemBreakdown | null;
  headlineBreakdownVersionList: IHeadlineBreakdownVersionListVersion[];
  headlineBreakdownSelectedVersion: IHeadlineBreakdownVersionListVersion | null;
  headlineBreakdownRealLatestVersion: IHeadlineBreakdownVersionListVersion | null;
  headlineLineItemBreakdownUpdatedAt: string | null;
  isHeadlineBreakdownEditedWithoutSaving: boolean;
  breakdownLogo: EPdfLogo | null;
  isCreateQuoteModalOpen: boolean;
  accommodation: IBAMState,
  activity: {
    isAddActivityModalOpen: boolean;
  };
  isAddAncillaryTransfersModalOpen: boolean;
  isAddAncillaryGroundServiceModalOpen: boolean;
  isAddAncillaryAccommodationModalOpen: boolean;
  createInvoice: {
    isCreateInvoiceModalOpen: boolean;
    isAddresseeModalOpen: boolean;
    bankAccount?: EBankAccount;
    invoiceAddresseeType: EInvoiceAddresseeType;
    invoiceAddressee?: IInvoiceAddressee;
    mode: EInvoiceMutationMode | null;
    lang: EInvoiceLang;
  };
  createManualInvoice: {
    isCreateManualInvoiceModalOpen: boolean;
    isAddresseeModalOpen: boolean;
    bankAccount?: EBankAccount;
    invoiceAddresseeType: EInvoiceAddresseeType;
    invoiceAddressee?: IInvoiceAddressee;
    mode: EInvoiceMutationMode | null;
    lang: EInvoiceLang;
  };
  paymentMethods: {
    list: IPaymentMethod[] | null;
    defaultPaymentMethodCode: string | null;
  };
  isSaveToPerformActionModalOpen: boolean;
  isPcrDetailsModalOpen: boolean;
  paymentTerms: IPaymentTerms | null;
  offerTerms: IOfferTerms | null;
  cancellationPolicies: ICancellationPolicies | null;
  policiesAndRestrictions: string | null;
  membership: ICompanyMembership | null;
  networkRequests: IBookingManagerBreakdownSubdomainNetworkRequests;

  barCommissionPercentage: number | null;
  barCommissionAmount: number | null;
  barCommissionErrors: {
    field: 'barCommissionPercentage' | 'barCommissionAmount';
    message: string;
  }[];

  // live cancellations
  liveCancellationModalData: ILiveCancellationModalData;

  isLiveCancellationWarningModalOpen: boolean;

  liveCancellationAccommodationData: ILiveAccommodationData[];
  liveAccommodationCancellationPolicies: ILiveAccommodationCancellationPolicy[];

  transfersSuppliers: SuppliersSearchItem[];
  groundServicesSuppliers: SuppliersSearchItem[];
  activitiesSuppliers: SuppliersSearchItem[];
  shouldUserNeedToConfirmCPsAndPTs: boolean;

  currentlyEditingLineItem: IHeadlineLineItemBreakdownLineItem | null;
  currentlyEditingLineItemIndex: number | null;
}

export interface IBookingManagerBreakdownSubdomainNetworkRequests {
  headlineLineItemBreakdownLoad: ENetworkRequestStatus;
  barCommission: ENetworkRequestStatus;
  saveAndUpdateHeadlineBreakdownLoad: ENetworkRequestStatus;
  downloadBreakdown: ENetworkRequestStatus;
  headlineBreakdownVersionListLoad: ENetworkRequestStatus;
  downloadBreakdownForClient: ENetworkRequestStatus;
  createInvoice: ENetworkRequestStatus;
  createManualInvoice: ENetworkRequestStatus;
  getPaymentMethods: ENetworkRequestStatus;
  paymentTermsLoad: ENetworkRequestStatus;
  offerTermsLoad: ENetworkRequestStatus;
  membershipLoad: ENetworkRequestStatus;
  membershipUpdate: ENetworkRequestStatus;
  cancellationPolicyLoad: ENetworkRequestStatus;
  policiesAndRestrictionsLoad: ENetworkRequestStatus;
  policiesAndTermsUpdate: ENetworkRequestStatus;
  invoiceAddresseeLoad: ENetworkRequestStatus;
  invoiceAddresseeSave: ENetworkRequestStatus;
  postLiveAccommodationCancellationLoad: ENetworkRequestStatus;
  getLiveAccommodationCancellationDataLoad: ENetworkRequestStatus;
  headlineLineItemBreakdownViaVersionReload: ENetworkRequestStatus;
  getSuppliers: ENetworkRequestStatus;
}

export const initialState: IBookingManagerBreakdownSubdomain = {
  headlineLineItemBreakdownCommited: null,
  headlineLineItemBreakdown: null,
  headlineBreakdownVersionList: [],
  headlineBreakdownSelectedVersion: null,
  headlineBreakdownRealLatestVersion: null,
  headlineLineItemBreakdownUpdatedAt: null,
  isHeadlineBreakdownEditedWithoutSaving: false,
  barCommissionPercentage: null,
  barCommissionAmount: null,

  barCommissionErrors: [],

  breakdownLogo: EPdfLogo.MAIN_COMPANY_LOGO,
  accommodation: {
    filters: {
      hotelUuid: '',
      startDate: '',
      endDate: '',
      guestAges: {
        numberOfAdults: 2,
        agesOfAllChildren: [],
      },
    },
    data: {
      accommodationResults: [],
      filterHotels: [],
    },
    requests: {
      filterHotelsRequest: ENetworkRequestStatus.IDLE,
      searchAccommodationsRequest: ENetworkRequestStatus.IDLE,
    },
    error: null,
  },
  activity: {
    isAddActivityModalOpen: false,
  },
  isAddAncillaryTransfersModalOpen: false,
  isAddAncillaryGroundServiceModalOpen: false,
  isAddAncillaryAccommodationModalOpen: false,
  createInvoice: {
    isCreateInvoiceModalOpen: false,
    isAddresseeModalOpen: false,
    bankAccount: undefined,
    invoiceAddresseeType: EInvoiceAddresseeType.TRAVEL_PARTNER,
    invoiceAddressee: undefined,
    mode: null,
    lang: EInvoiceLang.EN,
  },
  createManualInvoice: {
    isCreateManualInvoiceModalOpen: false,
    isAddresseeModalOpen: false,
    bankAccount: undefined,
    invoiceAddresseeType: EInvoiceAddresseeType.TRAVEL_PARTNER,
    invoiceAddressee: undefined,
    mode: null,
    lang: EInvoiceLang.EN,
  },
  paymentMethods: {
    list: null,
    defaultPaymentMethodCode: null,
  },
  isPcrDetailsModalOpen: false,
  isCreateQuoteModalOpen: false,
  isSaveToPerformActionModalOpen: false,
  paymentTerms: null,
  cancellationPolicies: null,
  policiesAndRestrictions: null,
  offerTerms: null,
  membership: null,
  transfersSuppliers: [],
  groundServicesSuppliers: [],
  activitiesSuppliers: [],
  networkRequests: {
    headlineLineItemBreakdownLoad: ENetworkRequestStatus.IDLE,
    barCommission: ENetworkRequestStatus.IDLE,
    saveAndUpdateHeadlineBreakdownLoad: ENetworkRequestStatus.IDLE,
    downloadBreakdown: ENetworkRequestStatus.IDLE,
    headlineBreakdownVersionListLoad: ENetworkRequestStatus.IDLE,
    downloadBreakdownForClient: ENetworkRequestStatus.IDLE,
    createInvoice: ENetworkRequestStatus.IDLE,
    createManualInvoice: ENetworkRequestStatus.IDLE,
    getPaymentMethods: ENetworkRequestStatus.IDLE,
    paymentTermsLoad: ENetworkRequestStatus.IDLE,
    cancellationPolicyLoad: ENetworkRequestStatus.IDLE,
    offerTermsLoad: ENetworkRequestStatus.IDLE,
    membershipLoad: ENetworkRequestStatus.IDLE,
    membershipUpdate: ENetworkRequestStatus.IDLE,
    policiesAndRestrictionsLoad: ENetworkRequestStatus.IDLE,
    policiesAndTermsUpdate: ENetworkRequestStatus.IDLE,
    invoiceAddresseeLoad: ENetworkRequestStatus.IDLE,
    invoiceAddresseeSave: ENetworkRequestStatus.IDLE,
    postLiveAccommodationCancellationLoad: ENetworkRequestStatus.IDLE,
    getLiveAccommodationCancellationDataLoad: ENetworkRequestStatus.IDLE,

    // we keep a separate load variable for when the headline is specifically changed
    // by re-loading all the versions. it allows a more fine grained styling and state-layout
    headlineLineItemBreakdownViaVersionReload: ENetworkRequestStatus.IDLE,

    getSuppliers: ENetworkRequestStatus.IDLE,
  },

  liveCancellationModalData: {
    isLiveCancellationModalOpen: false,
    accoms: [],
    cancellationFeeCentsTotal: 0,
  },

  isLiveCancellationWarningModalOpen: false,

  liveCancellationAccommodationData: [],
  liveAccommodationCancellationPolicies: [],

  shouldUserNeedToConfirmCPsAndPTs: false,

  currentlyEditingLineItem: null,
  currentlyEditingLineItemIndex: null,
};
