import React from 'react';
import classnames from 'classnames';

export enum ERefundabilityTagStatus {
  REFUNDABLE,
  NONREFUNDABLE,
}

export interface ICancellationTagProps {
  className: string;
  cancellationStatus: ERefundabilityTagStatus;
}
export const RefundabilityTag = (props: ICancellationTagProps) => {
  const { className, cancellationStatus } = props;
  return (
    <span
      className={classnames(
        `${className} font-hurmegeometric-sans text-xs leading-14px tracking-2xs rounded-2px not-italic font-normal my-5px`,
        {
          'text-green-message': cancellationStatus === ERefundabilityTagStatus.REFUNDABLE,
          'text-status-requested': cancellationStatus === ERefundabilityTagStatus.NONREFUNDABLE,
        }
      )}
    >
      {cancellationStatus === ERefundabilityTagStatus.REFUNDABLE ? 'Refundable' : 'Non-Refundable'}
    </span>
  );
};
