import React, { useCallback, useEffect, useState } from 'react';
import { GeneralModal } from 'ui/GeneralModal';
import { formatNumberToShortThousands, generateArrayOfDatesBetween } from 'utils';
import { Multiselect, IMultiselectProps } from 'ui/Multiselect';
import FluidButton from 'ui/FluidButton';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import { GuestAgesPicker } from 'ui/GuestAgesPicker/GuestAgesPicker';
import { format } from 'date-fns';
import DateRangeInput from 'pureUi/DateRangeInput';
import styled from 'styled-components';
import classNames from 'classnames';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { isNil } from 'lodash-es';
import {
  AncillaryProductWithRatesWithUserData,
  AncillaryRatedResult,
  AncillaryRatesProductsSearchItem,
  isAncillaryRatedResult,
  SelectedAncillarySearchResult,
} from 'services/BookingManagerApi/types/AncillaryService';
import { TCountryCode } from 'interfaces';
import { theme } from '../../../tailwind.config';
import { DatePickerCarousel } from 'ui/DatePickerCarousel';
import { AddAncillarySearchResultsTable } from 'ui/AddAncillarySharedComponents/AddAncillarySearchResultsTable';
import { AddAncillaryRightHandPanel } from 'ui/AddAncillarySharedComponents/AddAncillaryRightHandPanel';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { IGuestAges } from 'services/BackendApi';
import { AddAccommodationModalFilters, GA_FLOW_TAG } from './AddAccommodationModalFilters';
import { useDispatch, useSelector } from 'react-redux';
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import * as DashboardSelectors from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import { FSetStartAndEndDate, TStartAndEndDate } from './types';
import { calculateGuestAgesFromTopNavigationData } from './helpers';
import { IHeadlineLineItemBreakdownComponent } from 'ui/HeadlineLineItemBreakdown';


export interface IAddAccommodationModalProps {
  bookingUuid: string;
  bookingCurrencySymbol: IHeadlineLineItemBreakdownComponent['bookingCurrency'];
}

export const AddAccommodationModal = ({bookingUuid, bookingCurrencySymbol}: IAddAccommodationModalProps) => {
  const { dynamicParameters } = useDynamicParameters();
  const dispatch = useDispatch();
  const error = useSelector(BreakdownSelectors.BAMErrorSelector);
  const isAccommodationModalOpen = useSelector(BreakdownSelectors.breakdownAncillaryAccommodationModalToggleSelector);
  const hotelUuid = useSelector(BreakdownSelectors.BAMHotelFilterSelector);
  const searchAccommodationsRequest = useSelector(BreakdownSelectors.BAMSearchAccommodationsRequestSelector);

  const topNavigationData = useSelector(DashboardSelectors.topNavigationDataDashboardSelector);

  const [guestAges, setGuestAges] = useState<IGuestAges>(calculateGuestAgesFromTopNavigationData(topNavigationData));
  const handleSetGuestAges = useCallback((a: number, b?: number[]) => {
    setGuestAges({ numberOfAdults: a, agesOfAllChildren: b ? b : [] });
  }, [setGuestAges]);

  const [startAndEndDate, setStartAndEndDate] = useState<TStartAndEndDate>({
    startDate: topNavigationData?.arrivalDate as string,
    endDate: topNavigationData?.departureDate as string,
  });
  let selectedDates: string[] = [];
  if (startAndEndDate.startDate && startAndEndDate.endDate) {
    selectedDates = generateArrayOfDatesBetween(startAndEndDate.startDate, startAndEndDate.endDate).map(
      d => `${d}T00:00:00.000Z`
    );
  }

  const handleCloseModal = () => {
    if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
      // @ts-ignore
      window.gtag('event', `${GA_FLOW_TAG}_close`, {});
    }
    dispatch(BreakdownActions.setAddAncillaryAccommodationModalToggleAction(false));

    setGuestAges(calculateGuestAgesFromTopNavigationData(topNavigationData));
    setStartAndEndDate({
      startDate: topNavigationData?.arrivalDate as string,
      endDate: topNavigationData?.departureDate as string,
    });
  };



  if (!isAccommodationModalOpen) {
    return null;
  }

  if (!topNavigationData) {
    return <LoadingBar />
  }

  if (error) {
    return (
      <GeneralModal
        onClose={handleCloseModal}
        modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-clip"
        shouldCloseByClickingOutside={false}
      >
        <h2 className="m-0 p-0 font-noe-display text-21px leading-27px font-normal mb-25px">Add Accommodation</h2>
        <div>
          <ErrorBar message={error} />
        </div>
      </GeneralModal>
    );
  }

  return (
    <GeneralModal
      onClose={handleCloseModal}
      modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-clip"
      shouldCloseByClickingOutside={false}
    >
      <h2 className="m-0 p-0 font-noe-display text-21px leading-27px font-normal mb-25px">Add Accommodation</h2>

      <AddAccommodationModalFilters
        hotelUuid={hotelUuid}
        bookingCurrencySymbol={bookingCurrencySymbol}
        guestAges={guestAges}
        setGuestAges={handleSetGuestAges}
        selectedDates={selectedDates}
        setStartAndEndDate={setStartAndEndDate}
        error={error}
      />
        
      <div className="mt-[25px]">
        <span className="font-bold text-black mb-5px block">Search Results</span>
        {searchAccommodationsRequest === ENetworkRequestStatus.PENDING ? (
          <span className="font-normal">Searching...</span>
        ) : (
          <span>&nbsp;(99)</span>
        )}
      </div>

      <div className="all-panels flex space-x-[30px] overflow-x-hidden flex-grow h-[calc(100%-65px)]">
        <div
          className={classNames('left-panel flex flex-col flex-shrink space-y-25px')}
        >
        </div>

      </div>
    </GeneralModal>
  );
};
