import React from 'react';
import classnames from 'classnames';
import { formatPrice, formatPriceCents, getCurrencySymbol } from 'utils';
import { TCurrencyCode } from 'interfaces';

interface IPriceProps {
  total?: number | string | null;
  totalBeforeDiscount?: number | string | null;
  includesMargin?: boolean;
  currencyCode: TCurrencyCode;
  isOnRequest?: boolean;
  totalClassName: string;
  totalBeforeDiscountClassName: string;
  totalAfterDiscountClassName: string;
  isOnRequestClassName: string;
}

export const Price: React.FC<IPriceProps> = React.memo(
  ({
    total,
    totalBeforeDiscount,
    currencyCode,
    isOnRequest,
    totalClassName,
    totalBeforeDiscountClassName,
    totalAfterDiscountClassName,
    isOnRequestClassName,
  }) => {
    const offersApplied = total !== totalBeforeDiscount;
    const currencySymbol = getCurrencySymbol(currencyCode);
    const totalPrice = typeof total === 'number' ? formatPriceCents(total) : formatPrice(total);
    const totalBeforeDiscountPrice =
      typeof totalBeforeDiscount === 'number'
        ? formatPriceCents(totalBeforeDiscount)
        : formatPrice(totalBeforeDiscount);

    if (isOnRequest) {
      return <p className={classnames('price-on-request', isOnRequestClassName)}>On Request</p>;
    }

    if (offersApplied) {
      return (
        <div className="flex flex-col">
          <p className={classnames('price-after-discount', totalAfterDiscountClassName)}>
            {currencySymbol}&nbsp;{totalPrice}
          </p>
          <p className={classnames('price-before-discount mt-[5px]', totalBeforeDiscountClassName)}>
            {currencySymbol}&nbsp;{totalBeforeDiscountPrice}
          </p>
        </div>
      );
    }

    return (
      <p className={classnames('price-without-discount', totalClassName)}>
        {currencySymbol}&nbsp;{totalPrice}
      </p>
    );
  }
);
