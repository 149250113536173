import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IBasketBuildL2, IBasketBuildL4 } from 'services/BackendApi/types/Basket';
import { convertBasketBuildToBookingBuilderFormat } from './utils';
import { SvgIcon } from 'ui/SvgIcon';
import Star from 'ui/Icons/star.component.svg';
import CopyIcon from 'ui/Icons/copy.component.svg';
import PencilIcon from 'ui/Icons/pencil-no-circle.component.svg';
import TrashIcon from 'ui/Icons/trash-no-circle.component.svg';
import * as BookingBuilderUtils from 'utils/bookingBuilder';
import { formatDateDisplay, formatPrice, generateArrayOfDatesBetween, getCurrencySymbol } from 'utils';
import { ERoomRateType } from 'store/modules/bookingBuilder';
import { Radio } from 'ui/Radio';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { BasketItemExpandableInfo } from './BasketItemExpandableInfo';
import { useTranslation } from 'react-i18next';
import ArrowDown from 'ui/Icons/keyboard_arrow_down.component.svg';
import { theme } from '../../../tailwind.config';

import { ConfirmationModal, EConfirmationModalType } from '../../ui/ConfirmationModal';
import { makeBackendApi, ENetworkRequestStatus } from 'services/BackendApi';
import { enqueueNotification } from 'store/modules/ui';

import RejectedIcon from 'ui/Icons/rejected.component.svg';
import PriorityIcon from 'ui/Icons/priority-high.component.svg';
import { HidingTooltip } from 'ui/Tooltip';
import { isNil } from 'lodash-es';
import { LodgingSummary } from 'interfaces';
import { BookingBuilder } from 'services/BackendApi';
import * as DateFNS from 'date-fns';
import { CopyButton } from './components/CopyButton';
import PureCheckbox from 'pureUi/Checkbox';

interface IBasketItemProps {
  build: IBasketBuildL4;
  isSelected: boolean;
  onSelectBuild: (buildUuid: string, newState: boolean) => void;
  onEdit: () => void;
  onDeleteBuild?: (buildUuid: string) => void;
  actingOnBehalfOfUuid?: string | null | undefined;
  selectedTaUserUuid?: string | null;
}

const countString = (itemCount: number, noun: string) => {
  if (itemCount === 0) {
    return `No ${noun}s`;
  } else if (itemCount === 1) {
    return `${itemCount} ${noun}`;
  } else {
    return `${itemCount} ${noun}s`;
  }
};

const RateTypeBadge = ({ rateType }: { rateType: ERoomRateType }) => {
  switch (rateType) {
    case ERoomRateType.STATIC:
      return (
        <span className="block self-end font-hurmegeometric-sans py-2px px-5px text-black bg-white-true text-12px leading-14px uppercase">
          static rate
        </span>
      );

    case ERoomRateType.LIVE:
      return (
        <span className="block self-end font-hurmegeometric-sans py-2px px-5px text-black bg-teal-20 text-12px leading-14px uppercase">
          live rate
        </span>
      );
    default:
      return (
        <span className="block self-end font-hurmegeometric-sans py-2px px-5px text-black bg-white-true text-12px leading-14px">
          static rate
        </span>
      );
  }
};

export const BasketItem = (props: IBasketItemProps) => {
  // we add a day to the end to make it render correctly, and then _minus_ 1 from the total length
  // because we don't want to include the end date in the length (because the length is nights)
  const dispatch = useDispatch();

  const startDate = props.build.buildRequest.startDate;
  const endDate = DateFNS.addDays(new Date(props.build.buildRequest.endDate), 1);
  const lengthOfStay = generateArrayOfDatesBetween(startDate, endDate).length - 1;

  const dateRangeString = `${formatDateDisplay(startDate)} - ${formatDateDisplay(endDate)}`;
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const accommodationCount = props.build.buildRequest.Accommodation.length;
  const transferCount = props.build.buildRequest.Transfer.length;
  const groundServiceCount = props.build.buildRequest['Ground Service'].length;

  const guestCount =
    props.build.buildRequest.guestAges.numberOfAdults + props.build.buildRequest.guestAges.agesOfAllChildren.length;

  const currencySymbol = getCurrencySymbol(props.build.initialBuildResponse.currency);

  const hasLatestBookingBuild = !isNil(props.build.latestBookingBuilderResponse);

  // define the 2 booking builders (latest and initial)
  const latestBookingBuilder: BookingBuilder | undefined = hasLatestBookingBuild
    ? convertBasketBuildToBookingBuilderFormat(props.build, true)
    : undefined;
  const initialBookingBuilder = convertBasketBuildToBookingBuilderFormat(props.build, false);

  // determine which one we're going to use for rendering everything
  const effectiveBookingBuilder =
    hasLatestBookingBuild && latestBookingBuilder?.response.canBeBooked === true
      ? latestBookingBuilder
      : initialBookingBuilder;

  // a bunch of convenience variables
  const rateType = effectiveBookingBuilder.response?.potentialBooking?.Accommodation[0]?.isLiveRate
    ? ERoomRateType.LIVE
    : ERoomRateType.STATIC;

  const initialTotalCostString = initialBookingBuilder.response.totals.oneOrMoreItemsOnRequest
    ? 'On Request'
    : currencySymbol + formatPrice(initialBookingBuilder.response?.totals.total || 0);
  const latestTotalCostString = latestBookingBuilder?.response?.totals.oneOrMoreItemsOnRequest
    ? 'On Request'
    : currencySymbol + formatPrice(latestBookingBuilder?.response?.totals.total || 0);

  const isEffectiveOnRequest = effectiveBookingBuilder.response.totals.oneOrMoreItemsOnRequest;

  const isEffectiveDiscounted =
    (effectiveBookingBuilder.response.totals.totalBeforeDiscountForPricedItemsCents || 0) >
    (effectiveBookingBuilder.response.totals.totalForPricedItemsCents || 0);

  const canBookLatest = latestBookingBuilder?.response?.canBeBooked === true || false;

  const hasTotalsChanged =
    props.build.latestBookingBuilderResponse?.totals.total !== props.build.initialBuildResponse.totals.total ||
    props.build.latestBookingBuilderResponse?.totals.oneOrMoreItemsOnRequest !==
      props.build.initialBuildResponse.totals.oneOrMoreItemsOnRequest;

  const hasOffersChanged =
    props.build.latestBookingBuilderResponse?.appliedOfferNames.join(',') !==
    props.build.initialBuildResponse.appliedOfferNames.join(',');

  const total = formatPrice(effectiveBookingBuilder.response?.totals.total);
  const totalBeforeDiscount = formatPrice(effectiveBookingBuilder.response?.totals.totalBeforeDiscount);

  const initialOffersString = initialBookingBuilder.response?.appliedOfferNames.join(', ');
  const latestOffersString = latestBookingBuilder?.response?.appliedOfferNames.join(', ') || '';

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [basketDeletingNetwork, setBasketDeletingNetwork] = useState(ENetworkRequestStatus.IDLE);

  const backendApi = makeBackendApi(props.selectedTaUserUuid as string | undefined);

  const handleDeleteItemClick = useCallback(() => {
    setConfirmationModalOpen(true);
  }, []);

  const handleDeleteItemConfirmation = useCallback(async () => {
    setBasketDeletingNetwork(ENetworkRequestStatus.PENDING);
    try {
      await backendApi.removeFromBasket(props.build.uuid, props.actingOnBehalfOfUuid);
      setBasketDeletingNetwork(ENetworkRequestStatus.SUCCESS);
      dispatch(
        enqueueNotification({
          message: `Item removed from basket successfully`,
          options: { variant: 'success' },
        })
      );
      if (props.onDeleteBuild) {
        props.onDeleteBuild(props.build.uuid);
      }
      setConfirmationModalOpen(false);
    } catch (error) {
      setBasketDeletingNetwork(ENetworkRequestStatus.ERROR);
      dispatch(
        enqueueNotification({
          message: `Deleting Error. Please try later`,
          options: { variant: 'error' },
        })
      );
    }
    setConfirmationModalOpen(false);
  }, [backendApi, props.build.uuid]);

  return (
    <div
      id={`basket-item-${props.build.uuid}`}
      className={classNames('flex flex-col bg-gray-3 py-15px px-20px', {
        'outline outline-teal-100': props.isSelected,
      })}
    >
      {confirmationModalOpen && (
        <ConfirmationModal
          type={EConfirmationModalType.WARNING}
          className="confirm-import-modal"
          title="You are about to remove an item from the basket."
          message={`Are you sure you want to continue?`}
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
          onConfirm={handleDeleteItemConfirmation}
          onCancel={() => {
            setConfirmationModalOpen(false);
          }}
          isConfirmLoading={false}
        />
      )}
      <div className={classNames('flex items-center justify-around space-x-20px', {})} >
        <span className="w-[25px] h-[25px]">
          <PureCheckbox 
            onChange={() => canBookLatest && props.onSelectBuild(props.build.uuid, !props.isSelected)}
            checked={props.isSelected}
            className="w-[25px] h-[25px]" 
          />
        </span>
        <span className="2nd-col w-[200px] hidden lg:block">
          <img
            onClick={() => canBookLatest && props.onSelectBuild(props.build.uuid, !props.isSelected)}
            className={classNames('w-full aspect-video', {
              'cursor-pointer': canBookLatest,
            })}
            src={props.build.hotelPhotoUrl}
            alt="Hotel photo"
          />
        </span>
        <span className="3rd-col flex-grow">
          <span className="flex flex-row justify-between mb-10px">
            <div>
              <span className="flex items-center uppercase space-x-2 mb-5px">
                <SvgIcon IconComponent={Star} className="w-4 h-4 fill-brown-prime" />
                <span className="text-brown-prime text-12px leading-14px mt-3px">5 star</span>
              </span>
              {/* hotel name */}
              <span className="block font-noe-display text-brown-prime text-[20px] mb-5px">
                {props.build.initialBuildResponse.hotel.name}
              </span>
              {/* hotel region */}
              <span className="block font-hurmegeometric-sans text-brown-prime text-[14px] uppercase mb-15px">
                {props.build.initialBuildResponse.hotel.region}
              </span>

              <span className="flex items-center space-x-2 font-hurmegeometric-sans uppercase mb-10px">
                <span className="font-bold text-[13px] p-2px ">{dateRangeString}</span>
                <span className="bg-teal-20 text-13px p-2px">{lengthOfStay} nights</span>
                <span className="text-13px">{countString(guestCount, 'guest')}</span>
                <span className="text-13px">{countString(accommodationCount, 'room')}</span>
              </span>
              <span className="flex items-center space-x-2 font-hurmegeometric-sans uppercase">
                <span className="text-13px">{countString(transferCount, 'transfer')}</span>
                <span className="text-13px">{countString(groundServiceCount, 'ground service')}</span>
              </span>
            </div>
            <div className="flex flex-col space-y-5px">
              <span className="flex self-end items-center space-x-2">
                <div className="tool-tip icons flex space-x-2">
                  {/* if we can't book the latest, red triangle with errors */}
                  {!canBookLatest && (
                    <HidingTooltip
                      renderTooltipContent={() => {
                        return (
                          <div className="font-pt-sans text-13px p-2">
                            This item is no longer available and cannot be booked.
                            <br />
                            {hasLatestBookingBuild && <span>Please edit or remove the item.</span>}
                            {!hasLatestBookingBuild && <span>Please remove the item.</span>}
                          </div>
                        );
                      }}
                      position="left"
                    >
                      <SvgIcon IconComponent={RejectedIcon} className="w-5 h-5 fill-red-95 mt-1" />
                    </HidingTooltip>
                  )}

                  {/* if we can book the latest, and the totals have change but the offers haven't */}
                  {canBookLatest && hasTotalsChanged && !hasOffersChanged && (
                    <HidingTooltip
                      renderTooltipContent={() => {
                        return (
                          <div className="font-pt-sans text-13px p-2">
                            <span className="block">The total cost has changed</span>
                            <span className="flex justify-between space-x-3">
                              <span>Previous total cost was:</span>
                              <span className="font-bold">{initialTotalCostString}</span>
                            </span>
                            <span className="flex justify-between space-x-3">
                              <span>Current total cost is:</span>
                              <span className="font-bold">{latestTotalCostString}</span>
                            </span>
                          </div>
                        );
                      }}
                      position="left"
                    >
                      <SvgIcon IconComponent={PriorityIcon} className="w-5 h-5 fill-status-requested" />
                    </HidingTooltip>
                  )}

                  {/* if we can book the latest, and the offers have changed but the totals haven't */}
                  {canBookLatest && hasOffersChanged && !hasTotalsChanged && (
                    <HidingTooltip
                      renderTooltipContent={() => {
                        return (
                          <div className="font-pt-sans text-13px p-2">
                            <span className="block">The offers have changed</span>
                            <span className="block">Previous offers: {initialOffersString}</span>
                            <span className="block">New offers: {latestOffersString}</span>
                          </div>
                        );
                      }}
                      position="left"
                    >
                      <SvgIcon IconComponent={PriorityIcon} className="w-5 h-5 fill-status-requested" />
                    </HidingTooltip>
                  )}

                  {/* if we can book the latest, and the offers have changed AND the totals have changed */}
                  {canBookLatest && hasOffersChanged && hasTotalsChanged && (
                    <HidingTooltip
                      renderTooltipContent={() => {
                        return (
                          <div className="font-pt-sans text-13px p-2">
                            <span className="block">The total cost has changed</span>
                            <span className="flex justify-between space-x-3">
                              <span>Previous total cost was:</span>
                              <span className="font-bold">{initialTotalCostString}</span>
                            </span>
                            <span className="flex justify-between space-x-3">
                              <span>Current total cost is:</span>
                              <span className="font-bold">{latestTotalCostString}</span>
                            </span>
                            <br />
                            <span className="block">Previous offers: {initialOffersString}</span>
                            <span className="block">New offers: {latestOffersString}</span>
                          </div>
                        );
                      }}
                      position="left"
                    >
                      <SvgIcon IconComponent={PriorityIcon} className="w-5 h-5 fill-status-requested" />
                    </HidingTooltip>
                  )}
                </div>

                {/* always show copy button, copying either initial or latest */}
                <CopyButton booking={effectiveBookingBuilder} />

                {/* if we do have a latest booking build, they can edit it */}
                {hasLatestBookingBuild && (
                  <button
                    className="bg-transparent border-none outline-none cursor-pointer hover:bg-gray-20 rounded-full w-6 h-6 flex items-center justify-around"
                    onClick={props.onEdit}
                  >
                    <SvgIcon IconComponent={PencilIcon} width="18px" height="18px" className="-ml-[2px]" />
                  </button>
                )}

                {/* they can always delete */}
                <button
                  className="bg-transparent border-none outline-none cursor-pointer hover:bg-gray-20 rounded-full w-6 h-6 flex items-center justify-around"
                  onClick={handleDeleteItemClick}
                >
                  <SvgIcon IconComponent={TrashIcon} width="12px" height="14px" />
                </button>
              </span>

              <RateTypeBadge rateType={rateType} />

              {/* if we ARE on request, regardless of discount, show as on request */}
              {isEffectiveOnRequest ? (
                <div className="flex flex-col space-y-1">
                  <span className="uppercase font-hurmegeometric-sans text-flint text-13px font-bold text-right">
                    total cost
                  </span>
                  <span className="font-hurmegeometric-sans text-black text-15px leading-20px font-[600] text-right">
                    On Request
                  </span>
                </div>
              ) : null}

              {/* if we're NOT discounted and NOT on request, just show a single total */}
              {!isEffectiveDiscounted && !isEffectiveOnRequest ? (
                <div className="flex flex-col space-y-1">
                  <span className="uppercase font-hurmegeometric-sans text-flint text-13px font-bold text-right">
                    total cost
                  </span>
                  <span className="uppercase font-hurmegeometric-sans text-black text-19px leading-20px font-[600] text-right">
                    {currencySymbol}
                    {formatPrice(total)}
                  </span>
                </div>
              ) : null}

              {/* if we ARE discounted but NOT on request, show the total and total before discount */}
              {isEffectiveDiscounted && !isEffectiveOnRequest ? (
                <div className="flex flex-col space-y-1">
                  <span className="uppercase font-hurmegeometric-sans text-flint text-13px font-bold text-right">
                    total cost
                  </span>
                  <span className="uppercase font-hurmegeometric-sans text-red-92 text-19px leading-20px font-[600] text-right">
                    {currencySymbol}
                    {formatPrice(total)}
                  </span>
                  <span className="uppercase font-hurmegeometric-sans text-gray-80 text-15px leading-18px text-right line-through">
                    {currencySymbol}
                    {formatPrice(totalBeforeDiscount)}
                  </span>
                </div>
              ) : null}

              {/* theres always a button to show the expandable area */}
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className={classNames(
                  '!mt-auto self-end cursor-pointer flex justify-around items-center h-6 w-6 hover:bg-gray-40 active:bg-gray-40 bg-transparent border-none outline-none'
                )}
              >
                <SvgIcon
                  IconComponent={ArrowDown}
                  className={classNames('transition-transform w-[14px] fill-black stroke-1', {
                    'rotate-180': isExpanded,
                  })}
                />
              </button>
            </div>
          </span>
          {/* Hiding until future ticket */}
          {/* <span className="text-brown-prime text-13px leading-16px uppercase underline font-hurmegeometric-sans">
            add commission
          </span> */}
        </span>
      </div>
      <AnimateHeight duration={300} height={isExpanded ? 'auto' : 0} className={classNames('accordion-area', {})}>
        <BasketItemExpandableInfo
          key={props.build.uuid}
          booking={effectiveBookingBuilder}
          lodgingSummaries={BookingBuilderUtils.generateLodgingSummariesFromBooking(t, effectiveBookingBuilder)}
        />
      </AnimateHeight>
    </div>
  );
};
