import React from 'react';
import { Price } from 'ui/Price';
import { ProductLineWithPrice } from './ProductLineWithPrice';
import { BookingBuilder, ICompany, ProductSetAccommodation } from 'services/BackendApi';
import { PlainDates } from './PlainDates';
import { ERateType, RateTag } from 'ui/RateTag/RateTag';
import { uniq } from 'lodash-es';
import { LodgingSummary } from 'interfaces';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { addDays, format } from 'date-fns';
import { useSelector } from 'react-redux';
import { ERefundabilityTagStatus, RefundabilityTag } from 'components/AccommodationCard/RefundabilityTag';
import { companyDataSelector } from 'store/modules/companyInfo';
import { isRefundable as isRefundableFn } from 'common-lib/cancellation-policy-composer';
import { offsetDate } from 'utils';

interface IBookingAccommodationProps {
  booking: BookingBuilder;
  accommodation: ProductSetAccommodation;
  lodgingSummaries: LodgingSummary[];
  startDate: string;
  endDate: string;
  accommodationIndex: number;
}

export const BookingAccommodation: React.FC<IBookingAccommodationProps> = React.memo(
  ({ booking, startDate, endDate, lodgingSummaries, accommodation, accommodationIndex }) => {
    const company = useSelector(companyDataSelector) as ICompany;
    const isTa: boolean = useSelector(AuthSelectors.isTA);
    const selectedMealPlan = accommodation.availableSubProductSets['Meal Plan'].find(item => item.selected)!;
    const priceClassName = 'font-hurmegeometric-sans text-15px leading-18px text-right mt-5px m-0';
    const isInstantBook = lodgingSummaries[accommodationIndex].availableToInstantBook && (!isTa || (isTa && company?.enableInstantBooking));
    const requestedAccommodation = booking.request.Accommodation[accommodationIndex];
    const accommodationOffers: string[] = [];
    accommodation.breakdown.forEach(item => {
      item.offers.forEach(offer => accommodationOffers.push(offer.offer.name));
    });
    const mealPlanOffers: string[] = [];
    accommodation.availableSubProductSets['Meal Plan']
      .filter(mealPlan => mealPlan.selected)
      .forEach(mealPlan => {
        mealPlan.breakdown.forEach(item => {
          item.offers.forEach(offer => mealPlanOffers.push(offer.offer.name));
        });
      });
    const offers = uniq([...accommodationOffers, ...mealPlanOffers]);
    
    const expenseInfo = booking.response.expenseInfosGroupedByRoom[accommodationIndex];
    const isRefundable = isRefundableFn(expenseInfo);

    return (
      <div className="confirmation-accommodation px-5 py-15px border-solid border-b border-gray-20">
        <div className="flex justify-between items-center">
          <p className="accommodation-title font-hurmegeometric-sans text-flint text-13px leading-16px uppercase font-bold m-0">
            {accommodation.products[0].name}
          </p>
          {isInstantBook ? <RateTag rateType={ERateType.INSTANT_BOOK} /> : null}
        </div>
        <div className="refundability-tag">
          {isRefundable !== null && (
            <RefundabilityTag
              cancellationStatus={isRefundable ? ERefundabilityTagStatus.REFUNDABLE : ERefundabilityTagStatus.NONREFUNDABLE}
              className="acc-free-sale-rates-tag flex items-center m-0"
            />
          )}
        </div>
        <div className="accommodation-data flex items-start justify-between">
          <div className="accommodation-data-left">
            <div className="dates-line flex justify-start mt-5px">
              <PlainDates checkInDate={requestedAccommodation.startDate} checkOutDate={format(addDays(offsetDate(requestedAccommodation.endDate), 1), 'yyyy-MM-dd')} />
              <p className="booking-adults font-hurmegeometric-sans text-13px leading-16px text-flint uppercase ml-5 m-0">
                {requestedAccommodation.guestAges?.numberOfAdults} x {requestedAccommodation.guestAges?.numberOfAdults === 1 ? 'ADULT' : 'ADULTS'}
              </p>
              {requestedAccommodation.guestAges?.agesOfAllChildren?.length ? (
                <p className="booking-children font-hurmegeometric-sans text-13px leading-16px text-flint uppercase ml-5 m-0">
                  {requestedAccommodation.guestAges.agesOfAllChildren?.length} x {requestedAccommodation.guestAges.agesOfAllChildren?.length === 1 ? 'CHILD' : 'CHILDREN'}
                </p>
              ) : null}
            </div>
            {offers.map(offer => (
              <div className="offer-item font-hurmegeometric-sans text-13px leading-16px text-red-92 uppercase mt-[5px] mr-[30px]">
                {offer}
              </div>
            ))}
          </div>
          <div className="accommodation-data-right">
            <Price
              total={accommodation.total}
              totalBeforeDiscount={accommodation.totalBeforeDiscount}
              currencyCode={booking.response.currency}
              isOnRequest={accommodation.isOnRequestOrPartiallyOnRequest}
              totalClassName={`${priceClassName} text-flint`}
              totalBeforeDiscountClassName={`${priceClassName} text-gold-light line-through`}
              totalAfterDiscountClassName={`${priceClassName} text-red-15`}
              isOnRequestClassName={`${priceClassName} text-flint`}
            />
          </div>
        </div>

        <div className="meal-plans mt-[10px]">
          <ProductLineWithPrice
            name={selectedMealPlan.products[0].name}
            total={selectedMealPlan.total}
            totalBeforeDiscount={selectedMealPlan.totalBeforeDiscount}
            currencyCode={booking.response.currency}
            isOnRequest={selectedMealPlan.isOnRequestOrPartiallyOnRequest}
            className="meal-plan"
          />
        </div>
      </div>
    );
  }
);
