import {
  InventoryReference,
} from '../../../interfaces';

export const SET_IS_MENU_OPEN = 'inventoryHeader/SET_IS_MENU_OPEN';
export const SET_INVENTORY_OPTION_SELECTED = 'inventoryHeader/SET_INVENTORY_OPTION_SELECTED';
export const RESET_INVENTORY_OPTION_SELECTED = 'inventoryHeader/RESET_INVENTORY_OPTION_SELECTED';

export type SetIsMenuOpenAction = ReturnType<typeof setIsMenuOpenAction>;
export const setIsMenuOpenAction = (val: boolean) => ({
  type: SET_IS_MENU_OPEN as typeof SET_IS_MENU_OPEN,
  val,
});

export type SetInventoryOptionSelectedAction = ReturnType<typeof setInventoryOptionSelectedAction>;
export const setInventoryOptionSelectedAction = (
  path: InventoryReference[], 
  url : string | null,
) => ({
  type: SET_INVENTORY_OPTION_SELECTED as typeof SET_INVENTORY_OPTION_SELECTED,
  path,
  url,
});

export type ResetInventoryOptionSelectedAction = ReturnType<typeof resetInventoryOptionSelectedAction>;
export const resetInventoryOptionSelectedAction = () => ({
  type: RESET_INVENTORY_OPTION_SELECTED as typeof RESET_INVENTORY_OPTION_SELECTED,
});

export type TInventoryHeaderActions = 
  SetIsMenuOpenAction |
  SetInventoryOptionSelectedAction |
  ResetInventoryOptionSelectedAction;
