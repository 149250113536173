import { ENetworkRequestStatus } from 'services/BackendApi';

import {
  ILiveRatesListingItem,
  ELiveRatesExternalSystem,
  ELiveRatesSettingsListFilterType,
  ILiveRatesSettingsInternalRoomsItem,
  ILiveRatesSettingsExternalRoomsItem,
  ILiveRatesSettingsInternalRoomItemError,
  ILiveRatesHotel,
  ILiveRatesMappableStay,
} from 'services/BackendApi/types/LiveRatesInternal';

import { ILiveRatesDomain } from './model';

export const LIST_ACTION_TYPES = {
  GET_REQUEST: 'liveRates/list/GET_REQUEST' as const,
  GET_SUCCESS: 'liveRates/list/GET_SUCCESS' as const,
  GET_FAILURE: 'liveRates/list/GET_FAILURE' as const,

  SET_FILTER_NAME: 'liveRates/list/SET_FILTER_NAME' as const,
  SET_FILTER_EXTERNAL_SYSTEM: 'liveRates/list/SET_FILTER_EXTERNAL_SYSTEM' as const,

  SET_LISTING_PAGE_GET: 'liveRates/list/SET_LISTING_PAGE_GET' as const,
  SET_PAGE: 'liveRates/SET_PAGE' as const,
  SET_PER_PAGE: 'liveRates/SET_PER_PAGE' as const,

  SET_SORT_DATA: 'liveRates/SET_SORT_DATA' as const,
  SET_SORT_BY: 'liveRates/SET_SORT_BY' as const,
  SET_SORT_ORDER: 'liveRates/SET_SORT_ORDER' as const,
  SET_TO_DELETE: 'liveRates/list/SET_TO_DELETE' as const,
  DELETE_REQUEST: 'liveRates/list/DELETE_REQUEST' as const,
  DELETE_SUCCESS: 'liveRates/list/DELETE_SUCCESS' as const,
  DELETE_FAILURE: 'liveRates/list/DELETE_FAILURE' as const,
};

export const SETTINGS_PAGE_ACTION_TYPES = {
  GET_STAY_AND_INTERNAL_ROOMS_REQUEST: 'liveRates/settingsPage/GET_STAY_AND_INTERNAL_ROOMS_REQUEST' as const,
  GET_STAY_AND_INTERNAL_ROOMS_SUCCESS: 'liveRates/settingsPage/GET_STAY_AND_INTERNAL_ROOMS_SUCCESS' as const,
  GET_STAY_AND_INTERNAL_ROOMS_FAILURE: 'liveRates/settingsPage/GET_STAY_AND_INTERNAL_ROOMS_FAILURE' as const,

  GET_EXTERNAL_ROOMS_REQUEST: 'liveRates/settingsPage/GET_EXTERNAL_ROOMS_REQUEST' as const,
  GET_EXTERNAL_ROOMS_SUCCESS: 'liveRates/settingsPage/GET_EXTERNAL_ROOMS_SUCCESS' as const,
  GET_EXTERNAL_ROOMS_FAILURE: 'liveRates/settingsPage/GET_EXTERNAL_ROOMS_FAILURE' as const,

  ATTEMPT_SET_INTERNAL_ROOMS_FILTER_REQUEST: 'liveRates/settingsPage/ATTEMPT_SET_INTERNAL_ROOMS_FILTER_REQUEST' as const,
  ATTEMPT_SET_INTERNAL_ROOMS_FILTER_SUCCESS: 'liveRates/settingsPage/ATTEMPT_SET_INTERNAL_ROOMS_FILTER_SUCCESS' as const,
  SET_INTERNAL_ROOMS_FILTER: 'liveRates/settingsPage/SET_INTERNAL_ROOMS_FILTER' as const,
  SET_EXTERNAL_ROOMS_FILTER: 'liveRates/settingsPage/SET_EXTERNAL_ROOMS_FILTER' as const,

  SET_HAS_UNSAVED_CHANGES: 'liveRates/settingsPage/SET_HAS_UNSAVED_CHANGES' as const,
  SET_SHOW_UNSAVED_CHANGES_MODAL: 'liveRates/settingsPage/SET_SHOW_UNSAVED_CHANGES_MODAL' as const,
  RESET_INTERNAL_ROOMS_TO_ORIGINAL_STATE: 'liveRates/settingsPage/RESET_INTERNAL_ROOMS_TO_ORIGINAL_STATE' as const,

  SET_INTERNAL_ROOM_EXTERNAL_ID: 'liveRates/settingsPage/SET_INTERNAL_ROOM_EXTERNAL_ID' as const,

  SAVE_LISTS_REQUEST: 'liveRates/settingsPage/SAVE_LISTS_REQUEST' as const,
  SAVE_LISTS_SUCCESS: 'liveRates/settingsPage/SAVE_LISTS_SUCCESS' as const,
  SAVE_LISTS_FAILURE: 'liveRates/settingsPage/SAVE_LISTS_FAILURE' as const,

  SET_INTERNAL_ROOM_ERRORS: 'liveRates/settingsPage/SET_INTERNAL_ROOM_ERRORS' as const,
  CLEAR_INTERNAL_ROOM_ERRORS: 'liveRates/settingsPage/CLEAR_INTERNAL_ROOM_ERRORS' as const,
};
export const CREATE_ACTIONS = {
  GET_MAPPABLE_STAYS_REQUEST: 'liveRates/create/GET_MAPPABLE_STAYS_REQUEST' as const,
  GET_MAPPABLE_STAYS_SUCCESS: 'liveRates/create/GET_MAPPABLE_STAYS_SUCCESS' as const,
  GET_MAPPABLE_STAYS_FAILURE: 'liveRates/create/GET_MAPPABLE_STAYS_FAILURE' as const,
  PATCH_MAPPABLE_STAY_REQUEST: 'liveRates/create/PATCH_MAPPABLE_STAY_REQUEST' as const,
  PATCH_MAPPABLE_STAY_SUCCESS: 'liveRates/create/PATCH_MAPPABLE_STAY_SUCCESS' as const,
  PATCH_MAPPABLE_STAY_FAILURE: 'liveRates/create/PATCH_MAPPABLE_STAY_FAILURE' as const,
  OPEN_NEW_MAPPING_MODAL: 'liveRates/create/OPEN_NEW_MAPPING_MODAL' as const,
  CLOSE_NEW_MAPPING_MODAL: 'liveRates/create/CLOSE_NEW_MAPPING_MODAL' as const,
};

export const listActions = {
  getRequestAction: () => ({
    type: LIST_ACTION_TYPES.GET_REQUEST,
  }),
  getSuccessAction: (data: ILiveRatesListingItem[], total: number) => ({
    type: LIST_ACTION_TYPES.GET_SUCCESS,
    data,
    total,
  }),
  getFailureAction: (error: string) => ({
    type: LIST_ACTION_TYPES.GET_FAILURE,
    error,
  }),

  setFilterName: (name: string | undefined) => ({
    type: LIST_ACTION_TYPES.SET_FILTER_NAME,
    name,
  }),

  setFilterExternalSystem: (externalSystem: ELiveRatesExternalSystem | undefined) => ({
    type: LIST_ACTION_TYPES.SET_FILTER_EXTERNAL_SYSTEM,
    externalSystem,
  }),

  setListingPageGet: (val: ENetworkRequestStatus) => ({
    type: LIST_ACTION_TYPES.SET_LISTING_PAGE_GET,
    val,
  }),

  setPaginationPage: (val: number) => ({
    type: LIST_ACTION_TYPES.SET_PAGE,
    val,
  }),
  setPaginationItemsPerPage: (val: number) => ({
    type: LIST_ACTION_TYPES.SET_PER_PAGE,
    val,
  }),

  setSortData: (
    sortBy: ILiveRatesDomain['listingPage']['filter']['order_by'],
    sortOrder: ILiveRatesDomain['listingPage']['filter']['order_type']
  ) => ({
    type: LIST_ACTION_TYPES.SET_SORT_DATA,
    sortBy,
    sortOrder,
  }),

  setToDeleteAction: (value: ILiveRatesListingItem | null) => ({
    type: LIST_ACTION_TYPES.SET_TO_DELETE as typeof LIST_ACTION_TYPES.SET_TO_DELETE,
    value,
  }),

  deleteRequestAction: () => ({
    type: LIST_ACTION_TYPES.DELETE_REQUEST as typeof LIST_ACTION_TYPES.DELETE_REQUEST,
  }),

  deleteSuccessAction: () => ({
    type: LIST_ACTION_TYPES.DELETE_SUCCESS as typeof LIST_ACTION_TYPES.DELETE_SUCCESS,
  }),

  deleteFailureAction: (error: string) => ({
    type: LIST_ACTION_TYPES.DELETE_FAILURE as typeof LIST_ACTION_TYPES.DELETE_FAILURE,
    error,
  }),
};

export const settingsPageActions = {
  getStayAndInternalRoomsRequestAction: (stayUuid: string) => ({
    type: SETTINGS_PAGE_ACTION_TYPES.GET_STAY_AND_INTERNAL_ROOMS_REQUEST,
    stayUuid,
  }),
  getStayAndInternalRoomsSuccessAction: (
    stayInformation: ILiveRatesDomain['settingsPage']['stayInformation'],
    internalRoomsList: ILiveRatesSettingsInternalRoomsItem[]
  ) => ({
    type: SETTINGS_PAGE_ACTION_TYPES.GET_STAY_AND_INTERNAL_ROOMS_SUCCESS,
    stayInformation,
    internalRoomsList,
  }),
  getStayAndInternalRoomsFailureAction: () => ({
    type: SETTINGS_PAGE_ACTION_TYPES.GET_STAY_AND_INTERNAL_ROOMS_FAILURE,
  }),
  getExternalRoomsRequestAction: (stayUuid: string) => ({
    type: SETTINGS_PAGE_ACTION_TYPES.GET_EXTERNAL_ROOMS_REQUEST,
    stayUuid,
  }),
  getExternalRoomsSuccessAction: (externalRoomsList: ILiveRatesSettingsExternalRoomsItem[]) => ({
    type: SETTINGS_PAGE_ACTION_TYPES.GET_EXTERNAL_ROOMS_SUCCESS,
    externalRoomsList,
  }),
  getExternalRoomsFailureAction: () => ({
    type: SETTINGS_PAGE_ACTION_TYPES.GET_EXTERNAL_ROOMS_FAILURE,
  }),

  attemptSetInternalRoomsFilterRequest: (name: ELiveRatesSettingsListFilterType) => ({
    type: SETTINGS_PAGE_ACTION_TYPES.ATTEMPT_SET_INTERNAL_ROOMS_FILTER_REQUEST,
    name,
  }),

  attemptSetInternalRoomsFilterSuccess: (name: ELiveRatesSettingsListFilterType) => ({
    type: SETTINGS_PAGE_ACTION_TYPES.ATTEMPT_SET_INTERNAL_ROOMS_FILTER_SUCCESS,
    name,
  }),

  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => ({
    type: SETTINGS_PAGE_ACTION_TYPES.SET_HAS_UNSAVED_CHANGES,
    hasUnsavedChanges,
  }),

  setShowUnsavedChangesModal: (showUnsavedChangesModal: boolean) => ({
    type: SETTINGS_PAGE_ACTION_TYPES.SET_SHOW_UNSAVED_CHANGES_MODAL,
    showUnsavedChangesModal,
  }),

  resetInternalRoomsToOriginalState: () => ({
    type: SETTINGS_PAGE_ACTION_TYPES.RESET_INTERNAL_ROOMS_TO_ORIGINAL_STATE,
  }),

  setInternalRoomsFilter: (name: ELiveRatesSettingsListFilterType) => ({
    type: SETTINGS_PAGE_ACTION_TYPES.SET_INTERNAL_ROOMS_FILTER,
    name,
  }),

  setExternalRoomsFilter: (name: ELiveRatesSettingsListFilterType) => ({
    type: SETTINGS_PAGE_ACTION_TYPES.SET_EXTERNAL_ROOMS_FILTER,
    name,
  }),
  setInternalRoomExternalId: (internalRoomUuid: string, externalId: string) => ({
    type: SETTINGS_PAGE_ACTION_TYPES.SET_INTERNAL_ROOM_EXTERNAL_ID,
    internalRoomUuid,
    externalId,
  }),
  saveListsRequest: () => ({
    type: SETTINGS_PAGE_ACTION_TYPES.SAVE_LISTS_REQUEST,
  }),
  saveListsSuccess: () => ({
    type: SETTINGS_PAGE_ACTION_TYPES.SAVE_LISTS_SUCCESS,
  }),
  saveListsFailure: () => ({
    type: SETTINGS_PAGE_ACTION_TYPES.SAVE_LISTS_FAILURE,
  }),

  setInternalRoomsErrors: (errors: ILiveRatesSettingsInternalRoomItemError[]) => ({
    type: SETTINGS_PAGE_ACTION_TYPES.SET_INTERNAL_ROOM_ERRORS,
    errors,
  }),
  clearInternalRoomsErrors: () => ({
    type: SETTINGS_PAGE_ACTION_TYPES.CLEAR_INTERNAL_ROOM_ERRORS,
  }),
};

export const createActions = {
  getMappableStaysRequestAction: () => ({
    type: CREATE_ACTIONS.GET_MAPPABLE_STAYS_REQUEST,
  }),
  getMappableStaysSuccessAction: (unmappedHotels: ILiveRatesHotel[], mappableStays: ILiveRatesMappableStay[]) => ({
    type: CREATE_ACTIONS.GET_MAPPABLE_STAYS_SUCCESS,
    unmappedHotels,
    mappableStays,
  }),
  getMappableStaysFailureAction: (error: string) => ({
    type: CREATE_ACTIONS.GET_MAPPABLE_STAYS_FAILURE,
    error,
  }),
  patchMappableStayRequestAction: (
    stayUuid: string,
    externalStayUuid: string,
    externalSupplier: ELiveRatesExternalSystem
  ) => ({
    type: CREATE_ACTIONS.PATCH_MAPPABLE_STAY_REQUEST,
    stayUuid,
    externalStayUuid,
    externalSupplier,
  }),
  patchMappableStaySuccessAction: () => ({
    type: CREATE_ACTIONS.PATCH_MAPPABLE_STAY_SUCCESS,
  }),
  patchMappableStayFailureAction: (error: string) => ({
    type: CREATE_ACTIONS.PATCH_MAPPABLE_STAY_FAILURE,
    error,
  }),
  openNewMappingModalAction: () => ({
    type: CREATE_ACTIONS.OPEN_NEW_MAPPING_MODAL,
  }),
  closeNewMappingModalAction: () => ({
    type: CREATE_ACTIONS.CLOSE_NEW_MAPPING_MODAL,
  }),
};

export type GetStayAndInternalRoomsRequestAction = ReturnType<typeof settingsPageActions.getStayAndInternalRoomsRequestAction>;
export type GetExternalRoomsRequestAction = ReturnType<typeof settingsPageActions.getExternalRoomsRequestAction>;
export type PatchMappableStayRequestAction = ReturnType<typeof createActions.patchMappableStayRequestAction>;

export type ListActions = ReturnType<
  | typeof listActions.getRequestAction
  | typeof listActions.getSuccessAction
  | typeof listActions.getFailureAction
  | typeof listActions.setFilterName
  | typeof listActions.setFilterExternalSystem
  | typeof listActions.setListingPageGet
  | typeof listActions.setPaginationPage
  | typeof listActions.setPaginationItemsPerPage
  | typeof listActions.setSortData
  | typeof listActions.setToDeleteAction
  | typeof listActions.deleteRequestAction
  | typeof listActions.deleteSuccessAction
  | typeof listActions.deleteFailureAction
>;

export type SettingsPageActions = ReturnType<
  | typeof settingsPageActions.getStayAndInternalRoomsRequestAction
  | typeof settingsPageActions.getStayAndInternalRoomsSuccessAction
  | typeof settingsPageActions.getStayAndInternalRoomsFailureAction
  | typeof settingsPageActions.getExternalRoomsRequestAction
  | typeof settingsPageActions.getExternalRoomsSuccessAction
  | typeof settingsPageActions.getExternalRoomsFailureAction
  | typeof settingsPageActions.setInternalRoomsFilter
  | typeof settingsPageActions.setExternalRoomsFilter
  | typeof settingsPageActions.setInternalRoomExternalId
  | typeof settingsPageActions.saveListsRequest
  | typeof settingsPageActions.saveListsSuccess
  | typeof settingsPageActions.saveListsFailure
  | typeof settingsPageActions.setInternalRoomsErrors
  | typeof settingsPageActions.clearInternalRoomsErrors
  | typeof settingsPageActions.attemptSetInternalRoomsFilterRequest
  | typeof settingsPageActions.attemptSetInternalRoomsFilterSuccess
  | typeof settingsPageActions.setHasUnsavedChanges
  | typeof settingsPageActions.setShowUnsavedChangesModal
  | typeof settingsPageActions.resetInternalRoomsToOriginalState
>;

export type CreateActions = ReturnType<
  | typeof createActions.getMappableStaysRequestAction
  | typeof createActions.getMappableStaysSuccessAction
  | typeof createActions.getMappableStaysFailureAction
  | typeof createActions.openNewMappingModalAction
  | typeof createActions.closeNewMappingModalAction
  | typeof createActions.patchMappableStayRequestAction
  | typeof createActions.patchMappableStaySuccessAction
  | typeof createActions.patchMappableStayFailureAction
>;

export type AllActions = ListActions | CreateActions | SettingsPageActions;
