import React, { useState, useEffect, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isAdult, formatPrice, formatPriceCents, formatDate, generateArrayOfDatesBetween } from 'utils';
import { RateMixingWarning } from 'components/RateMixingWarning';
import { AvailabilityTag, eAvailabilityTagStatus } from './AvailabilityTag';
import { BasketRateTypes } from 'interfaces';
import { isNilOrEmpty } from 'ramda-adjunct';
import {
  IHAPFloorPlan,
  IHAPFormattedAccommodationProductStaticRate,
  IHAPFormattedAccommodationProductWithLiveRatesWithBookingComRates,
} from 'store/modules/hotelAccommodationProducts/types';
import { TCurrencyCode } from 'interfaces';
import { getFromPrices } from './utils';
import * as DateFNS from 'date-fns';
import * as StaticRatesAvailabilityActions from 'store/modules/staticRatesAvailability/actions';
import * as StaticRatesAvailabilitySelectors from 'store/modules/staticRatesAvailability/selectors';
import * as HotelAccommodationProductsSelectors from 'store/modules/hotelAccommodationProducts/selectors';
import * as FastSearchSelectors from 'store/modules/fastSearch/selectors';
import * as AuthSelectors from 'store/modules/auth/selectors';

import { StaticRateAvailabilityCalendar } from './StaticRateAvailabilityCalendar';
import { StaticRateCancellationPolicy } from './StaticRateCancellationPolicy';
import {
  AdultComponent as AdultIcon,
  PdfComponent as PdfIcon,
  VideoComponent as VideoIcon,
  RoomSizeComponent as RoomSizeIcon,
  MapComponent as MapIcon,
} from 'ui/Icons';
import AnimateHeight from 'react-animate-height';
import { HidingTooltip } from 'ui/Tooltip';
import InfoIcon from 'ui/Icons/components/Info.component';
import AvailabilityCalendarIcon from 'ui/Icons/components/AvailabilityCalendar.component';
import { SvgIcon } from 'ui/SvgIcon';
import { EUploadTag, ICompany, LiveRateAggregated, LiveRateListItemWithGuestAges } from 'services/BackendApi';
import { VerticalLine } from 'ui/VerticalLine';
import { theme } from '../../../tailwind.config';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';
import { EVideoPlatform, VideoModal } from './VideoModal';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { InstantBookBlock } from './InstantBookBlock';
import { companyDataSelector } from 'store/modules/companyInfo';
import { isNil } from 'lodash-es';

export interface IAccommodationCardProps {
  className?: string; // comes from styled components
  key: string;
  accommodationProduct: IHAPFormattedAccommodationProductWithLiveRatesWithBookingComRates;
  id: string;
  currencyCode: TCurrencyCode;
  title: string;
  description: string;
  moreInformation?: string;
  amenities: string[];
  size: number;
  brochures: (IHAPFloorPlan | undefined)[];
  roomInfoPdf?: IHAPFloorPlan;
  occupancy: any;
  imageUri: string | null;
  addRoom: (data: any) => void;
  staticRates: IHAPFormattedAccommodationProductStaticRate[];
  liveRates: LiveRateAggregated[];
  liveRatesCurrency: TCurrencyCode;
  basketRateType: BasketRateTypes | undefined;
  isSr: boolean;
}

interface iSummaryPricingBlockProps {
  isOnRequest: boolean;
  fromPrice: string | null;
  fromPriceBeforeDiscount: string | null;
  offersChangedPrice: boolean;
  currencyCode: TCurrencyCode;
}

const SummaryPricingBlock = (props: iSummaryPricingBlockProps) => {
  const { isOnRequest, fromPrice, fromPriceBeforeDiscount, offersChangedPrice, currencyCode } = props;
  return (
    <ul className="list-none pl-0">
      {isOnRequest && <li className="price">On Request</li>}
      {!isOnRequest && fromPrice && (
        <li
          className={classnames(`uppercase text-base`, {
            'text-red-92': offersChangedPrice,
            'text-bistre': !offersChangedPrice,
          })}
        >
          From {`${currencyCode}${formatPrice(fromPrice)}`}
        </li>
      )}

      {!isOnRequest && fromPriceBeforeDiscount && offersChangedPrice && (
        <li className="text-gold-light text-sm line-through">{`${currencyCode}${formatPrice(
          fromPriceBeforeDiscount
        )}`}</li>
      )}
    </ul>
  );
};

interface iPricingBlockProps extends iSummaryPricingBlockProps {
  appliedOfferNames: string[];
}

const PricingBlock = (props: iPricingBlockProps) => {
  const {
    isOnRequest,
    fromPrice,
    fromPriceBeforeDiscount,
    offersChangedPrice,
    appliedOfferNames,
    currencyCode,
  } = props;
  const { t } = useTranslation();

  const renderOffersTooltip = useCallback(() => {
    return (
      <div className="offers-tooltip text-13px leading-16px text-red-15 p-10px shadow-pe6 normal-case text-left w-200px">
        {appliedOfferNames.map(offerName => (
          <span key={offerName}>{offerName}</span>
        ))}
      </div>
    );
  }, [appliedOfferNames]);

  return (
    <ul className="pricing">
      {isOnRequest && (
        <li className="price">
          <>{t('labels.onRequest')}</>
        </li>
      )}
      {!isOnRequest && fromPrice && (
        <li
          className={classnames(`uppercase price mt-10px mb-6px`, {
            'text-red-92': offersChangedPrice,
            'text-bistre': !offersChangedPrice,
          })}
        >
          <>
            {t('labels.pricing.from')} {`${currencyCode}${formatPrice(fromPrice)}`}
          </>
        </li>
      )}

      {!isOnRequest && fromPriceBeforeDiscount && offersChangedPrice && (
        <li className="flex justify-end items-center">
          <HidingTooltip renderTooltipContent={renderOffersTooltip} position="bottom-right">
            <span className="flex">
              <SvgIcon
                IconComponent={InfoIcon}
                defaultFill={theme.colors['gray-40']}
                hoverFill={theme.colors['gray-80']}
                activeFill={theme.colors['gray-40']}
                width="18px"
                height="18px"
              />
            </span>
          </HidingTooltip>
          <span className="priceBeforeDiscount ml-5px">{`${currencyCode}${formatPrice(fromPriceBeforeDiscount)}`}</span>
        </li>
      )}
    </ul>
  );
};

export const AccommodationCard = (props: IAccommodationCardProps) => {
  const { dynamicParameters } = useDynamicParameters();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { accommodationProduct, staticRates, basketRateType } = props;

  const isTa = useSelector(AuthSelectors.isTA);
  const isSr = useSelector(AuthSelectors.isInternalUser);
  const taCompany = useSelector(companyDataSelector) as ICompany;

  // instant book is available if the rate itself is available to instant book
  // AND (if the user is a TA and the feature is enabled for TAs
  //      OR if the user is an SR and the feature is enabled for SRs)
  const availableToInstantBook =
    ((dynamicParameters.ENABLE_INSTANT_BOOK_TA_USERS && taCompany?.enableInstantBooking && isTa) ||
      (dynamicParameters.ENABLE_INSTANT_BOOK_INTERNAL_USERS && isSr)) &&
    staticRates[0]?.availableToInstantBook;

  const { uuid } = accommodationProduct;
  const [isStaticRateCalendarOpen, setIsStaticRateCalendarOpen] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [originalAvailabilityTagStatus, setOriginalAvailabilityTagStatus] = useState<eAvailabilityTagStatus | null>(
    null
  );
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);

  const staticRateAvailabilities = useSelector(StaticRatesAvailabilitySelectors.aggregateSelector);

  const searchQuery = useSelector(FastSearchSelectors.offersQuerySelector);
  const [staticRateCalendarDate, setStaticRateCalendarDate] = useState(
    formatDate(DateFNS.startOfMonth(new Date(searchQuery.startDate)))
  );

  const floorPlan = props.brochures?.find(b => b?.tag === EUploadTag.FLOOR_PLAN);
  const isFloorPlanAvailable = floorPlan?.url;
  const handleFloorPlanClick = useCallback(() => {
    isFloorPlanAvailable && openLink(floorPlan.url);
  }, [isFloorPlanAvailable, floorPlan]);

  const handleRoomInfoPdfClick = useCallback(() => {
    props.roomInfoPdf?.url && openLink(props.roomInfoPdf?.url);
  }, [props.roomInfoPdf]);

  const toggleAvailabilityCalendar = useCallback(() => {
    setIsStaticRateCalendarOpen(!isStaticRateCalendarOpen);
  }, [isStaticRateCalendarOpen]);

  const handleStaticRateAvailabilityCalendarNextClick = useCallback(() => {
    setStaticRateCalendarDate(formatDate(DateFNS.addMonths(new Date(staticRateCalendarDate), 1)));
  }, [staticRateCalendarDate]);

  const handleStaticRateAvailabilityCalendarPrevClick = useCallback(() => {
    setStaticRateCalendarDate(formatDate(DateFNS.addMonths(new Date(staticRateCalendarDate), -1)));
  }, [staticRateCalendarDate]);

  // when we open and close the calendar, load fresh data
  useEffect(() => {
    if (staticRates.length <= 0) {
      // dont get static rate availabilities for accommodations with 0 static rates
      return;
    }

    // if the calendar is open, hit the saga with the relevant data
    if (isStaticRateCalendarOpen) {
      const sd = formatDate(DateFNS.startOfMonth(new Date(staticRateCalendarDate)));
      const ed = formatDate(DateFNS.endOfMonth(DateFNS.addMonths(new Date(staticRateCalendarDate), 1)));
      dispatch(StaticRatesAvailabilityActions.fetchStaticRatesAvailabilityRequestAction(uuid, sd, ed));
    }
  }, [uuid, staticRates, staticRateCalendarDate, isStaticRateCalendarOpen]);

  // if we close the accordion, close the calendar
  useEffect(() => {
    if (!isAccordionOpen) {
      setIsStaticRateCalendarOpen(false);
    }
  }, [isAccordionOpen]);

  const renderOccupancyTooltip = useCallback(() => {
    return (
      <div
        className="occupancy-tooltip text-13px leading-16px text-black p-10px shadow-pe6"
        style={{ maxWidth: '1600px' }}
      >
        {props.occupancy.limits.map(limit => {
          const { name, minimum, maximum } = limit;
          const groupName = isAdult(name) ? t('adult_plural') : t(`${name}_plural`) || name;
          const limitText = `${groupName} - ${t('labels.max')} ${maximum} ${t('labels.min')} ${minimum}`;
          return (
            <span className="whitespace-nowrap" key={limit.name}>
              {limitText} <br />
            </span>
          );
        })}
      </div>
    );
  }, [props.occupancy.limits, t]);

  const availableDates =
    staticRateAvailabilities?.accommodationsAggregates.find(a => a.accommodationUuid === uuid)?.availableDates || [];

  const unavailableDates =
    staticRateAvailabilities?.accommodationsAggregates.find(a => a.accommodationUuid === uuid)?.unavailableDates || [];

  const requestDates =
    staticRateAvailabilities?.accommodationsAggregates.find(a => a.accommodationUuid === uuid)?.requestDates || [];

  const openLink = url => window.open(url, '_blank');

  const hasLiveRates = props.liveRates.length >= 1;

  const isLiveRateLoadingError = useSelector(HotelAccommodationProductsSelectors.isLiveRateLoadingErrorSelector);

  // calculate the rate to use as the "from price"
  // if we have a standard occupation static rate, use that
  // if we don't, use the cheapest live rate
  // each could also technically be null
  const { fromPrice, fromPriceBeforeDiscount, appliedOfferNames, isLiveRate: isBestRateLiveRate } = getFromPrices(
    staticRates,
    props.liveRates
  );
  const isOnRequest = !fromPrice;

  const offersChangedPrice = fromPrice !== fromPriceBeforeDiscount;

  const searchDates = generateArrayOfDatesBetween(searchQuery.startDate, searchQuery.endDate);
  const isStaticAvailable = searchDates.every(sd => availableDates.includes(sd));
  const isStaticUnavailable = searchDates.every(sd => unavailableDates.includes(sd));
  const isStaticRequest =
    searchDates.every(sd => requestDates.includes(sd)) ||
    searchDates.some(
      sd => !availableDates.includes(sd) && !unavailableDates.includes(sd) && !requestDates.includes(sd)
    );
  const isStaticPartiallyAvailable = !isStaticAvailable && searchDates.some(sd => availableDates.includes(sd));

  const openVideoModal = useCallback(() => {
    setVideoModalOpen(true);
  }, []);

  const closeVideoModal = useCallback(() => {
    setVideoModalOpen(false);
  }, []);

  const staticAvailabilityStatus = useMemo(() => {
    let status = eAvailabilityTagStatus.PLAIN;
    if (availableToInstantBook) {
      status = eAvailabilityTagStatus.INSTANT_BOOK;
    } else if (isStaticAvailable) {
      status = eAvailabilityTagStatus.AVAILABLE;
    } else if (isStaticRequest) {
      status = eAvailabilityTagStatus.REQUEST;
    } else if (isStaticUnavailable) {
      status = eAvailabilityTagStatus.UNAVAILABLE;
    } else if (isStaticPartiallyAvailable) {
      status = eAvailabilityTagStatus.PLAIN;
    }
    return status;
  }, []);

  const staticAvailabilityTextMap = {
    [eAvailabilityTagStatus.AVAILABLE]: 'AVAILABLE',
    [eAvailabilityTagStatus.REQUEST]: 'ON REQUEST',
    [eAvailabilityTagStatus.UNAVAILABLE]: 'UNAVAILABLE',
    [eAvailabilityTagStatus.PLAIN]: 'CHECK CALENDAR',
    [eAvailabilityTagStatus.INSTANT_BOOK]: 'AVAILABLE - INSTANT BOOK',
  };
  const staticAvailabilityColorMap = {
    [eAvailabilityTagStatus.AVAILABLE]: theme.colors['teal-100'],
    [eAvailabilityTagStatus.REQUEST]: theme.colors['yellow-advert'],
    [eAvailabilityTagStatus.UNAVAILABLE]: theme.colors['red-50'],
    [eAvailabilityTagStatus.PLAIN]: theme.colors['brown-80'],
  };

  // if we have no static rates OR live rates, return null
  if (staticRates.length <= 0 && isNilOrEmpty(props.liveRates)) {
    return null;
  }

  // @see 4081 for breakdown of requirements
  if (originalAvailabilityTagStatus === null) {
    if (isBestRateLiveRate) {
      setOriginalAvailabilityTagStatus(eAvailabilityTagStatus.AVAILABLE);
    } else {
      setOriginalAvailabilityTagStatus(staticAvailabilityStatus);
    }
  }

  let showBookingComRates = false;

  if (fromPrice && accommodationProduct.bookingComRate) {
    const fromPriceCentsAsNumber = parseFloat(fromPrice) * 100;
    showBookingComRates =
      (isSr && !isNil(accommodationProduct.bookingComRate)) ||
      (isTa && accommodationProduct.bookingComRate.finalPriceCents > fromPriceCentsAsNumber);
  }

  const liveRateRates: LiveRateListItemWithGuestAges[] = [];

  props.liveRates.forEach(liveRateAgg => {
    const listItemsWithGuestAges = liveRateAgg.list.map(l => {
      return {
        ...l,
        guestAges: liveRateAgg.guestAges,
      };
    });

    listItemsWithGuestAges.forEach(l => {
      liveRateRates.push(l);
    });
  });

  return (
    <div className={props.className} data-role="accomodation-cards">
      <div className="title-line">
        <h1
          style={{
            width: '290px',
          }}
          className="text-brown-prime text-xl font-noe-display font-normal leading-lg tracking-1px"
          data-role="ac-title"
        >
          {props.title}
        </h1>

        {!isAccordionOpen && (
          <div
            style={{
              width: '184px',
            }}
            className="text-right"
          >
            {originalAvailabilityTagStatus !== null && (
              <AvailabilityTag availabilityStatus={originalAvailabilityTagStatus} className="acc-live-rates-tag">
                <span>{staticAvailabilityTextMap[originalAvailabilityTagStatus]}</span>
              </AvailabilityTag>
            )}
            <SummaryPricingBlock
              isOnRequest={isOnRequest}
              fromPrice={fromPrice}
              fromPriceBeforeDiscount={fromPriceBeforeDiscount}
              offersChangedPrice={offersChangedPrice}
              currencyCode={props.currencyCode}
            />
          </div>
        )}
        <button
          className="accordion-button border-none hover:bg-gray-20 cursor-pointer bg-gray-light w-24px h-24px"
          onClick={() => {
            // OWA-5182
            if (dynamicParameters.WOOPRA_DOMAIN) {
              // @ts-ignore
              window.woopra &&
                // @ts-ignore
                window.woopra.track('display-click', {
                  type: 'accommodation',
                  lodgingUuid: props.id,
                });
            }
            setIsAccordionOpen(!isAccordionOpen);
          }}
        >
          {(isAccordionOpen && <i className="fas fa-chevron-up" />) || <i className="fas fa-chevron-down" />}
        </button>
      </div>

      <AnimateHeight duration={300} height={isAccordionOpen ? 'auto' : 0} className="accordion-area">
        <hr />
        <div className="innerWrapper">
          <div className="accommodationIcons">
            <div className="accommodationIconsRow">
              <RoomSizeIcon icon={theme.colors['brown-100']} />
              <span className="text-sm leading-17px text-gold-light">{props.size} sq. m.</span>
              <VerticalLine height="15px" color={theme.colors['gray-18']} />
              <HidingTooltip renderTooltipContent={renderOccupancyTooltip} position="bottom-right">
                <span className="flex">
                  <AdultIcon icon={theme.colors['brown-100']} />
                </span>
              </HidingTooltip>
              <span className="text-sm leading-17px text-gold-light">
                x{props.occupancy.standardOccupancy} (max. {props.occupancy.maximumPeople})
              </span>
            </div>
            <div className="accommodationIconsRow">
              {isFloorPlanAvailable ? (
                <HidingTextTooltip
                  tooltipContent="Floor plan"
                  position="bottom-right"
                  tooltipContentClassname="map-tooltip"
                >
                  <span
                    className="flex justify-center items-center cursor-pointer w-6 h-6 hover:bg-gray-20"
                    onClick={handleFloorPlanClick}
                  >
                    <SvgIcon
                      IconComponent={MapIcon}
                      defaultFill={theme.colors['brown-100']}
                      hoverFill={theme.colors['brown-hard']}
                      defaultBackground={theme.colors['transparent']}
                      hoverBackground={theme.colors['gray-20']}
                      width="16px"
                      height="16px"
                    />
                  </span>
                </HidingTextTooltip>
              ) : (
                <MapIcon fill={theme.colors['gray-40']} />
              )}
              <VerticalLine height="15px" color={theme.colors['gray-18']} />
              {props.roomInfoPdf ? (
                <HidingTextTooltip tooltipContent="Pdf" position="bottom-right" tooltipContentClassname="pdf-tooltip">
                  <span
                    className="flex justify-center items-center cursor-pointer w-6 h-6 hover:bg-gray-20"
                    onClick={handleRoomInfoPdfClick}
                  >
                    <SvgIcon
                      IconComponent={PdfIcon}
                      defaultFill={theme.colors['brown-100']}
                      hoverFill={theme.colors['brown-hard']}
                      defaultBackground={theme.colors['transparent']}
                      hoverBackground={theme.colors['gray-20']}
                      width="14px"
                      height="16px"
                    />
                  </span>
                </HidingTextTooltip>
              ) : (
                <PdfIcon fill={theme.colors['gray-40']} />
              )}
              {accommodationProduct.vimeoVideoId && (
                <>
                  <VerticalLine height="15px" color={theme.colors['gray-18']} />
                  <HidingTextTooltip
                    tooltipContent="Video"
                    position="bottom-right"
                    tooltipContentClassname="video-tooltip"
                  >
                    <span
                      className="flex justify-center items-center cursor-pointer w-6 h-6 hover:bg-gray-20"
                      onClick={openVideoModal}
                    >
                      <SvgIcon
                        IconComponent={VideoIcon}
                        defaultFill={theme.colors['brown-100']}
                        hoverFill={theme.colors['brown-hard']}
                        defaultBackground={theme.colors['transparent']}
                        hoverBackground={theme.colors['gray-20']}
                        width="18px"
                        height="18px"
                      />
                    </span>
                  </HidingTextTooltip>
                </>
              )}
            </div>
          </div>

          <PricingBlock
            appliedOfferNames={appliedOfferNames}
            currencyCode={props.currencyCode}
            fromPrice={fromPrice}
            fromPriceBeforeDiscount={fromPriceBeforeDiscount}
            isOnRequest={isOnRequest}
            offersChangedPrice={offersChangedPrice}
          />
        </div>
        {staticRates.length >= 1 && (
          <React.Fragment>
            <span
              style={{ margin: '12px 0', padding: '0 27px' }}
              className="block text-flint font-bold uppercase font-hurmegeometric-sans"
            >
              Static rates
            </span>
            <span className="flex items-center" style={{ padding: '0 27px' }}>
              <AvailabilityTag availabilityStatus={staticAvailabilityStatus} className="acc-live-rates-tag">
                <span>{staticAvailabilityTextMap[staticAvailabilityStatus]}</span>
              </AvailabilityTag>
              <div className="static-rate-calendar-container">
                <HidingTextTooltip
                  tooltipContent="Availability"
                  position="bottom-right"
                  tooltipContentClassname="availability-tooltip"
                >
                  <div
                    className="flex items-center ml-5px bg-gray-light hover:bg-gray-20 cursor-pointer"
                    onClick={toggleAvailabilityCalendar}
                  >
                    <AvailabilityCalendarIcon
                      fill={theme.colors['brown-100']}
                      stroke={staticAvailabilityColorMap[staticAvailabilityStatus]}
                    />
                    <button className="availability-calendar-arrow border-none w-24px h-24px cursor-pointer bg-transparent">
                      {(isStaticRateCalendarOpen && <i className="fas fa-chevron-up" />) || (
                        <i className="fas fa-chevron-down" />
                      )}
                    </button>
                  </div>
                </HidingTextTooltip>
                {isStaticRateCalendarOpen && (
                  <StaticRateAvailabilityCalendar
                    error={undefined}
                    date={staticRateCalendarDate}
                    onNextClick={handleStaticRateAvailabilityCalendarNextClick}
                    onPrevClick={handleStaticRateAvailabilityCalendarPrevClick}
                    availableDates={availableDates}
                    unavailableDates={unavailableDates}
                    requestDates={requestDates}
                  />
                )}
              </div>
              {/* if instant book, show a hiding tooltip with some helper text in it */}
              {availableToInstantBook && (
                <HidingTooltip
                  renderTooltipContent={() => (
                    <p className="acc-live-rate-tooltip font-hurmegeometric-sans text-13px leading-16px w-150px m-0 p-10px text-black">
                      This is a static rate and is sold on Free Sale. Please note availability is subject to change
                      without notice.
                    </p>
                  )}
                  position="bottom-right"
                >
                  <span className="ml-10px">
                    <InfoIcon className="w-[18px]" fill={theme.colors['brown-100']} />
                  </span>
                </HidingTooltip>
              )}
            </span>
            <div style={{ padding: '0 27px', marginBottom: '20px' }} className="space-y-8px">
              {basketRateType !== undefined && basketRateType !== BasketRateTypes.STATIC && (
                <div className="mt-10px">
                  <RateMixingWarning isSr={props.isSr} />
                </div>
              )}
              {staticRates &&
                staticRates.map((staticRateSet, index) => {
                  const handleOnClick = () => {
                    if (basketRateType !== undefined && basketRateType !== BasketRateTypes.STATIC) {
                      return;
                    }
                    props.addRoom({
                      ...props.accommodationProduct,
                      guestAges: staticRateSet.guestAges,
                      defaultMealPlan: staticRateSet.defaultMealPlan,
                      availableToInstantBook: staticRateSet.availableToInstantBook,
                    });
                    // OWA-5182
                    if (dynamicParameters.WOOPRA_DOMAIN) {
                      // @ts-ignore
                      window.woopra &&
                        // @ts-ignore
                        window.woopra.track('cart-add', {
                          type: 'accommodation',
                          lodgingUuid: props.id,
                        });
                    }
                  };

                  const staticRateChildCount = staticRateSet.guestAges.agesOfAllChildren.length;

                  return (
                    <div key={`${staticRateSet.uuid}-${index}`}>
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-row flex-start items-center">
                          <span className="uppercase font-semibold text-14px text-brown-prime mr-5px">
                            {staticRateSet.totalGuests} Guest
                          </span>
                          {staticRateSet.isStandardOccupancy && (
                            <span className="uppercase text-brown-prime mr-5px">(Standard Occupancy)</span>
                          )}
                          <span className="text-brown-prime">{staticRateSet.guestAges.numberOfAdults} Adults</span>
                          {staticRateChildCount > 0 && (
                            <span style={{ marginLeft: '4px' }} className="text-brown-prime">
                              / {staticRateChildCount} {staticRateChildCount > 1 ? 'Children' : 'Child'}
                            </span>
                          )}
                        </div>
                        <div className="flex flex-row flex-end items-center">
                          {/* if static rate is on request, render that */}
                          {staticRateSet.totals.oneOrMoreItemsOnRequest && (
                            <span className="block mr-10px text-17px text-bistre uppercase">On Request</span>
                          )}

                          {/* if this static rate is NOT on request, render the price */}
                          {!staticRateSet.totals.oneOrMoreItemsOnRequest && (
                            <span className="block mr-10px text-17px text-bistre">{`${
                              props.currencyCode
                            }${formatPriceCents(staticRateSet.totals.totalForPricedItemsCents)}`}</span>
                          )}

                          <button
                            disabled={staticRateSet.isDisabled || props.basketRateType === BasketRateTypes.LIVE}
                            key={index}
                            style={{
                              width: '81px',
                            }}
                            className={classnames(
                              `add-lodging-static-${index} font-hurmegeometric-sans font-semibold border-none outline-none bg-brown-prime block h-35px text-white text-14px leading-17px uppercase`,
                              {
                                // not disabled
                                'hover:bg-marine cursor-pointer': !(
                                  staticRateSet.isDisabled || props.basketRateType === BasketRateTypes.LIVE
                                ),
                                // disabled
                                'opacity-50': staticRateSet.isDisabled || props.basketRateType === BasketRateTypes.LIVE,
                              }
                            )}
                            onClick={handleOnClick}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                      <StaticRateCancellationPolicy staticRate={staticRateSet} currencyCode={props.currencyCode} />
                    </div>
                  );
                })}
            </div>
          </React.Fragment>
        )}

        {/* these are the instant book (live rates) */}
        {liveRateRates.length >= 1 && (
          <InstantBookBlock
            accommodationProduct={props.accommodationProduct}
            rates={liveRateRates}
            currency={props.liveRatesCurrency}
            addRoom={props.addRoom}
            basketRateType={props.basketRateType}
            blockLabel="Live Rates"
            blockInfoTooltipContent={
              <p className="acc-live-rate-tooltip font-hurmegeometric-sans text-13px leading-16px max-w-150px m-0 p-10px text-black">
                <span className="acc-live-rate-tooltip-highlight text-brown-100">Available Now</span> but please note
                both the rate and the availability will need to be confirmed offline.
              </p>
            }
            forBasketType={BasketRateTypes.LIVE}
          />
        )}

        {isLiveRateLoadingError && (
          <div className="mx-15px p-15px bg-gray-7 mb-20px">
            <span className="font-hurmegeometric-sans text-flint uppercase font-bold block">Live Rates</span>
            <span className="block text-red-15">We were unable to check the live rates with the hotel</span>
          </div>
        )}

        {showBookingComRates && accommodationProduct.bookingComRate && (
          <div className="booking-com-rates bg-blue-10 p-15px ml-15px mr-15px mb-20px">
            <div className="flex flex-row items-center justify-between">
              <span className="text-flint text-xs">
                <span className="text-bold text-booking-com-navy-blue font-semibold">Booking</span>
                <span className="text-bold text-booking-com-sky-blue font-semibold">.com</span>'s current rate
              </span>

              <span className="flex flex-col font-hurmegeometric-sans text-sm text-flint text-right">
                <span className="booking-com-rates-price uppercase block leading-16px">
                  From {`${props.currencyCode}${formatPriceCents(accommodationProduct.bookingComRate.finalPriceCents)}`}
                </span>

                <span className="text-xs block leading-14px booking-com-rates-meal-plan">
                  * {accommodationProduct.bookingComRate.mealPlanCode} Meal Plan
                </span>

                <span className="text-xs block leading-14px booking-com-rates-cancellation-policy">
                  {accommodationProduct.bookingComRate.cancellationPolicy}
                </span>
              </span>
            </div>
          </div>
        )}
      </AnimateHeight>

      {isVideoModalOpen && accommodationProduct.vimeoVideoId && (
        <VideoModal
          videoId={accommodationProduct.vimeoVideoId}
          title={accommodationProduct.title}
          type={EVideoPlatform.VIMEO}
          onClose={closeVideoModal}
        />
      )}
    </div>
  );
};

export default AccommodationCard;
