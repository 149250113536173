import React, { useCallback, useState } from 'react';
import classnames from 'classnames';

interface ISimpleTabsOption {
  title: string;
  name: string;
  styles: string;
  styleObject?: React.CSSProperties; // for extremely specific widths
  renderContent: () => React.ReactElement;
}

interface ISimpleTabsProps {
  tabConfig: ISimpleTabsOption[];
}

export const SimpleTabs: React.FC<ISimpleTabsProps> = ({ tabConfig }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const makeHandleTabChange = useCallback((tabIndex: number) => () => setSelectedTabIndex(tabIndex), []);

  return (
    <div className="tabs w-1280px mx-auto pt-25px pb-35px">
      <div className="tab-selector flex mt-5">
        {tabConfig.map((tab, index) => (
          <span
            key={tab.name}
            style={tab.styleObject ? tab.styleObject : {}}
            onClick={makeHandleTabChange(index)}
            className={classnames(
              `${tab.name} font-pt-sans text-base text-black-true text-center border border-solid border-gray-40 border-r-0 last-of-type:border-r rounded rounded-b-none p-4 not-active cursor-pointer ${tab.styles}`,
              {
                'bg-ivory shadow-[0px_-5px_5px_0px_#00000008_inset]': index !== selectedTabIndex,
                'border-b-0 font-bold text-teal-100 active': index === selectedTabIndex,
              }
            )}
          >
            {tab.title}
          </span>
        ))}
        <div className="w-full border-t-0 border-r-0 border-l-0 border-b border-solid border-gray-40" />
      </div>
      {tabConfig.map((tab, index) => {
        if (index === selectedTabIndex) {
          return (
            <div
              key={tab.name}
              className={`${tab.name} flex border border-solid border-gray-40 border-t-0 pt-15px pb-30px px-5`}
            >
              {tab.renderContent()}
            </div>
          );
        }
      })}
    </div>
  );
};
