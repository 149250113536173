import { TINYMCE_EDITOR_LICENSE_KEY } from "config";
import EditorMentions, { ISingleSelectOption } from "./EditorMentions";
import { InitOptions } from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";
import { Editor as IEditor } from 'tinymce';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHideOnClickOutside } from "hooks/useHideOnClickOutside";
import { Editor as TinyMceEDitor } from '@tinymce/tinymce-react';
import { theme } from "../../../tailwind.config";

const optionToHtml = (id: string, name: string, email: string) => `<span id="mention" style="width: 100%; height: 100%; padding: 3px; background: ${theme.colors['blue-10']}; border-radius: 4px;" class="non-editable" data-user-id="${id}">
          <span id="mention-name" style="color: ${theme.colors['bright-sky-blue']}; font-size: 15px; font-family: 'PT Sans', sans-serif; font-weight: 400; text-transform: capitalize; word-wrap: break-word">@${name} </span>
          <span style="color: ${theme.colors['bright-sky-blue']}; font-size: 15px; font-family: 'PT Sans', sans-serif; font-style: italic; font-weight: 400; text-transform: capitalize; word-wrap: break-word">
          (<span id="mention-email" style="color: ${theme.colors['bright-sky-blue']}; font-size: 15px; font-family: 'PT Sans', sans-serif; font-style: italic; font-weight: 400; text-transform: lowercase; word-wrap: break-word">${email}</span>)</span>
</span>${' '}`;


export interface IMentionOptions {
  name: string;
  email: string;
  role: string;
  id: string;
};

export interface IEditorPops {
  handleEditorChange: (content: any, editor: any) => void;
  comment: string;
  options: IMentionOptions[];
  initVals?: InitOptions;
}

const Editor = ({ handleEditorChange, comment, options = [], initVals }: IEditorPops) => {
  const editorRef = useRef<IEditor | null>(null);
  const [nameFilter, setNameFilter] = useState('');
  const [mentionPosition, setMentionPosition] = useState<{ x: number, y: number } | null>(null);
  const [isMentionActive, setIsMentionActive] = useState<boolean>(false);
  const { instanceClassname } = useHideOnClickOutside(setIsMentionActive, 'mentions-list');

  useEffect(() => {
    const str = editorRef.current?.selection.getRng().endContainer.textContent?.trim() || '';
    if (str?.[0] === '@') {
      setNameFilter(str.substring(1));
    }
  }, [isMentionActive, comment]);

  const handleInitEditor = useCallback((_evt, editor) => editorRef.current = editor, []);

  const handleMentionPosition = useCallback((editor: IEditor) => {
    setNameFilter('');
    editor.formatter.apply('forecolor', { value: theme.colors['bright-sky-blue'] });
    editor.selection.collapse(false);

    const selection = editor.selection;
    const range = selection.getRng();

    if (!range.collapsed) {
      return null;
    }

    const span = document.createElement('span');
    span.appendChild(document.createTextNode('\u200B'));  // Zero-width space character
    range.insertNode(span);

    const rect = span.getBoundingClientRect();
    const containerRect = editor.container.getBoundingClientRect();

    span.parentNode?.removeChild(span);

    setMentionPosition({
      x: containerRect.left + window.scrollX + rect.left - 2,
      y: containerRect.top + window.scrollY + rect.top + 68
    });
  }, [])

  const handleSelectMention = useCallback((option: ISingleSelectOption) => {
    const selection = editorRef.current?.selection;
    const range = selection?.getRng();
    
    range?.selectNode(range?.commonAncestorContainer)
    range?.deleteContents();
    editorRef.current?.formatter.remove('forecolor', { value: theme.colors['bright-sky-blue'] });
    selection?.setContent(optionToHtml(option.id ,option.name, option.email), { format: 'html' });
    setNameFilter('');
    setIsMentionActive(false);
    editorRef.current?.focus();
  }, []);

  const handleOnKeyDown = useCallback((e) => {
    if (e.key === '@' && editorRef.current) {
      setIsMentionActive(true);
      handleMentionPosition(editorRef.current);
    } else if (e.key === ' ' && isMentionActive) {
      setIsMentionActive(false);
      setNameFilter('');
      editorRef.current?.formatter.remove('forecolor', undefined, editorRef.current?.selection.getRng());
      editorRef.current?.selection.collapse(false);
    } else if (e.key === 'Backspace' && nameFilter === '' && isMentionActive) {
      setIsMentionActive(false);
      editorRef.current?.formatter.remove('forecolor', undefined, editorRef.current?.selection.getRng());
    }
  }, [nameFilter, isMentionActive]);

  const editorInitVals = useMemo(() => ({
    height: 200,
    menubar: false,
    statusbar: false,
    toolbar: "blocks | bold italic | alignleft aligncenter alignright alignjustify | outdent indent",
    content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: ${theme.colors['black']} } .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {font-style: italic; color: ${theme.colors['gray-100']}}`,
    noneditable_class: 'non-editable',
    placeholder: 'Type @ to mention and notify someone.',
    ...(initVals || {}),
  }), []);

  const editorStyles = useMemo<React.CSSProperties>(() => ({
    position: 'absolute',
    left: `${mentionPosition?.x}px`,
    top: `${mentionPosition?.y}px`,
    zIndex: '2000',
  }), [mentionPosition]);

  return (
    <>
      {isMentionActive && (
        <EditorMentions
          options={options}
          style={editorStyles}
          nameFilter={nameFilter}
          onChange={handleSelectMention}
          className={instanceClassname}
        />
      )}
      <TinyMceEDitor
        tinymceScriptSrc='/tinymce/tinymce.min.js'
        licenseKey={TINYMCE_EDITOR_LICENSE_KEY}
        onInit={handleInitEditor}
        onEditorChange={handleEditorChange}
        value={comment}
        init={editorInitVals}
        onKeyDown={handleOnKeyDown}
      />
    </>
  );
}

export default Editor;