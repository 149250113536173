import React from 'react';
import classnames from 'classnames';

export enum eAvailabilityTagStatus {
  AVAILABLE,
  UNAVAILABLE,
  REQUEST,
  PLAIN,
  INSTANT_BOOK,
}

export interface iAvailabilityTagProps {
  className: string;
  availabilityStatus: eAvailabilityTagStatus;
  children: any;
}
export const AvailabilityTag = (props: iAvailabilityTagProps) => {
  const { className, availabilityStatus, children } = props;
  return (
    <span
      style={{
        padding: '2px 5px',
      }}
      className={classnames(
        `${className} text-black font-hurmegeometric-sans text-xs leading-14px tracking-2xs rounded-1px not-italic font-normal`,
        {
          'bg-teal-30':
            availabilityStatus === eAvailabilityTagStatus.AVAILABLE ||
            availabilityStatus === eAvailabilityTagStatus.INSTANT_BOOK,
          'bg-red-30': availabilityStatus === eAvailabilityTagStatus.UNAVAILABLE,
          'bg-yellow-40': availabilityStatus === eAvailabilityTagStatus.REQUEST,
          'bg-white': availabilityStatus === eAvailabilityTagStatus.PLAIN,
        }
      )}
    >
      {children}
    </span>
  );
};
