import { ITopNavigationData } from "services/BookingManagerApi";

export const calculateGuestAgesFromTopNavigationData = (topNavigationData: ITopNavigationData | null) => {
  if (topNavigationData === null) {
    return {
      numberOfAdults: 0,
      agesOfAllChildren: [],
    }
  }

  const guestCount = topNavigationData?.guestCount ?? 0;
  const childrenCount = topNavigationData?.childrenAges?.length ?? 0;
  const numberOfAdults = Math.abs(guestCount - childrenCount);
  const agesOfAllChildren = topNavigationData?.childrenAges ?? [];
  
  return {
    numberOfAdults,
    agesOfAllChildren,
  };
}