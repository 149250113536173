import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TaskCommentView } from './TaskCommentView';
import { TaskCommentEdit } from './TaskCommentEdit';
import { TaskCommentCreate } from './TaskCommentCreate';
import { focusedCommentsSelector, getdeleteCommentLoadingSelector, listTaskCommentsSelector, selectedCommentIdSelector } from 'store/modules/taskManagement/selectors';
import { addFocusedCommentAction, deleteCommentRequestAction, listTaskCommentsRequestAction, removeFocusedCommentAction, setSelectedCommentIdAction } from 'store/modules/taskManagement/actions';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import { ENetworkRequestStatus } from 'services/BackendApi';

interface ITaskCommentsProps {
  taskId: string;
}

export const TaskComments: React.FC<ITaskCommentsProps> = React.memo(({ taskId }) => {
  const comments = useSelector(listTaskCommentsSelector);
  const dispatch = useDispatch();
  const focusedComments = useSelector(focusedCommentsSelector);
  const deleteCommentLoading = useSelector(getdeleteCommentLoadingSelector);
  const selectedCommentId = useSelector(selectedCommentIdSelector);
  const isAddCommentFocused = focusedComments.includes(null);

  useEffect(() => {
    dispatch(listTaskCommentsRequestAction(taskId));
  }, [dispatch, taskId]);

  const handleOnBlur = useCallback(() => {
    dispatch(removeFocusedCommentAction(null));
  }, [dispatch]);
  
  const handleOnFocus = useCallback(() => {
    dispatch(addFocusedCommentAction(null));
  }, [dispatch]);

  const handleCommentToDelete = useCallback((commentId: string) => {
    dispatch(setSelectedCommentIdAction(commentId));
  }, [selectedCommentId]);

  const handleConfirmConfirmationModal = useCallback(() => {
    if (selectedCommentId) {
      dispatch(deleteCommentRequestAction(selectedCommentId, taskId));
    }
  }, [dispatch, selectedCommentId]);

  const handleCancelConfirmationModal = useCallback(() => {
    dispatch(setSelectedCommentIdAction(null));
  }, [dispatch, selectedCommentId]);

  return (
    <div className="comments flex flex-col items-start gap-[20px]">
      {selectedCommentId && (
        <ConfirmationModal
          type={EConfirmationModalType.WARNING}
          title='You are going to delete a comment.'
          message='Are you sure you want to delete this comment?'
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
          onConfirm={handleConfirmConfirmationModal}
          onCancel={handleCancelConfirmationModal}
          isConfirmLoading={deleteCommentLoading === ENetworkRequestStatus.PENDING}
        />
      )}
      <p className="font-pt-sans text-black m-0 text-[16px] font-bold">Comments</p>
      {!isAddCommentFocused && <TaskCommentCreate onFocus={handleOnFocus} />}
      {/* setting value '' while edit functionality is not implemented */}
      {isAddCommentFocused && <TaskCommentEdit value={''} onCancel={handleOnBlur} taskId={taskId} commentId={null} />}
      {comments.map(comment => <TaskCommentView key={comment.id} comment={comment} handleCommentToDelete={handleCommentToDelete} />)}
    </div>
  )
});
