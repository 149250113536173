import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, getCurrentUserType } from 'store/modules/auth';
import { EUserType, IUser } from 'services/BackendApi';
import styled from 'styled-components';
import { Link } from 'ui/Link';
import { getAvailableWorkspacesLinks } from '../../ui/InvHeader/inventoryMap';
import { IInventoryOptionBespoke } from '../../interfaces';
import * as InvHeaderActions from 'store/modules/inventoryHeader/actions';

//@ts-ignore TS doesn't like raw image imports, whatever
import heroImage from 'config/pages/search/assets/workspace_ui_homepage_jan_2023.jpg';



export const InventoryWorkspacesHomepage = () => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser) as IUser;
  const userType = useSelector(getCurrentUserType) as EUserType;

  const handleInventoryOptionSelected = useCallback((option: IInventoryOptionBespoke) => {
    dispatch(InvHeaderActions.setInventoryOptionSelectedAction(option.path, option.urlRedirect && option.urlRedirect() || null));
  }, []);

  const availableWorkspacesLinks = getAvailableWorkspacesLinks(user);

  const InventoryWorkspacesHomepageContainer = styled.div`
    .iwhc__links_wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }
    .iwhc__image_wrapper {
      height: 450px;
      background-size: cover;
      background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(${heroImage});
      background-position: center;
      font-family: Arial, Helvetica, sans-serif;
    }
    .iwhc__workspace-link {
      height: 129px;
      flex: 1 1 0px;
      font-family: Arial, Helvetica, sans-serif;
      &:hover {
        box-shadow: 0px 0px 1px 0px #0f172a0f, 0px 4px 6px -1px #0f172a1a, 0px 0px 1px 0px #0f172a0f;
      }
    }
    .iwhc__workspace-link-icon {
      width: 36px;
      height: 36px;
    }
  `;

  const availableWorkspacesLinkChunks: IInventoryOptionBespoke[][] = [];

  const chunkSize = availableWorkspacesLinks.length >= 6 ? 4 : 5;
  for (let i = 0; i < availableWorkspacesLinks.length; i += chunkSize) {
    availableWorkspacesLinkChunks.push(availableWorkspacesLinks.slice(i, i + chunkSize));
  }

  return (
    <InventoryWorkspacesHomepageContainer>
      <div className="iwhc__image_wrapper flex flex-col justify-center items-center">
        <span className="block text-white text-48px text-center">Welcome</span>
        <span className="block text-white text-64px text-center">Select your Workspace</span>
      </div>

      <div className="iwhc__links_wrapper space-y-40px py-15 bg-ivory">
        {availableWorkspacesLinkChunks.map((chunk, index) => {
          return (
            <div key={`chunk-${index}`} className="flex flex-row justify-evenly space-x-20px w-full ">
              {chunk.filter(workspace => !workspace.isHidden || (workspace.isHidden && !workspace.isHidden(userType))).map(workspace => {
                return (
                  <Link
                    key={workspace.label}
                    type={workspace.isExternal ? 'external' : 'internal'}
                    to={workspace.urlRedirect && workspace.urlRedirect() || '/'} // Presumibly we won't have any dead link in the top of the hierarchy of menus.
                    className={`${workspace.value?.toLowerCase()} iwhc__workspace-link flex cursor-pointer flex-col space-y-10px justify-center bg-white border border-solid border-gray-20`}
                  >
                    <div
                      onClick={e => {
                        handleInventoryOptionSelected(workspace);
                      }}
                    >
                      <span className="iwhc__workspace-link-icon block mx-auto">{workspace.iconHtmlHomepage}</span>
                      <span className="block text-black text-xl text-center">{workspace.label}</span>
                    </div>
                  </Link>
                );
              })}
            </div>
          );
        })}
      </div>
    </InventoryWorkspacesHomepageContainer>
  );
};
