import { ENetworkRequestStatus, IUser, Upload } from 'services/BackendApi';
import { ETasksSortableKey, ITask, ITaskAttachment, ITaskCategory, ITaskComment, ITaskReminder, ITaskTeam, ITaskUser } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { ESortOrder } from 'store/common/types';
import { INewUploadPayload } from '../bookingManager/subdomains/uploads/model';

export interface INewUpload {
  payload: INewUploadPayload | null;
  request: ENetworkRequestStatus;
  error: Error | null;
}

export interface ITaskAttachments {
  newAttachment: INewUpload;
  attachments: ITaskAttachment[];
  attachmentsLoad: ENetworkRequestStatus;
  attachmentsLoadError?: Error;
  uploadAttachmentModalOpen: boolean;
  uploadAttachmentError?: Error;
}

export interface ITaskManagementDomain {
  filters: {
    id: string;
    categories: string[];
    statuses: string[];
    team: string | null;
    assignee: string | null;
    priority: string | null;
    dates: string[];
    bookingRef: string;
    guestName: string;
    reporter: string;
  },
  requests: {
    getTasks: ENetworkRequestStatus;
    getTask: ENetworkRequestStatus;
    getCategories: ENetworkRequestStatus;
    getTeams: ENetworkRequestStatus;
    getReporters: ENetworkRequestStatus;
    getFilterInfo: ENetworkRequestStatus;
    getAssignees: ENetworkRequestStatus;
    createTask: ENetworkRequestStatus;
    deleteTask: ENetworkRequestStatus;
    editTask: ENetworkRequestStatus;
    createTaskComment: ENetworkRequestStatus;
    listComments: ENetworkRequestStatus;
    deleteComment: ENetworkRequestStatus;
    getMentionList: ENetworkRequestStatus;
    listAttachments: ENetworkRequestStatus;
    deleteAttachment: ENetworkRequestStatus;
  },
  data: {
    categories: ITaskCategory[];
    teams: ITaskTeam[];
    assignees: ITaskUser[];
    reporters: ITaskUser[];
    userCache: ITaskUser[];
    results: ITask[];
    tableLoading: boolean;
    comments: ITaskComment[];
    selectedTask: ITask | null;
    selectedCommentId: string | null;
    focusedComments: (string | null)[];
    reminders: ITaskReminder[];
    attachments: ITaskAttachments;
    mentionList: Pick<IUser, 'uuid' | 'firstName' | 'lastName' | 'email' | 'type'>[],
    selectedAttachmentId: string | null;
    duplicatedReminderIds: string[];
  },
  pagination: {
    currentPage: number;
    itemsPerPage: number; 
    totalItems: number | null; 
  },
  sort: {
    sortBy: ETasksSortableKey;
    sortOrder: ESortOrder; 
  },
}

export const initialState: ITaskManagementDomain = {
  filters: {
    id: '',
    categories: [],
    statuses: [],
    team: null,
    assignee: null,
    priority: null,
    dates: [],
    bookingRef: '',
    guestName: '',
    reporter: '',
  },
  requests: {
    getTasks: ENetworkRequestStatus.IDLE,
    getTask: ENetworkRequestStatus.IDLE,
    getCategories: ENetworkRequestStatus.IDLE,
    getTeams: ENetworkRequestStatus.IDLE,
    getReporters: ENetworkRequestStatus.IDLE,
    getAssignees: ENetworkRequestStatus.IDLE,
    getFilterInfo: ENetworkRequestStatus.IDLE,
    createTask: ENetworkRequestStatus.IDLE,
    deleteTask: ENetworkRequestStatus.IDLE,
    editTask: ENetworkRequestStatus.IDLE,
    createTaskComment: ENetworkRequestStatus.IDLE,
    listComments: ENetworkRequestStatus.IDLE,
    deleteComment: ENetworkRequestStatus.IDLE,
    getMentionList: ENetworkRequestStatus.IDLE,
    listAttachments: ENetworkRequestStatus.IDLE,
    deleteAttachment: ENetworkRequestStatus.IDLE,
  },
  data: {
    categories: [],
    assignees: [],
    reporters: [],
    userCache: [],
    teams: [],
    results: [],
    tableLoading: false,
    selectedTask: null,
    comments: [],
    focusedComments: [],
    selectedCommentId: null,
    reminders: [],
    attachments: {
      attachments: [],
      attachmentsLoad: ENetworkRequestStatus.IDLE,
      uploadAttachmentModalOpen: false,
      newAttachment: {
        payload: null,
        request: ENetworkRequestStatus.IDLE,
        error: null,
      },
    },
    mentionList: [],
    selectedAttachmentId: null,
    duplicatedReminderIds: [],
  },
  pagination: {
    currentPage: 0,
    itemsPerPage: 10,
    totalItems: null,
  },
  sort: {
    sortBy: ETasksSortableKey.DEADLINE,
    sortOrder: ESortOrder.ASC,
  }
};
