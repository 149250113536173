import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BasketRightTotalCost } from './BookingRightTotalCost';
import { BasketLeadGuestInfo } from './BasketLeadGuestInfo';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { BookingMargin } from 'containers/SummaryFormExtras/BookingMargin';
import { useTranslation } from 'react-i18next';
import { BasketBookingButtons } from './BasketBookingButtons';
import { TermsAndConditions } from 'containers/HotelContainer/TermsAndConditions';
import { BasketTravelAgentInfo } from './BasketTravelAgentInfo';
import { isNil } from 'lodash-es';
import { IBasketBuildL4 } from 'services/BackendApi/types/Basket';
import { getCurrencySymbol } from 'utils';
import { ICompany } from 'services/BackendApi';
import { getProposalListRequestAction } from 'store/modules/proposalsList/actions';
import { depositStatementTotalsRequestAction } from 'store/modules/ledger/actions';
interface IBasketRightColumnProps {
  basketBuild: IBasketBuildL4 | null;
  selectedBuildsCount: number;
  setRedirectLocation: React.Dispatch<React.SetStateAction<string | null>>;
  shouldShowLeaveAlert: boolean;
  setShouldShowLeaveAlert: Dispatch<SetStateAction<boolean>>;
  className?: string;
  onPostRemove: () => void;
  company: ICompany;
  clientCountryCode: string | null;
}

export const BasketRightColumn: React.FC<IBasketRightColumnProps> = React.memo(
  ({ basketBuild, selectedBuildsCount, setRedirectLocation, setShouldShowLeaveAlert, onPostRemove, company, clientCountryCode }) => {
    const dispatch = useDispatch();
    const [taMarginAmount, setTaMarginAmount] = useState<number | null>(null);
    const [isTAMarginApplied, setIsTAMarginApplied] = useState(true);
    const grandTotal = basketBuild?.latestBookingBuilderResponse?.totals.total;
    const taMarginType = 'percentage';
    const canBook = basketBuild?.latestBookingBuilderResponse?.canBeBooked;
    const currencyCode = basketBuild?.latestBookingBuilderResponse?.currency
      ? getCurrencySymbol(basketBuild?.latestBookingBuilderResponse?.currency)
      : '';

    const { t } = useTranslation();

    const isAdmin = useSelector(AuthSelectors.isAdmin);
    const isSr: boolean = useSelector(AuthSelectors.isInternalUser);
    const isRl: boolean = useSelector(AuthSelectors.isRL);
    const shouldShowTAInfo = isAdmin || (isSr && !isRl);
    const [isRequestInProgress, setRequestInProgress] = useState(false);
    const [liveRatePricesModalData, setLiveRatePricesModalData] = useState([]);
    const [isLiveRatePricesModalOpen, setIsLiveRatePricesModalOpen] = useState(false);
    const handleLiveRatePricesModalOpen = (value: boolean) => {
      setIsLiveRatePricesModalOpen(value);
    };
    const handleSetLiveRatePricesModalData = (value: any) => {
      setLiveRatePricesModalData(value);
    };

    let selectedBasketWithTaMargin: IBasketBuildL4 | null = null;

    if (basketBuild) {
      selectedBasketWithTaMargin = {
        ...basketBuild,
        taMarginAmount: taMarginAmount ? taMarginAmount.toString() : undefined,
      };
    }

    useEffect(() => {
      dispatch(getProposalListRequestAction());
      dispatch(depositStatementTotalsRequestAction(company.uuid));
    }, [selectedBasketWithTaMargin?.uuid]);

    return (
      <div className="basket-right-column w-full bg-white overflow-x-clip">
        {(selectedBuildsCount === 0) && (
          <span className="block bg-green-25 uppercase text-black font-hurmegeometric-sans py-15px text-center w-full mb-30px">
            No item has been selected
          </span>
        )}
        {(selectedBuildsCount > 1) && (
          <div className="block bg-green-25 text-black font-hurmegeometric-sans py-15px text-center w-full mb-30px">
            <div className={'uppercase'}>{`${selectedBuildsCount} Items selected`}</div>
            <div>Cannot be booked together.</div>
            <div>Please book separately.</div>
          </div>
        )}
        {!isNil(basketBuild) && (
          <div className="mb-30px">
            <BasketRightTotalCost basketBuild={basketBuild} />
          </div>
        )}
        <BasketLeadGuestInfo disabled={isNil(basketBuild)} setShouldShowLeaveAlert={setShouldShowLeaveAlert} />
        {shouldShowTAInfo ? <BasketTravelAgentInfo travelAgent={null} /> : null}

        <BookingMargin
          t={t}
          currencyCode={currencyCode}
          onMarginChange={(e: any, marginType: any, marginValue: any) => {
            setIsTAMarginApplied(true);
            setTaMarginAmount(Number(marginValue));
          }}
          grandTotal={grandTotal}
          canBook={canBook}
          isTAMarginApplied={isTAMarginApplied}
          taMarginType={taMarginType}
          taMarginAmount={taMarginAmount}
          textClassname="normal-case"
          depth={0}
          titleClassname="font-hurmegeometric-sans font-[12px] leading-[14px] font-bold text-flint pb-[10px] mb-[10px] ml-[-20px] border-b border-gray-17"
          marginClassname="mx-[-20px]"
        />
        <BasketBookingButtons
          basketBuild={selectedBasketWithTaMargin}
          isRequestInProgress={isRequestInProgress}
          setShouldShowLeaveAlert={setShouldShowLeaveAlert}
          setRequestInProgress={setRequestInProgress}
          setRedirectLocation={setRedirectLocation}
          canBook={!!canBook}
          isLiveRatePricesModalOpen={isLiveRatePricesModalOpen}
          setIsLiveRatePricesModalOpen={handleLiveRatePricesModalOpen}
          liveRatePricesModalData={liveRatePricesModalData}
          setLiveRatePricesModalData={handleSetLiveRatePricesModalData}
          onPostRemove={onPostRemove}
          company={company}
          clientCountryCode={clientCountryCode}
        />

        <div className="terms-and-conditions uppercase">
          <TermsAndConditions className="font-hurmegeometric-sans text-brown-100 visited:text-brown-100 inline-block mt-[18px] text-[12px] uppercase" />
        </div>
      </div>
    );
  }
);
