import { EInventoryReferenceL1, InventoryReference } from '../../../interfaces';

export interface IInventoryHeaderDomain {
  isMenuOpen: boolean;
  currentPathOptions: InventoryReference[];
  lastSetPathOptions: InventoryReference[];
  currentURL: string | null; // Will help to detect changed URLs in browser.
  lastSetUrl: string | null; // Will help to detect changed URLs in browser.
}

export const initialState: IInventoryHeaderDomain = {
  isMenuOpen: true,
  currentPathOptions: [EInventoryReferenceL1.RESERVATION, null, null],
  lastSetPathOptions: [EInventoryReferenceL1.RESERVATION, null, null],
  currentURL: '/',
  lastSetUrl: '/',
};
