import React, { useCallback } from 'react';
import { ITableColumn } from './types';
import { ESortOrder } from 'store/common/types';
import { ColumnHeader } from './ColumnHeader';

export interface ITableColumnProps {
  column: ITableColumn;
  sortBy: string;
  sortOrder: ESortOrder;
  onSort: (newSortBy: string, newSortOrder: string) => void;
}

export interface IThProps {
  id: string;
  style: React.CSSProperties;
  onClick?: () => void;
  className?: string;
}

export const TableColumn: React.FC<ITableColumnProps> = React.memo(({ 
  column, sortBy, sortOrder, onSort
}) => {
  const handleSort = useCallback((newSortBy: string) => () => {
    const newSortOrder = newSortBy === sortBy && sortOrder === ESortOrder.ASC
      ? ESortOrder.DESC
      : ESortOrder.ASC;
    onSort(newSortBy, newSortOrder);
  }, [sortBy, sortOrder, onSort])

  const thProps: IThProps = {
    id: `${column.id}-header`,
    style: { width: column.width },
    ...(column.fieldToSortBy ? { onClick: handleSort(column.fieldToSortBy) } : {}),
    ...(column.className ? { className: column.className } : {}),
  };

  return (
    <th key={column.id} {...thProps}>
      {column.label && (
        <ColumnHeader 
          label={column.label}
          currentSortBy={sortBy}
          currentSortOrder={sortOrder}
          columnSortBy={column.fieldToSortBy}
        />
      )}
    </th>
  )
});