import * as Actions from './actions';
import { IInventoryHeaderDomain, initialState } from './model';
import { produce } from 'immer';
import { clone } from 'lodash-es';

export const inventoryHeaderReducer = (
  state: IInventoryHeaderDomain = initialState,
  action: Actions.TInventoryHeaderActions
): IInventoryHeaderDomain => {
  switch (action.type) {
    case Actions.SET_IS_MENU_OPEN:
      return produce(state, draftState => {
        draftState.isMenuOpen = action.val;
        return draftState;
      });

    case Actions.SET_INVENTORY_OPTION_SELECTED:
      return produce(state, draftState => {
        draftState.currentPathOptions = [...action.path];
        draftState.lastSetPathOptions = [...action.path];
        draftState.currentURL = action.url;
        draftState.lastSetUrl = action.url;
        return draftState;
      });

    case Actions.RESET_INVENTORY_OPTION_SELECTED:
      return {
        ...initialState,
        lastSetPathOptions: clone(state.lastSetPathOptions),
        lastSetUrl: clone(state.lastSetUrl),
      };

    default:
      return state;
  }
};

export default inventoryHeaderReducer;
