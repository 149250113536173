import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { PageTitle } from 'ui/PageTitle';
import { BackLink } from 'ui/BackLink';
import { Field, Formik, FormikProps } from 'formik';
import { ITaskFormValues, taskFormValidationSchema } from './taskFormValidation';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { LoadingBar } from 'ui/NetworkStatusBar';
import FluidButton from 'ui/FluidButton';
import { TextInput } from 'ui/TextInput';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { UITextArea } from 'ui/UITextArea';
import { createTaskSelector, getAssigneesRequestSelector, getFilterInfoRequestSelector, getTaskAssigneesSelector, getTaskCategoriesSelector, getTaskTeamsSelector, listTaskRemindersSelector } from 'store/modules/taskManagement/selectors';
import * as TaskManagementActions from 'store/modules/taskManagement/actions';
import { taskPriorityOptions, taskStatusOptions } from './helpers';
import { TaskStatus } from './TaskStatus';
import { AsyncSearchDropdown } from 'ui/AsyncSearchDropdown';
import { ENetworkRequestStatus, IUser } from 'services/BackendApi';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import { StyledDateRangeInput } from 'containers/BookingList/StyledFilters';
import { getCurrentUser } from 'store/modules/auth';
import { useHistory, useRouteMatch } from 'react-router';
import { ETaskPriority, ETaskStatus, ITaskReminder } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { useCurrentWidth } from 'effects';
import { TaskReminders } from './TaskReminders/TaskReminders';

const getUserDisplayName = (user?: Pick<IUser, "uuid" | "firstName" | "lastName">) => {
  return user ? user.firstName + ' ' + user.lastName : '';
};

export const TaskCreate: React.FC = () => {
  const dispatch = useDispatch();
  const { currentWidth } = useCurrentWidth();
  const urlParams = new URLSearchParams(window.location.search);
  const routeBookingRef = urlParams.get('bookingRef');
  const history = useHistory();
  const canEdit = true;
  const taskCategoryOptions = useSelector(getTaskCategoriesSelector);
  const taskTeamOptions = useSelector(getTaskTeamsSelector);
  const getFilterInfoRequest = useSelector(getFilterInfoRequestSelector);
  const currentUser = useSelector(getCurrentUser) as IUser;
  const assignees = useSelector(getTaskAssigneesSelector);
  const getAssigneesRequest = useSelector(getAssigneesRequestSelector);
  const createTaskRequest = useSelector(createTaskSelector);
  const isSaving = createTaskRequest === ENetworkRequestStatus.PENDING;
  const [assigneeQueryText, setAssigneeText] = useState('');
  const taskReminders = useSelector(listTaskRemindersSelector);

  const statusOptions: ISingleSelectOption[] = taskStatusOptions.map(option => ({
    label: option.label,
    value: option?.value ?? undefined,
    renderItem: (className?: string) => (
      <TaskStatus className={classnames(className, 'min-h-[33px]')} value={option.value as ETaskStatus} />
    )
  }));

  const categoryOptions: ISingleSelectOption[] = taskCategoryOptions?.map(item => ({
    value: item.id,
    label: item.name,
  })) ?? [];

  const teamOptions: ISingleSelectOption[] = taskTeamOptions?.map(item => ({
    value: item.id,
    label: item.name,
  }) ?? []);

  const assigneeOptions: ISingleSelectOption[] = [{ value: 'all', label: 'No assignment' }, ...(assignees
    ?.map(user => ({
      value: user.uuid,
      label: getUserDisplayName(user),
    })) ?? [])];

  useLayoutEffect(() => {
    dispatch(TaskManagementActions.getUsersRequestAction('', 'assignee'));
    dispatch(TaskManagementActions.getFilterInfoRequestAction());
    dispatch(TaskManagementActions.clearTaskRemindersAction());
  }, []);

  const handleAssigneeFilterChange = useCallback(value => {
    setAssigneeText(value);
    dispatch(TaskManagementActions.getUsersRequestAction(value, 'assignee'));
  }, []);

  const initialValues: ITaskFormValues = useMemo(() => ({
    assigneeId: null,
    bookingRef: routeBookingRef ?? '',
    category: null,
    endDate: '',
    endTime: '00:00',
    description: '',
    priority: ETaskPriority.MEDIUM,
    startByDate: '',
    status: ETaskStatus.BACKLOG,
    subject: '',
    teamId: null,
  }), [routeBookingRef]);

  const handleFormSubmit = useCallback((values: ITaskFormValues) => {
    dispatch(TaskManagementActions.createTaskRequestAction(values, history));
  }, []);

  const validateReminders = useCallback(() => {
    dispatch(TaskManagementActions.validateRemindersAction());
  }, [dispatch]);

  if (getFilterInfoRequest === ENetworkRequestStatus.PENDING) {
    return <LoadingBar />
  }
  
  return (
    <div className={classnames('task-create-page', { 'px-[20px]': currentWidth <= 1262, 'px-[80px]': currentWidth > 1262 })}>
      <BackLink type="internal" href="/tasks">
        Back
      </BackLink>
      <div className="task-create-content w-full">
        <PageTitle
          primaryText="New Task"
        />
        <Formik
          initialValues={initialValues}
          validationSchema={taskFormValidationSchema}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
        >
          {(form: FormikProps<ITaskFormValues>) => {  


            return (
              <React.Fragment>
                <LeaveWithoutSavingModal 
                  when={form.dirty && (createTaskRequest === ENetworkRequestStatus.IDLE || createTaskRequest === ENetworkRequestStatus.ERROR)} 
                  confirmButtonLabel="Yes"
                  cancelButtonLabel="No"
                />

                <form autoComplete={'off'} onSubmit={form.handleSubmit}>
                    <div className="form-container flex justify-between gap-[80px] mt-[20px]">
                      <div className="left-part flex-1 flex flex-col gap-[20px]">
                        <div className="subject-category flex items-start gap-[20px]">
                          <Field
                            as={TextInput}
                            type="text"
                            name="subject"
                            id="subject"
                            className="subject flex-1"
                            label="Subject *"
                            errorMessage={form.touched.subject && form.errors.subject ? form.errors.subject : null}
                            errorClassName="subject-error"
                          />
                          <Field name="category">
                            {({ field: { name, value }, form: { setFieldValue } }) => (
                              <SingleSelect
                                fieldId="category"
                                label="Category *"
                                className="title min-w-[190px]"
                                value={value}
                                showSelectedOption
                                options={categoryOptions ?? []}
                                onChange={value => {
                                  setFieldValue(name, value);
                                }}
                                errorMessage={form.touched.category && form.errors.category ? form.errors.category : null}
                                errorClassName="category-error"
                              />
                            )}
                          </Field>
                        </div>
                        <Field
                          as={TextInput}
                          type="text"
                          name="bookingRef"
                          id="bookingRef"
                          className="bookingRef w-300px"
                          label="Booking Ref."
                          errorMessage={form.touched.bookingRef && form.errors.bookingRef ? form.errors.bookingRef : null}
                          errorClassName="booking-ref-error"
                        />
                        <Field name="description">
                          {({ field: { name, value }, form: { setFieldValue } }) => (
                            <UITextArea
                              id="description"
                              className="description"
                              value={value}
                              rows={6}
                              onChange={value => {
                                setFieldValue(name, value);
                              }}
                              label="Description"
                              errorMessage={form.touched.description && form.errors.description ? form.errors.description : null}
                              errorClassName="description-error"
                            />
                          )}
                        </Field>
                      </div>
                      <div className="right-part flex flex-col gap-[20px] p-[20px] border border-dashed border-gray-40 rounded-[4px] min-w-[442px]">
                        <div className="status-priority flex items-start gap-[20px]">
                          <Field name="status">
                            {({ field: { name, value }, form: { setFieldValue } }) => (
                              <SingleSelect
                                fieldId="status"
                                label="Status *"
                                className="status min-w-[190px]"
                                value={value}
                                showSelectedOption
                                options={statusOptions ?? []}
                                onChange={value => {
                                  setFieldValue(name, value);
                                }}
                                errorMessage={form.touched.status && form.errors.status ? form.errors.status : null}
                                errorClassName="status-error"
                              />
                            )}
                          </Field>
                          <Field name="priority">
                            {({ field: { name, value }, form: { setFieldValue } }) => (
                              <SingleSelect
                                fieldId="task-priority-filter"
                                label="Priority *"
                                className="task-priority-filter min-w-[190px] max-w-[190px]"
                                value={value}
                                options={taskPriorityOptions ?? []}
                                onChange={value => {
                                  setFieldValue(name, value);
                                }}
                                showSelectedOption
                                maxVisibleItems={6}
                                errorMessage={form.touched.priority && form.errors.priority ? form.errors.priority : null}
                                errorClassName="priority-error"
                              />
                            )}
                          </Field>
                        </div>
                        <div className="team-assignee flex items-start gap-[20px]">
                          <Field name="teamId">
                            {({ field: { name, value }, form: { setFieldValue } }) => (
                              <SingleSelect
                                fieldId="teamId"
                                label="Team *"
                                className="teamId min-w-[190px]"
                                value={value}
                                showSelectedOption
                                maxVisibleItems={6}
                                options={teamOptions ?? []}
                                onChange={value => {
                                  setFieldValue(name, value);
                                }}
                                errorMessage={form.touched.teamId && form.errors.teamId ? form.errors.teamId : null}
                                errorClassName="team-error"
                              />
                            )}
                          </Field>
                          <Field name="assigneeId">
                            {({ field: { name, value }, form: { setFieldValue } }) => (
                              <div className="relative">
                                <AsyncSearchDropdown
                                  fieldId="assignee"
                                  label="Assignee"
                                  className="assignee min-w-[180px] flex-1"
                                  value={assigneeQueryText}
                                  selectedValue={value}
                                  options={assigneeOptions}
                                  onChoose={(value: string) => {
                                    const selectedAssignee = assigneeOptions.find(item => item.value === value);
                                    setAssigneeText(selectedAssignee?.label ?? '');
                                    setFieldValue(name, value);
                                    dispatch(TaskManagementActions.getUsersRequestAction('', 'assignee'));
                                  }}
                                  onChange={handleAssigneeFilterChange}
                                  maxVisibleItems={8}
                                  loading={getAssigneesRequest === ENetworkRequestStatus.PENDING}
                                  errorMessage={null}
                                  errorClassName="assignee-error"
                                />
                                <span 
                                  className="assign-to-me absolute font-pt-sans underline text-[13px] leading-[17px] text-brown-100 cursor-pointer top-0 right-[2px]"
                                  onClick={() => {
                                    const selectedUser = assignees.find(user => user.uuid === currentUser.uuid);
                                    setAssigneeText(getUserDisplayName(selectedUser));
                                    setFieldValue(name, selectedUser?.uuid ?? null);
                                  }}
                                >Assign to me</span>
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="date-time flex flex-wrap items-start gap-[20px]">
                          <Field name="startByDate">
                            {({ field: { name, value }, form: { setFieldValue } }) => (
                              <div className="task-startdate-filter flex flex-col flex-1 relative min-w-[180px] max-w-[120px] ">
                                <label className="text-black font-pt-sans text-13px leading-17px tracking-2xs mb-[5px]">
                                  Start By
                                </label>
                                <DatePickerStateProvider
                                  defaultSelectedDates={[value]}
                                  placeholder="SELECT DATE"
                                  onDateChange={dates => {
                                    const date = format(new Date(dates[0]), 'yyyy-MM-dd');
                                    setFieldValue(name, date);
                                  }}
                                  isSingleDateSelection
                                  render={(params: IDatePickerSateParams) => {
                                    return (
                                      <StyledDateRangeInput
                                        displayString={params.displayString}
                                        currentDate={params.datePickerCurrentDate}
                                        totalNights={params.totalNights}
                                        selectedDates={params.selectedDates}
                                        onDayClick={params.handleDayClick}
                                        onDayMouseOver={params.handleDateMouseOver}
                                        showDatePicker={params.showDatePicker}
                                        onNextClick={params.incrementDate}
                                        onPrevClick={params.decrementDate}
                                        onMouseDown={params.toggleDatePicker}
                                        onClickOutside={params.hideDatePicker}
                                        calendarCount={1}
                                        customWidth={1}
                                        noPortal
                                        showTotalNights={false}
                                      />
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                          <Field name="endDate">
                            {({ field: { name, value }, form: { setFieldValue } }) => (
                              <div className="task-enddate-filter flex flex-col flex-1 min-w-[180px] max-w-[120px] ">
                                <label className="text-black font-pt-sans text-13px leading-17px tracking-2xs mb-[5px]">
                                  Deadline Date *
                                </label>
                                <DatePickerStateProvider
                                  defaultSelectedDates={[value]}
                                  placeholder="SELECT DATE"
                                  onDateChange={dates => {
                                    const date = format(new Date(dates[0]), 'yyyy-MM-dd');
                                    setFieldValue(name, date);
                                  }}
                                  isSingleDateSelection
                                  render={(params: IDatePickerSateParams) => {
                                    return (
                                      <StyledDateRangeInput
                                        datePickerLeft
                                        displayString={params.displayString}
                                        currentDate={params.datePickerCurrentDate}
                                        totalNights={params.totalNights}
                                        selectedDates={params.selectedDates}
                                        onDayClick={params.handleDayClick}
                                        onDayMouseOver={params.handleDateMouseOver}
                                        showDatePicker={params.showDatePicker}
                                        onNextClick={params.incrementDate}
                                        onPrevClick={params.decrementDate}
                                        calendarCount={1}
                                        customWidth={1}
                                        noPortal
                                        onMouseDown={params.toggleDatePicker}
                                        onClickOutside={params.hideDatePicker}
                                        showTotalNights={false}
                                        invalid={!!(form.touched.endDate && form.errors.endDate)}
                                      />
                                    );
                                  }}
                                />
                                {form.touched.endDate && form.errors.endDate && (
                                  <p className="text-red-95 text-13px leading-2xs font-pt-sans mt-5px mb-0 ">
                                    {form.errors.endDate}
                                  </p>
                                )}
                              </div>
                            )}
                          </Field>
                          <Field
                            as={TextInput}
                            type="time"
                            name="endTime"
                            id="endTime"
                            className="endTime flex-1 max-w-[120px] min-w-[120px]"
                            label="Deadline Time *"
                            errorMessage={form.touched.endTime && form.errors.endTime ? form.errors.endTime : null}
                            errorClassName="endTime-error"
                          />
                        </div>
                        <TaskReminders reminders={taskReminders} />                      
                      </div>      
                    </div>
                    

                    {canEdit && (
                      <div className="buttons flex gap-[10px] " onClick={validateReminders}>
                        <FluidButton type="primary" submit isLoading={isSaving}>
                          Save
                        </FluidButton>
                      </div>
                    )}
                  </form>
              </React.Fragment>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
