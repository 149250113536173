import React, { useState } from 'react';
import { ITableRow } from './types';
import classNames from 'classnames';

interface ITableRowProps {
  row: ITableRow;
  rowIndex: number;
  evenColor: string;
}

export const TableRow: React.FC<ITableRowProps> = React.memo(({ row, rowIndex, evenColor }) => {
  const visibleCells = row.cells.filter(cell => !cell.isHidden);
  const subRows = row.subRows || [];
  const isEven = rowIndex % 2 === 0;

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <tr
        key={row.id}
        className={classNames(
          `id-${row.id} h-15 border border-solid border-gray-20 text-15px border-l-0 border-r-0`,
          row.className,
          {
            'border-b-0': isExpanded,
            [`bg-${evenColor}`]: isEven,
          }
        )}
      >
        {visibleCells.map((cell, cellIndex) => {
          return (
            <td key={cell.id} className={classNames('px-2', cell.className)} title={cell.title}>
              {/* the button to expand subrows, and the actual content of the main cell */}
              {(subRows.length > 0 && cellIndex === 0 && (
                <span
                  onClick={() => setIsExpanded(!isExpanded)}
                  className={classNames('flex items-center space-x-2 cursor-pointer', {
                    'font-bold': isExpanded,
                  })}
                >
                  <span
                    className={classNames('w-5 transition-transform bg-none flex justify-around cursor-pointer', {
                      'transform rotate-90': isExpanded,
                    })}
                  >
                    <i className="fas fa-chevron-right" />
                  </span>
                  <span className={cell.contentClassName}>{cell.value}</span>
                </span>
              )) || <span className={cell.contentClassName}>{cell.value}</span>}
            </td>
          );
        })}
      </tr>
      {isExpanded && (
        <>
          {subRows.map((subRow, subrowIndex) => {
            return (
              <tr
                key={subRow.id}
                className={classNames(`id-subrow-${row.id}-${subrowIndex} h-10 text-15px`, subRow.className, {
                  'bg-gray-10': isEven,
                })}
              >
                {subRow.cells.map(cell => {
                  return (
                    <td key={cell.id} className={classNames('subrow px-2', cell.className)} title={cell.title}>
                      <span className={cell.contentClassName}>{cell.value}</span>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </>
      )}
    </>
  );
});
