import { BookingBuilderResponse } from 'services/BackendApi';
import { IBasketRoom, IBasketSubdomain } from './model';

export const SET_BASKET_HOTEL = 'bookingBuilder/SET_BASKET_HOTEL';
export const ADD_ROOM_TO_BASKET = 'bookingBuilder/ADD_ROOM_TO_BASKET';
export const REMOVE_ROOM_FROM_BASKET = 'bookingBuilder/REMOVE_ROOM_FROM_BASKET';
export const CLEAR_ALL_ITEMS_FROM_BASKET = 'bookingBuilder/CLEAR_ALL_ITEMS_FROM_BASKET';
export const SYNC_BASKET_ACCOMMODATIONS = 'bookingBuilder/SYNC_BASKET_ACCOMMODATIONS';

export const SET_IS_REQUEST_BOOKING_MODAL_OPEN = 'bookingBuilder/SET_IS_REQUEST_BOOKING_MODAL_OPEN';

export const UPDATE_REQUEST_BOOKING_MODAL_DATA = 'bookingBuilder/UPDATE_REQUEST_BOOKING_MODAL_DATA';

export const UPDATE_BASKET_REQUEST = 'bookingBuilder/UPDATE_BASKET_REQUEST';
export const UPDATE_BASKET_SUCCESS = 'bookingBuilder/UPDATE_BASKET_SUCCESS';
export const UPDATE_BASKET_FAILURE = 'bookingBuilder/UPDATE_BASKET_FAILURE';

export const REQUEST_BOOKING_REQUEST = 'bookingBuilder/REQUEST_BOOKING_REQUEST';
export const REQUEST_BOOKING_SUCCESS = 'bookingBuilder/REQUEST_BOOKING_SUCCESS';
export const REQUEST_BOOKING_FAILURE = 'bookingBuilder/REQUEST_BOOKING_FAILURE';

export type UpdateBasketRequestAction = ReturnType<typeof updateBasketRequestAction>;
export const updateBasketRequestAction = () => ({
  type: UPDATE_BASKET_REQUEST as typeof UPDATE_BASKET_REQUEST,
});

export type UpdateBasketSuccessAction = ReturnType<typeof updateBasketSuccessAction>;
export const updateBasketSuccessAction = (response: BookingBuilderResponse) => ({
  type: UPDATE_BASKET_SUCCESS as typeof UPDATE_BASKET_SUCCESS,
  response,
});

export type UpdateBasketFailureAction = ReturnType<typeof updateBasketFailureAction>;
export const updateBasketFailureAction = (error: string) => ({
  type: UPDATE_BASKET_FAILURE as typeof UPDATE_BASKET_FAILURE,
  error,
});

export type SetBasketHotelAction = ReturnType<typeof setBasketHotelAction>;
export const setBasketHotelAction = (hotelUuid: string) => ({
  type: SET_BASKET_HOTEL as typeof SET_BASKET_HOTEL,
  hotelUuid,
});

export type AddRoomAction = ReturnType<typeof addRoomAction>;
export const addRoomAction = (room: IBasketRoom, hotelUuid: string, roomIndex: number, isUpdate: boolean) => ({
  type: ADD_ROOM_TO_BASKET as typeof ADD_ROOM_TO_BASKET,
  room,
  roomIndex,
  hotelUuid,
  isUpdate,
});

export type RemoveRoomAction = ReturnType<typeof removeRoomAction>;
export const removeRoomAction = (roomIndex: number) => ({
  type: REMOVE_ROOM_FROM_BASKET as typeof REMOVE_ROOM_FROM_BASKET,
  roomIndex,
});

export type InitBasketRoomsAction = ReturnType<typeof initBasketRoomsAction>;
export const initBasketRoomsAction = (accommodationsAmount: number) => ({
  type: CLEAR_ALL_ITEMS_FROM_BASKET as typeof CLEAR_ALL_ITEMS_FROM_BASKET,
  accommodationsAmount,
});

export type SyncBasketAccommodationsAction = ReturnType<typeof syncBasketAccommodationsAction>;
export const syncBasketAccommodationsAction = (accommodationsAmount: number, accommodationsToRemove: number[]) => ({
  type: SYNC_BASKET_ACCOMMODATIONS as typeof SYNC_BASKET_ACCOMMODATIONS,
  accommodationsAmount,
  accommodationsToRemove,
});

export type SetIsRequestBookingModalOpenAction = ReturnType<typeof setIsRequestBookingModalOpenAction>;
export const setIsRequestBookingModalOpenAction = (val: boolean) => ({
  type: SET_IS_REQUEST_BOOKING_MODAL_OPEN as typeof SET_IS_REQUEST_BOOKING_MODAL_OPEN,
  val,
});

export type UpdateRequestBookingModalDataAction = ReturnType<typeof updateRequestBookingModalDataAction>;
export const updateRequestBookingModalDataAction = (data: Partial<IBasketSubdomain['requestBookingModalData']>) => ({
  type: UPDATE_REQUEST_BOOKING_MODAL_DATA as typeof UPDATE_REQUEST_BOOKING_MODAL_DATA,
  data,
});

export type RequestBookingRequestAction = ReturnType<typeof requestBookingRequestAction>;
export const requestBookingRequestAction = () => ({
  type: REQUEST_BOOKING_REQUEST as typeof REQUEST_BOOKING_REQUEST,
});

export type RequestBookingSuccessAction = ReturnType<typeof requestBookingSuccessAction>;
export const requestBookingSuccessAction = () => ({
  type: REQUEST_BOOKING_SUCCESS as typeof REQUEST_BOOKING_SUCCESS,
});

export type RequestBookingFailureAction = ReturnType<typeof requestBookingFailureAction>;
export const requestBookingFailureAction = () => ({
  type: REQUEST_BOOKING_FAILURE as typeof REQUEST_BOOKING_FAILURE,
});

export type BookingBuilderBasketAction =
  | SetBasketHotelAction
  | AddRoomAction
  | RemoveRoomAction
  | InitBasketRoomsAction
  | UpdateBasketRequestAction
  | UpdateBasketSuccessAction
  | UpdateBasketFailureAction
  | SyncBasketAccommodationsAction
  | SetIsRequestBookingModalOpenAction
  | UpdateRequestBookingModalDataAction
  | RequestBookingRequestAction
  | RequestBookingSuccessAction
  | RequestBookingFailureAction;
