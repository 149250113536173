import { addIndex, always, either, equals, identity, is, map, merge, prop, reduce, test, tryCatch } from 'ramda';

const currencyConfig = require('config/data/currencies.json');

export const noop = () => {};

export const isArray = is(Array);
export const isObject = is(Object);
export const isFunction = is(Function);
export const isString = is(String);

export const mapWithIndex = addIndex(map);
export const reduceWithIndex = addIndex(reduce);

/**
 * Array to key value object
 *
 * Returns an object from an array based on which prop
 * to use in the array as key and which prop to use as the value
 *
 * @param {string} keyProp
 * @param {string} valueProp
 * @returns {object}
 */
export const arrayToKeyValueObject = (keyProp, valueProp) =>
  reduce((accum, item) => merge(accum, { [prop(keyProp, item)]: prop(valueProp, item) }), {});

/**
 * Test adult
 *
 * Tests to see if string contains adult
 *
 * @param {string}
 * @returns {boolean}
 */
export const testAdult = test(/^adult$/i);

/**
 * Is Adult
 *
 * @param {string}
 * @returns {boolean}
 */
export const isAdult = either(testAdult, equals('default'));

/**
 * Parse JSON
 *
 * Custom function that will add try catch round JSON.parse
 * returning the original data if it couldn't be parsed
 *
 * @param {string} data
 * @returns {string | object}
 */
export const parseJson = data => tryCatch(JSON.parse, always(identity(data)))(data);

export const compareObjectsByProperties = (A, B, properties) => {
  let match = true;

  properties.forEach(property => {
    if (A[property] !== B[property]) {
      match = false;
    }
  });

  return match;
};

export const filterByObjectProperties = (A, B, propertiesToMatch) => {
  return A.filter(elementA => {
    return B.some(elementB => {
      return compareObjectsByProperties(elementA, elementB, propertiesToMatch);
    });
  });
};

export const arrayOfObjectsToMapping = (arrayOfObjects, key, value) => {
  const mapping = {};

  arrayOfObjects.forEach(o => {
    mapping[o[key]] = o[value];
  });

  return mapping;
};

export const isBlank = value => {
  return value === '' || value === null || value === undefined;
};

export const currencyCodeToSymbol = currencyCode => {
  try {
    const symbol = currencyConfig[currencyCode].symbol;
    return symbol;
  } catch (e) {
    throw Error(`Cannot find currency symbol for "${currencyCode}"`);
  }
};

export const renderAdultsChildrenString = (numberOfAdults, agesOfAllChildren) => {
  const numberOfChildren = agesOfAllChildren?.length ?? 0;

  return numberOfChildren > 0
    ? `${numberOfAdults} Adults / ${numberOfChildren} ${numberOfChildren === 1 ? 'Child' : 'Children'}`
    : `${numberOfAdults} Adults`;
};

export const delay = async milliseconds => {
  return new Promise(resolve => {
    setTimeout(resolve, milliseconds);
  });
};

export const convertArrayToKeyValueObject = (array, key, value) => {
  return array.reduce((acc, item) => {
    acc[item[key]] = item[value];
    return acc;
  }, {});
};
