import { ENetworkRequestStatus } from 'services/BackendApi';

import {
  ELiveRatesSettingsListFilterType,
  ILiveRatesListingFilter,
  ILiveRatesListingItem,
  ILiveRatesSettingsInternalRoomsItem,
  ILiveRatesSettingsExternalRoomsItem,
  ILiveRatesSettingsInternalRoomItemError,
  ILiveRatesMappableStay,
  ILiveRatesHotel,
} from 'services/BackendApi/types/LiveRatesInternal';

import { ESortOrder } from 'store/common/types';

export interface ILiveRatesDomain {
  listingPage: {
    items: ILiveRatesListingItem[];
    filter: ILiveRatesListingFilter;
    toDelete: ILiveRatesListingItem | null;
  };
  settingsPage: {
    stayInformation:
      | {
          uuid: string;
          name: string;
          externalSystem: string;
          externalId: string;
        }
      | undefined;
    internalRooms: ILiveRatesSettingsInternalRoomsItem[];

    // we keep a copy of the original rooms that we DONT edit
    // so that if the user needs to reset their state, we can
    originalInternalRooms: ILiveRatesSettingsInternalRoomsItem[];
    externalRooms: ILiveRatesSettingsExternalRoomsItem[];
    internalRoomsFilter: ELiveRatesSettingsListFilterType;
    externalRoomsFilter: ELiveRatesSettingsListFilterType;
    internalRoomErrors: ILiveRatesSettingsInternalRoomItemError[];
    hasUnsavedChanges: boolean;
    showUnsavedChangesModal: boolean;
    isAttemptingToChangeInternalRoomsFilterTo: ELiveRatesSettingsListFilterType;
  };
  createModal: {
    isOpen: boolean;
    mappableStays: ILiveRatesMappableStay[];
    unmappedHotels: ILiveRatesHotel[];
  };
  request: {
    listingPageGet: ENetworkRequestStatus;
    settingsPageStayAndInternalRoomsGet: ENetworkRequestStatus;
    settingsPageExternalRoomsGet: ENetworkRequestStatus;
    deletingItem: ENetworkRequestStatus;

    settingsPageSave: ENetworkRequestStatus;

    mappableStaysGet: ENetworkRequestStatus;
    mappableStaysPatch: ENetworkRequestStatus;
  };
}

export const initialState: ILiveRatesDomain = {
  listingPage: {
    items: [],
    filter: {
      nameFilter: undefined,
      externalSystem: undefined,
      page: 1,
      page_size: 10,
      order_by: 'name',
      order_type: ESortOrder.ASC,
    },
    toDelete: null,
  },
  settingsPage: {
    stayInformation: undefined,
    internalRooms: [],
    originalInternalRooms: [],
    externalRooms: [],
    internalRoomsFilter: ELiveRatesSettingsListFilterType.MAPPED,
    externalRoomsFilter: ELiveRatesSettingsListFilterType.MAPPED,
    internalRoomErrors: [],
    hasUnsavedChanges: false,
    showUnsavedChangesModal: false,
    isAttemptingToChangeInternalRoomsFilterTo: ELiveRatesSettingsListFilterType.MAPPED,
  },
  createModal: {
    isOpen: false,
    mappableStays: [],
    unmappedHotels: [],
  },
  request: {
    listingPageGet: ENetworkRequestStatus.IDLE,
    settingsPageStayAndInternalRoomsGet: ENetworkRequestStatus.IDLE,
    settingsPageExternalRoomsGet: ENetworkRequestStatus.IDLE,
    deletingItem: ENetworkRequestStatus.IDLE,

    settingsPageSave: ENetworkRequestStatus.IDLE,
    mappableStaysGet: ENetworkRequestStatus.IDLE,
    mappableStaysPatch: ENetworkRequestStatus.IDLE,
  },
};
