import React, { useEffect, Suspense } from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';
import { compose, prop } from 'ramda';
import { ErrorBoundary, Loader } from '@pure-escapes/webapp-ui-components';
import { AsyncBookingList } from 'pages/async';
import { usePageVisibility } from 'react-page-visibility';
import { getAppRoutes } from 'routing';
import { Layout } from 'components';
import { useScrollToTop, useEffectBoundary } from 'effects';
import { withAuthentication, withUser } from 'hoc';
import { propTypes, defaultProps } from './App.props';
import connect from './App.state';
import { FastSearchContainerConnected } from 'containers/FastSearch';
import { RatesImportContainerConnected } from 'containers/RatesImport';
import { ProductsImportContainerConnected } from 'containers/ProductsImport';
import { StaticRatesAvailabilityImportContainerConnected } from 'containers/StaticRatesAvailabilityImport';
import { OfferRouting } from 'containers/OfferRouting';
import { BookingManagerRouting } from 'containers/BookingManager/routing';
import AuthRoute from 'containers/AuthRoute';
import { EUserType } from 'services/BackendApi';
import { ENVIRONMENT, COGNITO_ENABLE_FLOW, APP_ROUTING_PATHS } from 'config';
import { RateBreakRouting } from 'containers/RateBreak/routing';
import { TravelCompaniesRouting } from 'containers/TravelCompanies/routing';
import { TravelAgentsRouting } from 'containers/TravelAgents/routing';
import { InternalUsersRouting } from 'containers/InternalUsers/routing';
import { StatementRouting } from 'containers/Statement';
import { BookingBuilderV1 } from 'pages/BookingBuilderV1';
import { ProposalsRouting } from 'containers/Proposals/routing';
import { CredentialsInfoRouting } from 'containers/CredentialsInfo';
import { Dashboard } from 'pages/Dashboard/Dashboard';
import Landing from 'containers/Landing/Landing';
import { InventoryWorkspacesHomepage } from 'containers/InventoryWorkspacesHomepage';
import { LiveRatesRouting } from 'containers/LiveRates/routing';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { CognitoCallback } from 'pages/CognitoCallback/CognitoCallback';
import { CognitoFlowError } from 'pages/CognitoFlowError/CognitoFlowError';
import { NotFound } from 'pages/NotFound/NotFound';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { useSelector } from 'react-redux';
import { getBootstrapErrorSelector } from 'store/modules/bootstrap/selectors';
import { AncillarySuppliersRouting } from 'containers/AncillarySuppliers/routing';
import { AncillaryActivitiesRouting } from 'containers/AncillaryActivities/routing';
import { AncillaryTransfersRouting } from 'containers/AncillaryTransfers/routing';
import { AncillaryGroundServicesRouting } from 'containers/AncillaryGroundServices/routing';
import { BookingConfirmationRouting } from 'containers/BookingConfirmation/routing';
import { TaskManagementRouting } from 'containers/TaskManagement/routing';
import { BasketRouting } from 'containers/Basket/routing';
import { getBearerTokenFromLocalStorage } from 'services/tokenLocalStorage';

export const App = ({
  location: { pathname },
  user,
  resetStatuses,
  pageChange,
  bootstrapInitRequestAction,
  bootstrapAppRequestAction,
  visibilityChange,
  isAppVersionDeprecated,
  isAuthenticated,
}) => {
  const { dynamicParameters, dynamicParametersAreEmpty } = useDynamicParameters();
  const bootstrapError = useSelector(getBootstrapErrorSelector);
  // Scroll to top on path change
  useScrollToTop(pathname);

  useEffect(() => {
    // App can load at the beginning or at any point of the flow
    // Either user is logged in and can throw BOOTSTRAP_APP_REQUEST
    // Either is not yet then when AUTH_LOG_IN_SUCCESS happens same saga will be triggered
    const token = getBearerTokenFromLocalStorage();
    if (!!token.value && !token.expired) {
      // Normal bootstrap flow for app load at any point
      bootstrapAppRequestAction();
    } else {
      // First Cognito flow, then normal bootstrap flow
      bootstrapInitRequestAction();
    }
  }, []);

  useEffectBoundary(() => {
    pageChange(pathname);

    // Reset statuses on page change
    resetStatuses();
  }, [pathname]);

  const visible = usePageVisibility();

  useEffect(() => {
    visibilityChange(visible);
  }, [visibilityChange, visible]);

  if (bootstrapError) {
    return (
      <Layout isAppVersionDeprecated={isAppVersionDeprecated}>
        <div className="bootstrap-error mt-[100px]">
          <ErrorBar message="Service temporary unavailable. Please try again later." />
        </div>
      </Layout>
    );
  }

  if (dynamicParametersAreEmpty) {
    return (
      <div className="mt-[100px]">
        <LoadingBar />
      </div>
    );
  }

  if (
    ENVIRONMENT !== 'local' &&
    !isAuthenticated &&
    window.location.pathname === '/' &&
    dynamicParameters.MARKETING_APP_URL
  ) {
    window.location.replace(dynamicParameters.MARKETING_APP_URL);
  }

  return (
    <Layout isAppVersionDeprecated={isAppVersionDeprecated}>
      <Suspense fallback={<Loader />}>
        <Switch>
          {/* If inventory is ON, all users get the new homepage... */}
          <AuthRoute
            path="/"
            exact
            component={InventoryWorkspacesHomepage}
            allow={[EUserType.ADMIN, EUserType.FINANCE, EUserType.RL, EUserType.SR, EUserType.TA]}
          />
          {/* ...and everyone gets a new "filters" page */}
          <AuthRoute
            path="/filters"
            exact
            component={user && (user.type === EUserType.SR || user.type === EUserType.ADMIN) ? Dashboard : Landing}
            allow={[EUserType.TA, EUserType.ADMIN, EUserType.FINANCE, EUserType.RL, EUserType.SR]}
          />

          <AuthRoute
            path="/search/beta"
            exact
            component={FastSearchContainerConnected}
            allow={[EUserType.TA, EUserType.SR, EUserType.ADMIN]}
          />
          <AuthRoute path="/booking-manager" component={BookingManagerRouting} />
          <AuthRoute allow={[EUserType.RL, EUserType.ADMIN]} path="/offers" component={OfferRouting} />
          <AuthRoute
            allow={[EUserType.RL, EUserType.ADMIN]}
            exact
            path="/rates/import"
            component={RatesImportContainerConnected}
          />
          <AuthRoute
            allow={[EUserType.RL, EUserType.ADMIN]}
            exact
            path="/products/import"
            component={ProductsImportContainerConnected}
          />
          <AuthRoute
            allow={[EUserType.RL, EUserType.ADMIN]}
            exact
            path="/static-rates-availability/import"
            component={StaticRatesAvailabilityImportContainerConnected}
          />
          <AuthRoute
            allow={[EUserType.TA, EUserType.SR, EUserType.RL, EUserType.FINANCE, EUserType.ADMIN]}
            exact
            path="/bookings"
            component={AsyncBookingList}
          />
          <AuthRoute allow={[EUserType.RL, EUserType.ADMIN]} path="/rate-breaks" component={RateBreakRouting} />
          {dynamicParameters.ENABLE_ANCILLARY_SUPPLIERS_IMPORT && (
            <AuthRoute
              allow={[EUserType.RL, EUserType.ADMIN]}
              path="/suppliers"
              component={AncillarySuppliersRouting}
            />
          )}
          {dynamicParameters.ENABLE_ANCILLARY_ACTIVITIES_IMPORT && (
            <AuthRoute
              allow={[EUserType.RL, EUserType.ADMIN]}
              path="/activities"
              component={AncillaryActivitiesRouting}
            />
          )}
          {dynamicParameters.ENABLE_ANCILLARY_TRANSFERS_IMPORT && (
            <AuthRoute
              allow={[EUserType.RL, EUserType.ADMIN]}
              path="/transfers"
              component={AncillaryTransfersRouting}
            />
          )}
          {dynamicParameters.ENABLE_ANCILLARY_GROUND_SERVICES_IMPORT && (
            <AuthRoute
              allow={[EUserType.RL, EUserType.ADMIN]}
              path="/ground-services"
              component={AncillaryGroundServicesRouting}
            />
          )}
          {/* Admin, SR, Finance --> Can see one TC's settings, but SR and F cannot see the list for all TCs */}
          <AuthRoute
            allow={[EUserType.ADMIN, EUserType.SR, EUserType.FINANCE]}
            path="/travel-companies"
            component={TravelCompaniesRouting}
          />
          <AuthRoute allow={[EUserType.ADMIN]} path="/users" component={InternalUsersRouting} />
          <AuthRoute allow={[EUserType.ADMIN, EUserType.SR]} path="/travel-agents" component={TravelAgentsRouting} />
          <AuthRoute allow={[EUserType.ADMIN]} path="/users" component={InternalUsersRouting} />
          <AuthRoute
            allow={
              user?.companyTaManager || user?.taCanReadFinance
                ? [EUserType.ADMIN, EUserType.FINANCE, EUserType.SR, EUserType.TA]
                : [EUserType.ADMIN, EUserType.FINANCE, EUserType.SR]
            }
            path="/statement"
            component={StatementRouting}
          />
          <AuthRoute
            allow={[EUserType.ADMIN, EUserType.SR, EUserType.TA]}
            path="/hotels/:id"
            component={BookingBuilderV1}
          />
          <AuthRoute
            allow={[EUserType.TA, EUserType.SR, EUserType.ADMIN]}
            path="/proposals-v2"
            component={ProposalsRouting}
          />
          <AuthRoute
            allow={[EUserType.ADMIN, EUserType.SR, EUserType.TA]}
            path="/booking-confirmation"
            component={BookingConfirmationRouting}
          />
          {dynamicParameters.ENABLE_BASKET && (
            <AuthRoute
              allow={[EUserType.ADMIN, EUserType.SR, EUserType.TA]}
              path={APP_ROUTING_PATHS.basket.home}
              component={BasketRouting}
            />
          )}
          {dynamicParameters.ENABLE_BASKET && (
            <AuthRoute
              allow={[EUserType.ADMIN, EUserType.SR, EUserType.TA]}
              path={`${APP_ROUTING_PATHS.basket.edit}/:id`}
              component={BookingBuilderV1}
            />
          )}
          <AuthRoute allow={[EUserType.ADMIN, EUserType.SR]} path="/ta-history" component={CredentialsInfoRouting} />
          <AuthRoute allow={[EUserType.RL, EUserType.ADMIN]} path="/live-rates" component={LiveRatesRouting} />
          {COGNITO_ENABLE_FLOW && <Route path="/login-error" component={CognitoFlowError} />}
          {COGNITO_ENABLE_FLOW && <Route path="/cognito-callback" component={CognitoCallback} />}
          {COGNITO_ENABLE_FLOW && <Route path="/login" component={NotFound} />}
          {COGNITO_ENABLE_FLOW && <Route path="/settings/password" component={NotFound} />}
          {((user?.type === EUserType.TA && dynamicParameters.ENABLE_OPERATIONS_WORKSPACE_TA) ||
            (user?.type !== EUserType.TA && dynamicParameters.ENABLE_OPERATIONS_WORKSPACE_INTERNAL_USERS)) && (
            <AuthRoute
              allow={[EUserType.ADMIN, EUserType.SR, EUserType.TA, EUserType.FINANCE, EUserType.RL]}
              path="/tasks"
              component={TaskManagementRouting}
            />
          )}

          {...getAppRoutes(prop('type', user))}
        </Switch>
      </Suspense>
    </Layout>
  );
};

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default compose(ErrorBoundary, withRouter, withUser, withAuthentication, connect)(App);
